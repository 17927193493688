import React, { useContext, useState, useRef, useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as InstrumentContext } from '../../../../../contexts/InstrumentContext';

import CreateWatchlistButton from '../../Watchlist/CreateWatchlistButton';
import SearchResult from '../../Watchlist/SearchResult/SearchResult';
import EmptySearch from '../../Watchlist/EmptySearch/EmptySearch';

import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';

import { forceCloseModal } from '../../../../../utils/hooks/useForceCloseModal';

import Button from '../../../../../shared/Button/Button';
import Modal from '../../../../../shared/Modal/Modal';

import { AccountMarketType, MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';

import usePromiseFactory from '../../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';

import { sortSearchResults } from '../../../../../utils/functions/sortSearchResults';
import { getSearchResults } from '../../../../../utils/functions/getSearchResults';
import usePreferredFeedId from '../../../../../utils/hooks/usePreferredFeedId';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	instrumentExistsInDynamicWatchlists,
	instrumentExistsInWatchlist,
	invalidWatchlistName,
	maxWatchlistsReached,
} from '../../../../../utils/functions/WatchlistUtils';

import { TWatchlists } from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import styles from './TradeBoardHeader.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

interface TradeBoardHeaderProps {
	isWatchlistSearchOpen: boolean;
	handleWatchlistSearchToggle: () => void;
	isDynamicWatchlist: boolean;
}

const TradeBoardHeader = ({
	isWatchlistSearchOpen,
	handleWatchlistSearchToggle,
	isDynamicWatchlist,
}: TradeBoardHeaderProps) => {
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const getTranslatedWLName = useGetTranslatedWLName();

	const currentWatchList = tradingAccountStore.use.currentWatchList();
	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const selectedTradingAccountWatchlists = dashboardContext.watchlist;

	const [openModify, setOpenModify] = useState(false);
	const [openWatchlist, setOpenWatchlist] = useState(false);
	const [createWatchList, setCreateWatchList] = useState(false);
	const [watchlistName, setWatchlistName] = useState(currentWatchList ? currentWatchList : '');
	const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
	const [typedValue, setTypedValue] = useState('');
	const [nameError, setNameError] = useState('');
	const [searchResults, setSearchResults] = useState<MarketItem[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [showEmptySearchState, setShowEmptySearchState] = useState(false);
	const [invalidRemoveName, setInvalidRemoveName] = useState('');
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const watchlistNameRef = useRef<string>(currentWatchList);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const marketItems = dashboardContext.marketItems.filter((marketItem) =>
		isSpreadBettingAccount
			? marketItem.accountMarketType === AccountMarketType.SpreadBetting
			: marketItem.accountMarketType !== AccountMarketType.SpreadBetting
	);
	const selectedTradingAccount = useSelectedTradingAccount();

	const preferredFeedId = usePreferredFeedId(selectedTradingAccount);
	const FILTER_THROTTLE = 150;

	useObservable(
		dashboardContext.getPropertyChangeStream('marketItems', 'tradingAccount', 'watchlist', 'mappedWatchlist'),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const closeWatchlistPopup = (e: any) => {
		if (
			e.target.classList[0] !== undefined ||
			e.target.classList.length === 0 ||
			(Array.isArray(e.target.classList) && !e.target.classList[0].includes('Watchlist'))
		) {
			setOpenWatchlist(false);
		}
	};

	const handleModify = (item: string) => {
		setCurrentItemWatchList(item);
		setOpenModify(true);
	};

	const handleCloseModifyModal = () => {
		setOpenModify(false);
		setNameError('');
		setInvalidRemoveName('');
		setTypedValue('');
		forceCloseModal('fade modal');
	};

	const handleCreateWatchlist = () => {
		if (!maxWatchlistsError) {
			setTypedValue('');
			setCreateWatchList(true);
		}
	};

	const changeValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setTypedValue(value);
		nameError.length > 0 && setNameError('');
	};

	const changeWatchlistName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setWatchlistName(value);
		if (nameError || invalidRemoveName) {
			setNameError('');
			setInvalidRemoveName('');
		}
	};

	const handleCreateWatchListClose = () => {
		setNameError('');
		setTypedValue('');
		setCreateWatchList(false);
	};

	const verifyWatchlist = () => {
		if (dashboardContext.mappedWatchlist !== null) {
			const nameExists = invalidWatchlistName(typedValue, dashboardContext.mappedWatchlist);
			const maxWatchlists = maxWatchlistsReached(dashboardContext.mappedWatchlist);
			if (!nameExists && !maxWatchlists) {
				setNameError('');
				handleSaveWatchlist();
			} else if (nameExists) {
				setNameError(t('wtr:NAME_IN_USE'));
			}
		}
	};

	const handleSaveWatchlist = () => {
		const watchlistName = typedValue.trim();
		const newWatchlistEntry: TWatchlists = {
			instrument: [],
			_feedId: preferredFeedId !== '' ? preferredFeedId : DEFAULT_FEED_ID,
			_id: watchlistName,
			_name: watchlistName,
			_sortOrder: 'None',
			_sortValue: 'Instrument',
		};
		selectedTradingAccountWatchlists.push(newWatchlistEntry);
		dashboardContext.watchlist = selectedTradingAccountWatchlists;
		const updateWatchlistMap = createWatchlistMap(dashboardContext.watchlist);
		dashboardContext.mappedWatchlist = updateWatchlistMap;
		setCurrentWatchList(watchlistName);
		saveWatchlistToPreferences(selectedTradingAccountWatchlists);
		setCreateWatchList(false);
		setOpenWatchlist(false);
	};

	const resetWatchlistSearch = () => {
		handleWatchlistSearchToggle();
		setSearchResults([]);
		setSearchValue('');
		setShowEmptySearchState(false);
	};

	const clearSearchInput = () => {
		setSearchValue('');
		setSearchResults([]);
		setShowEmptySearchState(false);
		searchInputRef.current !== null && searchInputRef.current.focus();
	};

	const handleSearchValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		if (value.length <= 1) {
			setSearchResults([]);
			setShowEmptySearchState(false);
		} else {
			promiseFactory.throttle('searchTermChange', FILTER_THROTTLE).then(() => {
				const results = getSearchResults(marketItems, value);
				if (results.length === 0) {
					setSearchResults([]);
					setShowEmptySearchState(true);
				} else {
					const sorted = sortSearchResults(results, value);
					setSearchResults(sorted);
					setShowEmptySearchState(false);
				}
			});
		}
		setSearchValue(value.trim());
	};

	const updateWatchlist = (action: 'add' | 'remove', symbol: string) => {
		const name = currentWatchList.trim();
		let preferenceToUpdate = selectedTradingAccountWatchlists.find(
			(preference) => preference._name === currentWatchList
		);
		if (preferenceToUpdate) {
			if (action === 'add') {
				preferenceToUpdate.instrument = [...preferenceToUpdate.instrument, { _code: symbol }];
			} else {
				preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
					(instrument) => instrument._code !== symbol
				);
			}
			setCurrentWatchList(name);
			dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
			saveWatchlistToPreferences(dashboardContext.watchlist);
		}
	};

	const removeWatchlist = () => {
		if (dashboardContext.mappedWatchlist !== null) {
			if (!invalidWatchlistName(watchlistName.trim(), dashboardContext.mappedWatchlist!)) {
				setInvalidRemoveName('Watchlist does not exist');
			} else if (dashboardContext.mappedWatchlist !== null) {
				let copyWatchlist = dashboardContext.mappedWatchlist!;
				delete copyWatchlist[watchlistName];
				const removed = selectedTradingAccountWatchlists.filter(
					(watchlist) => watchlist._name.toUpperCase() !== watchlistName.toUpperCase()
				);
				dashboardContext.watchlist = removed;
				dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
				const watchlistNames = Object.keys(copyWatchlist);
				setCurrentWatchList(watchlistNames[0]);
				saveWatchlistToPreferences(dashboardContext.watchlist);
				setOpenModify(false);
				forceCloseModal('fade modal');
			}
		}
	};

	const saveNewWatchlist = () => {
		if (dashboardContext.mappedWatchlist !== null) {
			const name = watchlistName.trim();
			const validateName = invalidWatchlistName(name, dashboardContext.mappedWatchlist);
			if (!validateName) {
				if (watchlistNameRef.current === name && Object.keys(dashboardContext.mappedWatchlist).length === 1) {
					setOpenModify(false);
					dashboardContext.watchlist = selectedTradingAccountWatchlists;
					setCurrentWatchList(name);
					watchlistNameRef.current = name;
				} else {
					setOpenModify(false);
					const originalName = selectedTradingAccountWatchlists.find(
						(watchlist) => watchlist._name === currentWatchList
					);
					if (originalName) {
						originalName._name = name;
						originalName._id = name;
					}
					let cloneWatchlist = dashboardContext.mappedWatchlist;
					cloneWatchlist[name] = cloneWatchlist[currentWatchList];
					delete cloneWatchlist[currentWatchList];
					setCurrentWatchList(name);
					watchlistNameRef.current = name;
					saveWatchlistToPreferences(selectedTradingAccountWatchlists);
				}
				forceCloseModal('fade modal');
			} else {
				setNameError(t('wtr:NAME_IN_USE'));
				setOpenModify(true);
			}
		}
	};

	//Reset existing errors when input length is 0
	useEffect(() => {
		typedValue.length === 0 && setNameError('');
		watchlistName.length === 0 && setNameError('');
	}, [typedValue, watchlistName]);

	//Clear existing errors when modify screen is reopened
	useEffect(() => {
		nameError.length > 0 && setNameError('');
	}, [openModify]);

	// TODO - Check if we need that one
	useEffect(() => {
		if (dashboardContext.mappedWatchlist !== null) {
			sessionStorage.setItem('manualWatchlist', JSON.stringify(dashboardContext.mappedWatchlist));
		}
	}, [dashboardContext.mappedWatchlist]);

	useEffect(() => {
		if (dashboardContext.mappedWatchlist !== null) {
			if (maxWatchlistsReached(dashboardContext.mappedWatchlist)) {
				setMaxWatchlistsError(true);
			} else {
				setMaxWatchlistsError(false);
			}
		}
	}, [handleCreateWatchlist]);

	useEffect(() => {
		setOpenWatchlist(false);
	}, [dashboardContext.selectedInstrument]);

	useEffect(() => {
		watchlistNameRef.current = currentWatchList;
	}, [currentWatchList]);

	const setCurrentItemWatchList = (item: any) => {
		if (dashboardContext.mappedWatchlist !== null) {
			setCurrentWatchList(item);
			setWatchlistName(item);
			setOpenWatchlist(false);
		}
	};

	return (
		<>
			<div className={cn(styles.headerContainer, isDynamicWatchlist && styles.headerContainerCenter)}>
				<div className={styles.editWatchlistIcon}></div>
				{openModify && (
					<div className={styles.title}>
						{getTranslatedWLName(currentWatchList)} &nbsp;
						<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
					</div>
				)}

				{!openModify && (
					<WtrPopup
						className={styles.watchlistsPopup}
						open={openWatchlist}
						onClose={closeWatchlistPopup}
						content={
							!createWatchList ? (
								<>
									<div className={styles.watchlists}>
										{dashboardContext.mappedWatchlist !== null &&
											Object.keys(dashboardContext.mappedWatchlist).map((item, index: number) => {
												const tierName = getTranslatedWLName(item);

												return (
													<div key={index} className={styles.listContainer}>
														<div
															className={styles.itemContainer}
															onClick={() => {
																setCurrentItemWatchList(item);
															}}
														>
															<div className={styles.watchlistItem}>
																<FontAwesomeIcon
																	className={cn(styles.icon, item === currentWatchList && styles.active)}
																	icon={['fas', 'list']}
																/>
																<span
																	className={styles.watchlistName}
																	id={`${item}_${index}`}
																	onClick={() => {
																		setCurrentItemWatchList(item);
																	}}
																>
																	{tierName}
																</span>
															</div>

															{!instrumentExistsInDynamicWatchlists(
																item,
																dashboardContext.defaultWatchLists,
																isSpreadBettingAccount
															) && (
																<FontAwesomeIcon
																	icon={['fas', 'pen']}
																	size="1x"
																	className={styles.icon}
																	onClick={() => handleModify(item)}
																/>
															)}
														</div>
													</div>
												);
											})}
									</div>
									<CreateWatchlistButton
										maxWatchlistsError={maxWatchlistsError}
										handleCreateWatchlist={handleCreateWatchlist}
									/>
								</>
							) : (
								<>
									<div className={styles.createWatchlistContainer}>
										<div className={styles.createWatchlistItems}>
											<span className={styles.createWatchlistTitle}> {tt('CREATE_WATCHLIST')}</span>
										</div>
										<div className={styles.inputContainer}>
											<div className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</div>
											<div className={styles.inputBox}>
												<input
													type="text"
													className={nameError ? styles.errorInput : styles.costInput}
													maxLength={20}
													value={typedValue}
													onChange={changeValue}
													autoFocus
												/>
											</div>
											<div className={styles.messageRow}>
												{nameError.length !== 0 && <div className={styles.errorMessage}>{nameError}</div>}
												<div className={styles.inputContainerText}> {typedValue.trim().length}/20</div>
											</div>
										</div>
										<div className={styles.createWatchlistFooter}>
											<Button variant="secondary" size="sm" label={tt('CANCEL')} onClick={handleCreateWatchListClose} />
											<Button
												variant="primary"
												size="sm"
												label={tt('CREATE')}
												disabled={nameError.length > 0 || typedValue.trim().length === 0}
												onClick={verifyWatchlist}
											/>
										</div>
									</div>
								</>
							)
						}
						on={['click']}
						pinned
						position="bottom center"
						trigger={
							currentWatchList && (
								<div className={styles.title} onClick={() => setOpenWatchlist(true)}>
									{getTranslatedWLName(currentWatchList)} &nbsp;
									<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
								</div>
							)
						}
					/>
				)}

				{!isDynamicWatchlist && (
					<WtrPopup
						className={styles.watchlistSearchContainer}
						open={isWatchlistSearchOpen}
						onClose={resetWatchlistSearch}
						on="click"
						pinned
						position="bottom center"
						trigger={
							<OverlayTrigger
								delay={{ show: 750, hide: 0 }}
								key="addIcon"
								placement="top"
								overlay={
									<Tooltip className="my-tooltip" id="icon">
										{tt('ADD_INSTRUMENT')}
									</Tooltip>
								}
							>
								<span>
									<FontAwesomeIcon
										icon={['far', 'plus-circle']}
										className={styles.addToWatchlistIcon}
										size="2x"
										onClick={() => handleWatchlistSearchToggle()}
									></FontAwesomeIcon>
								</span>
							</OverlayTrigger>
						}
						content={
							<>
								<div className={styles.searchContainer}>
									<div className={styles.searchTitle}>
										{t('wtr:ADD_INSTRUMENT_TO')} {getTranslatedWLName(currentWatchList)}{' '}
									</div>
									<div className={styles.searchInputContainer}>
										<FontAwesomeIcon icon={['fas', 'search']} className={styles.inputIcon}></FontAwesomeIcon>
										<FontAwesomeIcon
											icon={['fas', 'times']}
											onClick={clearSearchInput}
											className={searchValue.length > 0 ? styles.deleteIcon : styles.hidden}
										></FontAwesomeIcon>
										<input
											className={cn(
												searchResults.length > 0 || showEmptySearchState ? styles.openSearchInput : styles.searchInput
											)}
											type="text"
											placeholder={tt('ADD_INSTRUMENT')}
											autoFocus
											ref={searchInputRef}
											value={searchValue}
											onChange={handleSearchValue}
											name="search"
											spellCheck="false"
											autoComplete="off"
										/>
									</div>
								</div>
								<div
									className={cn(
										styles.searchResults,
										showEmptySearchState && styles.hiddenScroll,
										searchResults.length > 0 && styles.boxSearch
									)}
								>
									{searchResults.length > 0 &&
										dashboardContext.mappedWatchlist !== null &&
										searchResults.map((result) => {
											const existsInWatchlist = instrumentExistsInWatchlist(
												dashboardContext.mappedWatchlist!,
												currentWatchList,
												result
											);
											let watchlists: string[] = [];
											if (existsInWatchlist) {
												watchlists = instrumentContext.watchlistsforInstrument(
													dashboardContext.mappedWatchlist!,
													result
												);
											}
											return (
												<SearchResult
													key={result.code}
													searchTerm={searchValue}
													result={result}
													existsInWatchlist={existsInWatchlist}
													onUpdateWatchlist={() => updateWatchlist(existsInWatchlist ? 'remove' : 'add', result.code)}
													watchlists={watchlists}
												/>
											);
										})}
									{showEmptySearchState && <EmptySearch invalidSearchTerm={searchValue} />}
								</div>
							</>
						}
					/>
				)}
			</div>

			<Modal show={openModify} centered dialogClassName={styles.modalWatchlist}>
				<Modal.Header className={styles.modalTopHeader}>
					<Modal.Title className={styles.modalTitle}>{t('wtr:MODIFY_WATCHLIST')}</Modal.Title>
				</Modal.Header>
				<Modal.Body className={styles.modifyWatchlistModalBody}>
					<div className={styles.modifyWatchlist}>
						<div className={styles.title}>{t('wtr:WATCHLIST_NAME')}</div>
						<div className={styles.modifyInputContainer}>
							<input
								type="text"
								className={
									nameError.length !== 0 || invalidRemoveName.length !== 0
										? styles.modifyErrorInput
										: styles.modifyInput
								}
								maxLength={20}
								value={watchlistName}
								onChange={changeWatchlistName}
							/>
						</div>
						<div className={styles.inputLimit}>{watchlistName.length}/20</div>
						{(nameError.length !== 0 || invalidRemoveName.length !== 0) && (
							<div className={styles.modifyErrorMessage}>{nameError.length > 0 ? nameError : invalidRemoveName}</div>
						)}
						<div
							className={cn(
								styles.buttonDiv,
								dashboardContext.mappedWatchlist !== null && Object.keys(dashboardContext.mappedWatchlist).length === 1
									? styles.disableBtn
									: ''
							)}
						>
							<Button
								label={t('wtr:REMOVE_WATCHLIST')}
								size="lg"
								onClick={removeWatchlist}
								disabled={watchlistName.trim().length === 0}
								className={cn(
									styles.modifyButton,
									(dashboardContext.mappedWatchlist !== null &&
										Object.keys(dashboardContext.mappedWatchlist).length === 1) ||
										watchlistName.trim().length === 0
										? styles.disableBtn
										: ''
								)}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={styles.modifyFooter}>
					<div className={styles.actionButtons}>
						<Button
							label={tt('CANCEL')}
							size="lg"
							variant="outline"
							className={styles.cancelButton}
							onClick={handleCloseModifyModal}
						/>
						<Button
							label={tt('SAVE')}
							size="lg"
							variant="primary"
							disabled={watchlistName.trim().length === 0}
							className={styles.saveButton}
							onClick={saveNewWatchlist}
						/>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TradeBoardHeader;
