import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';

import {getJPAcctIdForTierInfoRequest} from '../functions/subscriptionUtils';

import useSelectedTradingAccount from './useSelectedTradingAccount';

const useJPAcctIdForTierInfoRequest = (): string | null | undefined => {
	const appContext = useContext(AppContext);

	const selectedTradingAccount = useSelectedTradingAccount();

	if (appContext.isJapanAccount) {
		return getJPAcctIdForTierInfoRequest(appContext);
	}

	return selectedTradingAccount?.accountNumber?.toString();
};
export default useJPAcctIdForTierInfoRequest;
