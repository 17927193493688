import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from "./AccountUserIcon.module.scss";

interface IAccountUserIconProps {
    accountPopupIsOpen?: boolean;
    size?: 'small' | 'large';
}

const AccountUserIcon = ({accountPopupIsOpen, size = 'small'}: IAccountUserIconProps) => (<FontAwesomeIcon className={styles[size]} icon={[accountPopupIsOpen ? 'fas' : 'fal', 'user']} />);

export default AccountUserIcon;