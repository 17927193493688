import { useMutation } from '@tanstack/react-query';
import { SHA384 } from 'sha2';

import axios from 'axios';

import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import tradingAccountStore from '../../store/tradingAccountStore';
import { TradingAccountType } from '../functions/enums';

const useSaveUserPreferences = () => {
	const appContext = useContext(AppContext);
	const currentWatchList = tradingAccountStore.use.currentWatchList();

	const mutationFn = async () => {
		const preferences = appContext.userPreferences;
		const isJapanAccount = appContext.isJapanAccount;

		if (currentWatchList && preferences && isJapanAccount) {
			const liveCFDWatchlist = preferences?.user_prefs?.watchlists?.account.find((account) => {
				return account && account._type.toUpperCase() === TradingAccountType.LIVE && !account.accountType;
			});

			if (liveCFDWatchlist) {
				liveCFDWatchlist._lastUsedWatchlist = currentWatchList;
			}
		}

		if (preferences) {
			const convertUserPref = JSON.stringify(preferences);
			const email = appContext.email;
			try {
				const response = await axios({
					url: '/v2/preferences',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: SHA384(`${email}Bji4xKz9cPTac4B7ruET`).toString('hex'),
					},
					params: {
						email,
					},
					data: {
						email,
						preference: btoa(encodeURIComponent(convertUserPref)),
					},
				});

				if (convertUserPref && convertUserPref !== 'null') {
					localStorage.setItem(`userPref-${email}`, convertUserPref);
				}

				return response.data;
			} catch (e) {
				console.error(e);

				if (!preferences) {
					return;
				}

				if (convertUserPref && convertUserPref !== 'null') {
					localStorage.setItem(`userPref-${email}`, convertUserPref);
				}
			}
		}
	};

	return useMutation({ mutationKey: ['useSaveUserPreferences', appContext.email], mutationFn });
};

export default useSaveUserPreferences;
