import { formatNumberWithCommas } from '../../Watchlist/Instrument/formattedQuoteNumber';
import { DashboardContextProvider } from '../../../../../contexts/DashboardContext';

export const hidePopup = (isArabic: boolean) => {
	const popup = document.getElementsByClassName(`ui top ${isArabic ? 'left' : 'right'} popup transition visible`);
	if (popup && popup.length > 0) popup[0].className = `ui top ${isArabic ? 'left' : 'right'} popup transition`;
};

export const addCommas = (
	current: number | string,
	languageSettings: string,
	decPrec?: number | null,
	isPipsChange?: boolean | null
) => {
	decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;

	if (isPipsChange) decPrec = 2;

	return formatNumberWithCommas(current, decPrec, languageSettings);
};

export const closeAllTickets = (dashboardContext: DashboardContextProvider) => {
	dashboardContext.showOrderTicket = false;
	dashboardContext.showCloseTicket = false;
	dashboardContext.showCancelTicket = false;
	dashboardContext.modifyTicket = false;
	dashboardContext.showNewsFeed = false;
	dashboardContext.showConfirmTicket = false;
	dashboardContext.showOrderInformation = false;
};

export const positionTableObj = {
	side: true,
	Lots: true,
	Amount: true,
	onDate: true,
	open: true,
	change: true,
	current: true,
	netPL: true,
	grossPL: false,
	time: false,
	swap: false,
	comment: false,
	dividend: false,
	comm: false,
	posId: false,
	pipsChange: false,
};

export const orderTableObj = {
	side: true,
	Lots: true,
	Amount: true,
	onDate: true,
	open: true,
	expiryOn: false,
	distanceToMarket: true,
	type: true,
	current: true,
	createdOn: true,
	time: false,
	posId: false,
};

export const closedTableObj = {
	side: true,
	Lots: true,
	Amount: true,
	onDate: true,
	open: true,
	closed: true,
	change: true,
	openDate: true,
	closeOn: true,
	StopLoss: false,
	TakeProfit: false,
	netPL: true,
	grossPL: false,
	time: false,
	closedTime: false,
	swap: false,
	comment: false,
	dividend: false,
	comm: false,
	posId: false,
	pipsChange: true,
};
