import React, { useContext, useMemo } from 'react';
import cn from 'classnames';

import { Loader } from 'semantic-ui-react';

import { tierIcons } from '../../../../../setup/subscriptionsConfig';

import { getActiveTier } from '../../../../../utils/functions/subscriptionUtils';

import authStore from '../../../../../store/authStore';

import AppContext from '../../../../../contexts/AppContext';

import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../utils/hooks/useObservable';

import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import AccountCurrency from './AccountCurrency';

import styles from './AccountIconAndTier.module.scss';

interface AccountIconAndTierProps {
	accountPopupIsOpen?: boolean;
	size?: 'small' | 'large';
	outline?: boolean;
	customCurrency?: string;
	isForJapanSubscriptionAccount?: boolean;
}

const AccountIconAndTier = ({
	accountPopupIsOpen,
	size = 'small',
	outline = false,
	customCurrency,
	isForJapanSubscriptionAccount = false,
}: AccountIconAndTierProps) => {
	const isJapanAccount = authStore.use.isJapanAccount();
	const appContext = useContext(AppContext);
	const selectedTradingAccount = useSelectedTradingAccount();

	const forceRerender = useForceRerender();
	const iconStyle = accountPopupIsOpen ? 'fas' : 'fal';

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => {
		forceRerender();
	});


	const icon = useMemo(() => {
		if (selectedTradingAccount) {
			if (isJapanAccount && isForJapanSubscriptionAccount) {
				const activeTier = getActiveTier(appContext.subscriptionInfo);

				return activeTier ? (
					<img src={tierIcons[activeTier.toLowerCase()]} alt="Tier" className={styles[size]} />
				) : (
					<Loader className={styles.loader} active />
				);
			} else {
				return <AccountCurrency code={customCurrency || selectedTradingAccount.baseCurrency} />;
			}
		} else {
			return <Loader className={styles.loader} active />;
		}
	}, [customCurrency, iconStyle, isJapanAccount, selectedTradingAccount, size, isForJapanSubscriptionAccount, appContext.subscriptionInfo]);

	if (!outline) return icon;

	return (
		<div className={styles.imageWrapper}>
			<div className={cn(styles.imageOutline)}>{icon}</div>
		</div>
	);
};

export default AccountIconAndTier;
