import React, { useEffect, useContext } from 'react';
import cn from 'classnames';

import useForceRerender from '../../../utils/hooks/useForceRerender';
import useObservable from '../../../utils/hooks/useObservable';
import AppContext from '../../../contexts/AppContext';

import '../../../css/_dark_variables.scss';
import '../../../css/_japan_theming.scss';

import StringUtils from '../../../utils/functions/StringUtils';

import styles from './ThemeWrapper.module.scss';
import { LanguageCodes } from '../../features/Dashboard/Settings/settingsOptions';

const ThemeWrapper: React.FC = ({ children }: any) => {
	const forceRerender = useForceRerender();
	const appContext = useContext(AppContext);

	// If the appTheme context changes then a force re-render will be executed
	useObservable(appContext.getPropertyChangeStream('appTheme', 'isJapanAccount', 'languageSettings'), () => {
		forceRerender();
	});

	const isJapanese =
		appContext.isJapanAccount || appContext.languageSettings === LanguageCodes.JAPANESE ? 'isJapanese' : '';
	const isThai = appContext.languageSettings === LanguageCodes.THAI ? 'isThai' : '';
	const isVietnamese = appContext.languageSettings === LanguageCodes.VIETNAMESE ? 'isVietnamese' : '';
	const isArab = appContext.languageSettings === LanguageCodes.ARABIC ? 'isArab' : '';
	const isTraditionalChinese =
		appContext.languageSettings === LanguageCodes.CHINESE_TRADITIONAL ? 'isTraditionalChinese' : '';
	const isSimplifiedChinese =
		appContext.languageSettings === LanguageCodes.CHINESE_SIMPLIFIED ? 'isSimplifiedChinese' : '';

	useEffect(() => {
		document.body.classList.remove('light', 'dark');
		document.body.classList.add(appContext.appTheme);

		if (!StringUtils.isNullOrEmpty(isJapanese)) {
			document.body.classList.add(isJapanese);
		}

		if (!StringUtils.isNullOrEmpty(isVietnamese)) {
			document.body.classList.add(isVietnamese);
		}

		if (!StringUtils.isNullOrEmpty(isThai)) {
			document.body.classList.add(isThai);
		}

		if (!StringUtils.isNullOrEmpty(isArab)) {
			document.body.classList.add(isArab);
		}

		if (!StringUtils.isNullOrEmpty(isTraditionalChinese)) {
			document.body.classList.add(isTraditionalChinese);
		}

		if (!StringUtils.isNullOrEmpty(isSimplifiedChinese)) {
			document.body.classList.add(isSimplifiedChinese);
		}
	}, [isJapanese, isVietnamese, isThai, isArab, isTraditionalChinese, isSimplifiedChinese, appContext.appTheme]);

	// let width = window.screen.width;
	//
	// useEffect(() => {
	// 	if (navigator.maxTouchPoints > 0) {
	// 		width = window.screen.width;
	// 	}
	// }, [navigator.maxTouchPoints]);

	return (
		<div
			// style={navigator.maxTouchPoints ? { width: '100vw' } : {}}
			className={cn(appContext.appTheme || '', styles.fullHeightWidth)}
		>
			{children}
		</div>
	);
};

export default ThemeWrapper;
