import { MarketItem, MarketWatchItem } from '../../../gateways/RfpGateway/rfp.types';

/**
 * Creates a map of market items grouped by groupCode
 * @param data {MarketItem} - Array of market items
 * @returns {Record<string, MarketItem>} - Map of market items grouped by groupCode
 */
export const createMarketItemCodeMap = (data: MarketItem[]): Record<string, MarketItem> =>
	data.reduce((all, item) => {
		all[item.code] = item;

		return all;
	}, {} as Record<string, MarketItem>);

/**
 * Creates a map of market items grouped by group for CFD
 * @param data {MarketItem[]} - Array of market items
 * @returns {Record<string, MarketItem[]>} - Map of market items grouped by groupCode
 */
export const createMarketItemGroupMap = (data: MarketItem[]): Record<string, MarketItem[]> =>
	data.reduce((all, item) => {
		if (all[item.grp]) {
			all[item.grp].push(item);
		} else {
			all[item.grp] = [item];
		}

		return all;
	}, {} as Record<string, MarketItem[]>);

export interface TableMarketItem {
	symbol: string;
	name: string;
	volatility: number;
	currency: string;
	change: number;
	low: string;
	high: string;
	sell: string;
	buy: string;
	settings: string;
}

export interface PositionTableItem {
	simulationId: string;
	instrument: string;
	side: string;
	Lots: number;
	Amount: number;
	onDate: string;
	open: string;
	change: number;
	current: number;
	TakeProfit: number;
	StopLoss: number;
	netPL: number;
	grossPL: number;
	settings: string;
	subRows: any;
	close: boolean;
	time: string;
	comment: string;
	comm: string;
	dividend: string;
	swap: string;
	posId: string;
	headID: string;
	pipsChange: string;
	id: string;
}
export interface CloseTableItem {
	simulationId: string;
	instrument: string;
	headID: string;
	side: string;
	Lots: number;
	Amount: number;
	onDate: string;
	open: string;
	closeOn: string;
	openDate: string;
	change: number;
	current: number;
	TakeProfit: number;
	StopLoss: number;
	netPL: number;
	grossPL: number;
	settings: string;
	subRows: any;
	close: boolean;
	closed: boolean;
	time: string;
	closedTime: string;
	comment: string;
	comm: string;
	dividend: string;
	swap: string;
	posId: string;
	pipsChange: string;
	id: string;
}

export interface OrderTableItem {
	simulationId: string;
	instrument: string;
	side: string;
	Lots: number;
	Amount: number;
	onDate: string;
	open: string;
	createdOn: string;
	expiryOn: string;
	headID: string;
	change: number;
	current: number;
	TakeProfit: number;
	StopLoss: number;
	distanceToMarket: number;
	type: string;
	settings: string;
	subRows: any;
	close: boolean;
	time: string;
	posId: string;
}

interface CreateMarketItemGroupTableDataProps {
	marketItems: MarketItem[];
	changeMap?: Record<string, number>;
	volatilityMap?: Record<string, number>;
}

/**
 * Creates a map of market items that is ready for the table
 * @param marketItems {MarketItem[]} - Array of market items
 * @param changeMap {Record<string, number>} - Map of market items with change
 * @param volatilityMap {Record<string, number>} - Map of market items with volatility
 * @returns {TableMarketItem[]} - Array of market items ready for the table
 */
export const createMarketItemGroupTableData = ({
	marketItems,
	changeMap = {},
	volatilityMap = {},
}: CreateMarketItemGroupTableDataProps): TableMarketItem[] =>
	marketItems.reduce((all, item) => {
		const tableItem: TableMarketItem = {
			symbol: item.code,
			name: item.fullName,
			volatility: volatilityMap[item.code] || 0,
			currency: item.ccy,
			change: changeMap[item.code] || 0,
			low: item.code,
			high: item.code,
			sell: item.code,
			buy: item.code,
			settings: item.code,
		};

		all.push(tableItem);
		return all;
	}, [] as TableMarketItem[]);

/**
 * Creates an array with market item codes from Market Item Type
 * @param data {MarketItem[]} - Array of market items
 * @returns {string[]} - Array of market item codes
 */
export const createMarketItemCodes = (data: MarketItem[]): string[] =>
	data.reduce((all, item) => {
		all.push(item.code);
		return all;
	}, [] as string[]);

/**
 * Creates an array with market item codes from Market Watch Item type
 * @param data {MarketItem[]} - Array of market items
 * @returns {string[]} - Array of market item codes
 */
export const createMarketWatchItemCodes = (data: MarketWatchItem[]): string[] =>
	data.reduce((all, item) => {
		if (item.code) {
			all.push(item.code);
		}

		return all;
	}, [] as string[]);

enum Tier {
	Tier1 = 'Tier1',
	Tier2 = 'Tier2',
	Tier3 = 'Tier3',
}

/**
 * Creates a map of market items grouped by tier for Japanese market
 * @param data {MarketItem[]} - Array of market items
 * @returns {Record<string, MarketItem[]>} - Map of market items grouped by tier
 */
export const createMarketItemTierMap = (data: MarketItem[]): Record<string, MarketItem[]> =>
	data.reduce(
		(all, item) => {
			const tier = item.minTier as number;

			if (tier === 1) {
				all[Tier.Tier1].push(item);
				all[Tier.Tier2].push(item);
				all[Tier.Tier3].push(item);
			}

			if (tier === 2) {
				all[Tier.Tier2].push(item);
				all[Tier.Tier3].push(item);
			}

			if (tier === 3) {
				all[Tier.Tier3].push(item);
			}

			return all;
		},
		{
			Tier1: [],
			Tier2: [],
			Tier3: [],
		} as Record<string, MarketItem[]>
	);

interface CreateMarketItemsArrayFromCodesProps {
	codes: string[];
	marketsItemMap: Record<string, MarketItem>;
}

/**
 * Creates an array with market items from Market Item Codes
 * @param codes {string[]} - Array of market item codes
 * @param marketsItemMap {Record<string, MarketItem>} - Map of market items by Code
 * @returns {MarketItem[]} - Array of market items
 */
export const createMarketItemsArrayFromCodes = ({
	codes,
	marketsItemMap,
}: CreateMarketItemsArrayFromCodesProps): MarketItem[] =>
	codes.reduce((all, code) => {
		if (marketsItemMap[code]) {
			all.push(marketsItemMap[code]);
		}

		return all;
	}, [] as MarketItem[]);
