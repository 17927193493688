import { createContext } from 'react';
import clone from 'clone';

import { Resolver } from '../utils/functions/Ioc';
import { default as Lazy } from '../utils/functions/Lazy';
import { ObservableObject } from '../utils/functions/Observables';
import { Optional } from '../utils/functions/Nullable';
import {
	TWatchlistPreference,
	TSymbolPreference,
	TWatchlists,
	TWatchlistInstrument,
} from '../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { default as RfpGateway } from '../gateways/RfpGateway/RfpGateway';
import {
	IChartParameters,
	MarketItem,
	TradeRequest,
	TradingInstruments,
	MarketItemInfo,
	RFPNews,
	TradingAccount,
	TradingPosition,
	orderTypes,
	MarketWatchIntervals,
	MarketWatchCategories,
	MarketWatchItem,
	MarketWatchItems,
	PreviousDayClosePrices,
} from '../gateways/RfpGateway/rfp.types';

import { AppComponentType } from '../utils/functions/enums';

export type TModalToggle = {
	type: 'CLOSE_ORDER' | 'CLOSE_POSITION';
	value: boolean;
};

export type TNewOrderModalToggle = {
	type: 'ORDER_TICKET' | 'CONFIRM_ORDER' | 'ORDER_SUCCESS' | 'ADD_PROFIT_LOSS';
	value: boolean;
};

export type TOpenOrder = {
	symbol: string;
	side: string;
	commission?: string;
	quantity: string;
	openPrice: string;
	currentPrice: string;
	stopLoss: string;
	takeProfit: string;
	pL: string;
	dividend: string;
	risk: string;
	swap: string;
	orderID: string;
};

export type TExecutedOrder = {
	symbol: string;
	side: string;
	quantity: string;
	openPrice: string;
	currentPrice: string;
	stopLoss: string;
	takeProfit: string;
	pL: string;
	dividend: string;
	risk: string;
	commission: string;
	swap: string;
	orderID: string;
};

export type TClosedOrder = {
	closeTime: string;
	symbol: string;
	side: string;
	quantity: string;
	openPrice: string;
	currentPrice: string;
	stopLoss: string;
	takeProfit: string;
	pL: string;
	dividend: string;
	risk: string;
	commission: string;
	swap: string;
	orderID: string;
};

export type TChartFeed = {
	c: number;
	cf: number;
	f: string;
	h: number;
	l: number;
	o: number;
	r: string;
	t: number;
	v: number;
	x: string;
};

export interface WatchListItemCodes {
	_code: string;
}

export interface WatchListItem {
	codes_csv: WatchListItemCodes[];
	order: number;
	title: string;
}

export interface DefaultWatchList {
	isoCode: string;
	items: WatchListItem[];
}

export type TMappedWatchlist = {
	[key: string]: TWatchlistInstrument[];
};

export type TLoadingState = {
	type: 'MARKET' | 'ACCOUNT' | 'POSITION';
	value: boolean;
};

export type TOrderConfirm = {
	new: boolean;
	edit: boolean;
	cancel: boolean;
	close: boolean;
};

export type TInputError = {
	type: 'AMOUNT-LOTS' | 'LIMIT-STOP' | 'TAKE-PROFIT' | 'STOP-LOSS' | 'CLOSE-QUANTITY' | 'ADD-PROFIT' | 'ADD-LOSS';
	value: boolean | string;
};

export type TNotification = {
	isActive: boolean;
	message: string;
	type: 'info' | 'error';
};

export type TOrderValue = {
	amountLots: string;
	limitStop: string;
	takeProfit: string;
	stopLoss: string;
	closePosition: string;
	closeQuantity: string;
	addProfit: string;
	addLoss: string;
	label: string;
};

export type TShowProfitLoss = {
	takeProfit: boolean;
	stopLoss: boolean;
	addProfit: boolean;
	addLoss: boolean;
};

export type TInputValidation = {
	addProfit: boolean;
	addLoss: boolean;
	amountLots: boolean;
	closeQuantity: boolean;
	limitStop: boolean;
	highAmount: boolean;
};

type TDashboardContextProperties = {
	accountType: 'cfd' | 'equity';
	alreadyEstablished: boolean;
	orderModalOpenedFrom: string;
	accountValues: {
		balance: number;
		margin: number;
		freeMargin: number;
		marginLevel: number;
		equity: number;
		organizationName: string;
		pl: number;
	};
	updateMarketWatchItem: MarketWatchItem | null;
	marketWatchIntervals: MarketWatchIntervals | MarketWatchIntervals[];
	marketWatchCategories: MarketWatchCategories | MarketWatchCategories[];
	marketWatchItem: MarketWatchItem[];
	marketWatchItems: MarketWatchItems[];
	marketWatchRisers: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	marketWatchRisersSB: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	marketWatchFallers: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	marketWatchFallersSB: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	marketWatchMostVolatile: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	marketWatchMostVolatileSB: {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	};
	chartParameters: IChartParameters;
	closedTable: TClosedOrder[];
	historicalData: TChartFeed[];
	detailedInformation: TradingInstruments[];
	equityWatchlist: TWatchlistPreference['equity'];
	defaultWatchLists: DefaultWatchList[];
	mainPanelView: number;
	marketItems: MarketItem[];
	marketItemsInfo: MarketItemInfo[];
	multiCharts: boolean;
	gridViewCharts: boolean;
	newChart: boolean;
	notificationMessage: string;
	notificationType: 'info' | 'error';
	tradeNews: RFPNews[];
	openInformation: number;
	openTable: TOpenOrder[];
	orderTable: TExecutedOrder[];
	// orderType: 'SELL' | 'BUY' | 'NEW';
	quantityType: 'Lots' | 'Amount';
	notificationSoundMuted: boolean;
	notificationSoundVolume: number;
	tradeFromChartEnabled: boolean;
	oneClickTrading: boolean;
	rfpInfoStatus: any;
	rfpConnectionError: any;
	selectedTradingAccount: number;
	selectedInstrument: Optional<MarketItem>;
	selectedSymbol: any;
	selectedPosition: any;
	selectedNewsContent: number;
	symbolChanged: string;
	toggleOverlay: number;
	positionProfitLosses: number;
	tradingAccount: TradingAccount[];
	tradingPositions: Record<number, TradingPosition>;
	tradingPositionsArr: TradingPosition[];
	tradingPositionsLength: number;
	twoFactorAuthModalToggle: boolean;
	watchlist: TWatchlists[];
	mappedWatchlist: TMappedWatchlist | null;
	hsSubs: any;
	instruments: any;
	watchlistAccount: any;
	accountChange: boolean;
	orderConfirm: TOrderConfirm;
	keyPress: any;
	marketIndex: any;
	rfpGatewayProvider: Optional<() => RfpGateway>;
	lastSymbol: Optional<TSymbolPreference>;
	inactiveTime: number;
	inactiveModal: boolean;
	openOrder: any;
	openOrderInformation: boolean;
	toggleAccordionMenu: string;
	inputValidation: TInputValidation;
	orderValue: TOrderValue;
	trade: any;
	currentPrice: any;
	isEdit: boolean;
	dragPosition: any;
	newAccount: boolean;
	showProfitLoss: TShowProfitLoss;
	activeIndex: number;
	gridClicked: boolean | number;
	selectedSymbolUpdated: boolean;
	getOrderInfo: any;
	instrumentLastTrade: any;
	settingsOptions: any;
	gridChartsChanged: boolean;
	showOrderTicket: boolean;
	showOrderInformation: boolean;
	showCloseTicket: boolean;
	showClosedPositionTicket: boolean;
	showConfirmTicket: boolean;
	tradeInformation: Optional<TradeRequest>;
	showCancelTicket: boolean;
	gridButton: any;
	modifyTicket: boolean;
	gridChartInitialLoad: boolean;
	crossHairState: any;
	showNewsFeed: boolean;
	selectedType: string;
	toggleSort: boolean;
	watchListStoredAsArray: any;
	closeTrade: any;
	navigation: any;
	startTradingNow: boolean;
	tabnumber: number;
	maximizeGrid: boolean;
	applicationStatus: string | null | undefined;
	chartTimezone: any;
	initialTimezone: Optional<string>;
	headerSelected: boolean;
	accountIndex: any;
	closeLength: number;
	emptyChartModal: boolean;
	ticketLoad: boolean;
	typeOfOrder: orderTypes;
	userGuide: boolean;
	modalToggle: {
		closePosition: boolean;
		closeOrder: boolean;
	};
	newOrderModalToggle: {
		orderTicket: boolean;
		confirmOrder: boolean;
		amount?: number;
		limit?: number;
		stopLoss?: number;
		takeProfit?: number;
		date?: string;
		instrument?: string;
	};
	notification: TNotification;
	reconnection: {
		isReconnecting: boolean;
		count: number;
	};
	loadingState: {
		marketItems: boolean;
		tradingAccounts: boolean;
		tradingPositions: boolean;
	};
	presentComponentType: AppComponentType;
	accountPopupToggle: boolean;
	tfboConnectionError: boolean;
	ssoTradingAccount: string | null;
	previousDayClosePrices: PreviousDayClosePrices;
};

let defaultValues = new Lazy<TDashboardContextProperties>(() => {
	return {
		presentComponentType: AppComponentType.Watchlist,
		accountType: 'cfd',
		alreadyEstablished: false,
		accountValues: {
			balance: 0,
			margin: 0,
			freeMargin: 0,
			marginLevel: 0,
			equity: 0,
			pl: 0,
			organizationName: '',
		},
		orderModalOpenedFrom: '',
		chartParameters: {
			func: 'pullInitialData',
			symbol: '',
			period: 1,
			timeUnit: 'minute',
			interval: 1,
			start: '',
			end: '',
		},
		updateMarketWatchItem: null,
		marketWatchIntervals: [],
		marketWatchCategories: [],
		marketWatchItem: [],
		marketWatchItems: [],
		marketWatchRisers: {
			daily: [],
			weekly: [],
		},
		marketWatchRisersSB: {
			daily: [],
			weekly: [],
		},
		marketWatchFallers: {
			daily: [],
			weekly: [],
		},
		marketWatchFallersSB: {
			daily: [],
			weekly: [],
		},
		marketWatchMostVolatile: {
			daily: [],
			weekly: [],
		},
		marketWatchMostVolatileSB: {
			daily: [],
			weekly: [],
		},
		closedTable: [],
		historicalData: [],
		detailedInformation: [],
		equityWatchlist: {},
		defaultWatchLists: [],
		mainPanelView: 2,
		marketItems: [],
		marketItemsInfo: [],
		multiCharts: true,
		gridViewCharts: true,
		newChart: false,
		notificationMessage: 'My Error Message',
		notificationType: 'error',
		tradeNews: [],
		openInformation: -1,
		openTable: [],
		orderTable: [],
		orderType: 'BUY',
		quantityType: 'Amount',
		notificationSoundMuted: false,
		notificationSoundVolume: 50,
		tradeFromChartEnabled: true,
		rfpInfoStatus: {},
		rfpConnectionError: {},
		selectedTradingAccount: 0,
		selectedInstrument: null,
		selectedSymbol: {},
		selectedPosition: {},
		selectedNewsContent: -1,
		symbolChanged: '',
		toggleOverlay: -1,
		positionProfitLosses: 0,
		tradingAccount: [],
		tradingPositions: {},
		tradingPositionsArr: [],
		tradingPositionsLength: 0,
		twoFactorAuthModalToggle: false,
		startTradingNow: false,
		watchlist: [],
		mappedWatchlist: null,
		hsSubs: [],
		instruments: {},
		watchlistAccount: [],
		accountChange: false,
		orderConfirm: {
			new: false,
			edit: false,
			cancel: false,
			close: false,
		},
		oneClickTrading: false,
		keyPress: -1,
		marketIndex: -1,
		rfpGatewayProvider: null,
		lastSymbol: null,
		inactiveTime: 3600,
		inactiveModal: false,
		openOrder: [],
		openOrderInformation: false,
		toggleAccordionMenu: '',
		inputValidation: {
			amountLots: false,
			limitStop: false,
			addProfit: false,
			addLoss: false,
			closeQuantity: false,
			highAmount: false,
		},
		orderValue: {
			amountLots: '',
			limitStop: '',
			takeProfit: '',
			stopLoss: '',
			closePosition: '',
			closeQuantity: '',
			addProfit: '',
			addLoss: '',
			label: '',
		},
		trade: [],
		toggleOrdersTFC: false,
		toggleTradesTFC: false,
		emptyChartModal: false,
		currentPrice: 0,
		isEdit: false,
		dragPosition: {},
		newAccount: false,
		showProfitLoss: {
			takeProfit: false,
			stopLoss: false,
			addProfit: false,
			addLoss: false,
		},
		activeIndex: 0,
		gridClicked: 0,
		selectedSymbolUpdated: false,
		getOrderInfo: {},
		instrumentLastTrade: [],
		settingsOptions: {},
		gridChartsChanged: false,
		showOrderTicket: false,
		showOrderInformation: false,
		showCloseTicket: false,
		showClosedPositionTicket: false,
		showConfirmTicket: false,
		tradeInformation: null,
		showCancelTicket: false,
		gridButton: '',
		modifyTicket: false,
		gridChartInitialLoad: false,
		crossHairState: {},
		showNewsFeed: false,
		selectedType: 'Instrument',
		toggleSort: false,
		watchListStoredAsArray: [],
		closeTrade: 'Today',
		navigation: 'Watchlist',
		tabnumber: 0,
		maximizeGrid: false,
		applicationStatus: undefined,
		chartTimezone: '',
		initialTimezone: '',
		headerSelected: false,
		accountIndex: 1,
		closeLength: 0,
		ticketLoad: false,
		typeOfOrder: 'market',
		userGuide: false,
		modalToggle: {
			closePosition: false,
			closeOrder: false,
		},
		newOrderModalToggle: {
			orderTicket: false,
			confirmOrder: false,
		},
		notification: {
			isActive: false,
			message: '',
			type: 'error',
		},
		reconnection: {
			isReconnecting: false,
			count: 0,
		},
		loadingState: {
			marketItems: false,
			tradingAccounts: false,
			tradingPositions: false,
		},
		accountPopupToggle: false,
		tfboConnectionError: false,
		ssoTradingAccount: null,
		previousDayClosePrices: {},
		closeAllOtherTabs: null,
	};
});

interface IDashboardContext extends TDashboardContextProperties {
	reset(): void;
}

export class DashboardContextProvider
	extends ObservableObject<DashboardContextProvider, keyof TDashboardContextProperties>
	implements IDashboardContext
{
	private m_propertyValues: TDashboardContextProperties;
	private static _instance = new Lazy(() => {
		return new DashboardContextProvider();
	});

	public static get instance(): DashboardContextProvider {
		return this._instance.getValue();
	}

	public get marketWatchIntervals(): MarketWatchIntervals | MarketWatchIntervals[] {
		return this.m_propertyValues.marketWatchIntervals;
	}

	public set marketWatchIntervals(value: MarketWatchIntervals | MarketWatchIntervals[]) {
		this.raiseAndSetIfChanged('marketWatchIntervals', value, this.m_propertyValues.marketWatchIntervals, (value) => {
			this.m_propertyValues.marketWatchIntervals = value;
		});
	}

	public get marketWatchCategories(): MarketWatchCategories | MarketWatchCategories[] {
		return this.m_propertyValues.marketWatchCategories;
	}

	public set marketWatchCategories(value: MarketWatchCategories | MarketWatchCategories[]) {
		this.raiseAndSetIfChanged('marketWatchCategories', value, this.m_propertyValues.marketWatchCategories, (value) => {
			this.m_propertyValues.marketWatchCategories = value;
		});
	}

	public get marketWatchItem(): MarketWatchItem[] {
		return this.m_propertyValues.marketWatchItem;
	}

	public set marketWatchItem(value: MarketWatchItem[]) {
		this.raiseAndSetIfChanged('marketWatchItem', value, this.m_propertyValues.marketWatchItem, (value) => {
			this.m_propertyValues.marketWatchItem = value;
		});
	}

	public get defaultWatchLists(): DefaultWatchList[] {
		return this.m_propertyValues.defaultWatchLists;
	}

	public set defaultWatchLists(value: DefaultWatchList[]) {
		this.raiseAndSetIfChanged('defaultWatchLists', value, this.m_propertyValues.defaultWatchLists, (value) => {
			this.m_propertyValues.defaultWatchLists = value;
		});
	}

	public get updateMarketWatchItem(): MarketWatchItem | null {
		return this.m_propertyValues.updateMarketWatchItem;
	}

	public set updateMarketWatchItem(value: MarketWatchItem | null) {
		this.raiseAndSetIfChanged('updateMarketWatchItem', value, this.m_propertyValues.updateMarketWatchItem, (value) => {
			this.m_propertyValues.updateMarketWatchItem = value;
		});
	}

	public get marketWatchItems(): MarketWatchItems[] {
		return this.m_propertyValues.marketWatchItems;
	}

	public set marketWatchItems(value: MarketWatchItems[]) {
		this.raiseAndSetIfChanged('marketWatchItems', value, this.m_propertyValues.marketWatchItems, (value) => {
			this.m_propertyValues.marketWatchItems = value;
		});
	}

	public get marketWatchRisers(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchRisers;
	}

	public set marketWatchRisers(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged('marketWatchRisers', value, this.m_propertyValues.marketWatchRisers, (value) => {
			this.m_propertyValues.marketWatchRisers = value;
		});
	}

	public get marketWatchRisersSB(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchRisersSB;
	}

	public set marketWatchRisersSB(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged('marketWatchRisersSB', value, this.m_propertyValues.marketWatchRisersSB, (value) => {
			this.m_propertyValues.marketWatchRisersSB = value;
		});
	}

	public get marketWatchFallers(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchFallers;
	}

	public set marketWatchFallers(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged('marketWatchFallers', value, this.m_propertyValues.marketWatchFallers, (value) => {
			this.m_propertyValues.marketWatchFallers = value;
		});
	}

	public get marketWatchFallersSB(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchFallersSB;
	}

	public set marketWatchFallersSB(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged('marketWatchFallersSB', value, this.m_propertyValues.marketWatchFallersSB, (value) => {
			this.m_propertyValues.marketWatchFallersSB = value;
		});
	}

	public get marketWatchMostVolatile(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchMostVolatile;
	}

	public set marketWatchMostVolatile(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged(
			'marketWatchMostVolatile',
			value,
			this.m_propertyValues.marketWatchMostVolatile,
			(value) => {
				this.m_propertyValues.marketWatchMostVolatile = value;
			}
		);
	}
	public get marketWatchMostVolatileSB(): {
		daily: MarketWatchItems[];
		weekly: MarketWatchItems[];
	} {
		return this.m_propertyValues.marketWatchMostVolatileSB;
	}

	public set marketWatchMostVolatileSB(value: { daily: MarketWatchItems[]; weekly: MarketWatchItems[] }) {
		this.raiseAndSetIfChanged(
			'marketWatchMostVolatileSB',
			value,
			this.m_propertyValues.marketWatchMostVolatileSB,
			(value) => {
				this.m_propertyValues.marketWatchMostVolatileSB = value;
			}
		);
	}

	public get accountType(): 'cfd' | 'equity' {
		return this.m_propertyValues.accountType;
	}

	public set accountType(value: 'cfd' | 'equity') {
		this.raiseAndSetIfChanged('accountType', value, this.m_propertyValues.accountType, (value) => {
			this.m_propertyValues.accountType = value;
		});
	}

	public get alreadyEstablished(): boolean {
		return this.m_propertyValues.alreadyEstablished;
	}

	public set alreadyEstablished(value: boolean) {
		this.raiseAndSetIfChanged('alreadyEstablished', value, this.m_propertyValues.alreadyEstablished, (value) => {
			this.m_propertyValues.alreadyEstablished = value;
		});
	}

	public get orderModalOpenedFrom(): string {
		return this.m_propertyValues.orderModalOpenedFrom;
	}

	public set orderModalOpenedFrom(value: string) {
		this.raiseAndSetIfChanged('orderModalOpenedFrom', value, this.m_propertyValues.orderModalOpenedFrom, (value) => {
			this.m_propertyValues.orderModalOpenedFrom = value;
		});
	}

	public get chartParameters(): IChartParameters {
		return this.m_propertyValues.chartParameters;
	}

	public set chartParameters(value: IChartParameters) {
		this.raiseAndSetIfChanged('chartParameters', value, this.m_propertyValues.chartParameters, (value) => {
			this.m_propertyValues.chartParameters = value;
		});
	}

	public get closedTable(): TClosedOrder[] {
		return this.m_propertyValues.closedTable;
	}

	public set closedTable(value: TClosedOrder[]) {
		this.raiseAndSetIfChanged('closedTable', value, this.m_propertyValues.closedTable, (value) => {
			this.m_propertyValues.closedTable = value;
		});
	}

	public get historicalData(): TChartFeed[] {
		return this.m_propertyValues.historicalData;
	}

	public set historicalData(value: TChartFeed[]) {
		this.raiseAndSetIfChanged('historicalData', value, this.m_propertyValues.historicalData, (value) => {
			this.m_propertyValues.historicalData = value;
		});
	}

	public get detailedInformation(): TradingInstruments[] {
		return this.m_propertyValues.detailedInformation;
	}

	public set detailedInformation(value: TradingInstruments[]) {
		this.raiseAndSetIfChanged('detailedInformation', value, this.m_propertyValues.detailedInformation, (value) => {
			this.m_propertyValues.detailedInformation = value;
		});
	}

	public get equityWatchlist(): TWatchlistPreference['equity'] {
		return this.m_propertyValues.equityWatchlist;
	}

	public set equityWatchlist(value: TWatchlistPreference['equity']) {
		this.raiseAndSetIfChanged('equityWatchlist', value, this.m_propertyValues.equityWatchlist, (value) => {
			this.m_propertyValues.equityWatchlist = value;
		});
	}

	public get mainPanelView(): number {
		return this.m_propertyValues.mainPanelView;
	}

	public set mainPanelView(value: number) {
		this.raiseAndSetIfChanged('mainPanelView', value, this.m_propertyValues.mainPanelView, (value) => {
			this.m_propertyValues.mainPanelView = value;
		});
	}

	public get marketItems(): MarketItem[] {
		return this.m_propertyValues.marketItems;
	}

	public set marketItems(value: MarketItem[]) {
		this.raiseAndSetIfChanged('marketItems', value, this.m_propertyValues.marketItems, (value) => {
			this.m_propertyValues.marketItems = value;
		});
	}

	public get marketItemsInfo(): MarketItemInfo[] {
		return this.m_propertyValues.marketItemsInfo;
	}

	public set marketItemsInfo(value: MarketItemInfo[]) {
		this.raiseAndSetIfChanged('marketItemsInfo', value, this.m_propertyValues.marketItemsInfo, (value) => {
			this.m_propertyValues.marketItemsInfo = value;
		});
	}

	public get presentComponentType(): AppComponentType {
		return this.m_propertyValues.presentComponentType;
	}

	public set presentComponentType(value: AppComponentType) {
		this.raiseAndSetIfChanged('presentComponentType', value, this.m_propertyValues.presentComponentType, (value) => {
			this.m_propertyValues.presentComponentType = value;
		});
	}

	public get multiCharts(): boolean {
		return this.m_propertyValues.multiCharts;
	}

	public set multiCharts(value: boolean) {
		this.raiseAndSetIfChanged('multiCharts', value, this.m_propertyValues.multiCharts, (value) => {
			this.m_propertyValues.multiCharts = value;
		});
	}

	public get gridViewCharts(): boolean {
		return this.m_propertyValues.gridViewCharts;
	}

	public set gridViewCharts(value: boolean) {
		this.raiseAndSetIfChanged('gridViewCharts', value, this.m_propertyValues.gridViewCharts, (value) => {
			this.m_propertyValues.gridViewCharts = value;
		});
	}

	public get newChart(): boolean {
		return this.m_propertyValues.newChart;
	}

	public set newChart(value: boolean) {
		this.raiseAndSetIfChanged('newChart', value, this.m_propertyValues.newChart, (value) => {
			this.m_propertyValues.newChart = value;
		});
	}

	public get notificationMessage(): string {
		return this.m_propertyValues.notificationMessage;
	}

	public set notificationMessage(value: string) {
		this.raiseAndSetIfChanged('notificationMessage', value, this.m_propertyValues.notificationMessage, (value) => {
			this.m_propertyValues.notificationMessage = value;
		});
	}

	public get notificationType(): 'info' | 'error' {
		return this.m_propertyValues.notificationType;
	}

	public set notificationType(value: 'info' | 'error') {
		this.raiseAndSetIfChanged('notificationType', value, this.m_propertyValues.notificationType, (value) => {
			this.m_propertyValues.notificationType = value;
		});
	}

	public get tradeNews(): RFPNews[] {
		return this.m_propertyValues.tradeNews;
	}

	public set tradeNews(value: RFPNews[]) {
		this.raiseAndSetIfChanged('tradeNews', value, this.m_propertyValues.tradeNews, (value) => {
			this.m_propertyValues.tradeNews = value;
		});
	}

	public get openInformation(): number {
		return this.m_propertyValues.openInformation;
	}

	public set openInformation(value: number) {
		this.raiseAndSetIfChanged('openInformation', value, this.m_propertyValues.openInformation, (value) => {
			this.m_propertyValues.openInformation = value;
		});
	}

	public get openTable(): TOpenOrder[] {
		return this.m_propertyValues.openTable;
	}

	public set openTable(value: TOpenOrder[]) {
		this.raiseAndSetIfChanged('openTable', value, this.m_propertyValues.openTable, (value) => {
			this.m_propertyValues.openTable = value;
		});
	}

	public get orderTable(): TExecutedOrder[] {
		return this.m_propertyValues.orderTable;
	}

	public set orderTable(value: TExecutedOrder[]) {
		this.raiseAndSetIfChanged('orderTable', value, this.m_propertyValues.orderTable, (value) => {
			this.m_propertyValues.orderTable = value;
		});
	}

	// public get orderType(): 'SELL' | 'BUY' | 'NEW' {
	// 	return this.m_propertyValues.orderType;
	// }

	// public set orderType(value: 'SELL' | 'BUY' | 'NEW') {
	// 	this.raiseAndSetIfChanged('orderType', value, this.m_propertyValues.orderType, (value) => {
	// 		this.m_propertyValues.orderType = value;
	// 	});
	// }

	public get quantityType(): 'Lots' | 'Amount' {
		return this.m_propertyValues.quantityType;
	}

	public set quantityType(value: 'Lots' | 'Amount') {
		this.raiseAndSetIfChanged('quantityType', value, this.m_propertyValues.quantityType, (value) => {
			this.m_propertyValues.quantityType = value;
		});
	}

	public get notificationSoundMuted(): boolean {
		return this.m_propertyValues.notificationSoundMuted;
	}

	public set notificationSoundMuted(value: boolean) {
		this.raiseAndSetIfChanged(
			'notificationSoundMuted',
			value,
			this.m_propertyValues.notificationSoundMuted,
			(value) => {
				this.m_propertyValues.notificationSoundMuted = value;
			}
		);
	}

	public get notificationSoundVolume(): number {
		return this.m_propertyValues.notificationSoundVolume;
	}
	public set notificationSoundVolume(value: number) {
		this.raiseAndSetIfChanged(
			'notificationSoundVolume',
			value,
			this.m_propertyValues.notificationSoundVolume,
			(value) => {
				this.m_propertyValues.notificationSoundVolume = value;
			}
		);
	}

	public get tradeFromChartEnabled(): boolean {
		return this.m_propertyValues.tradeFromChartEnabled;
	}

	public set tradeFromChartEnabled(value: boolean) {
		this.raiseAndSetIfChanged('tradeFromChartEnabled', value, this.m_propertyValues.tradeFromChartEnabled, (value) => {
			this.m_propertyValues.tradeFromChartEnabled = value;
		});
	}

	public get rfpInfoStatus(): any {
		return this.m_propertyValues.rfpInfoStatus;
	}

	public set rfpInfoStatus(value: any) {
		this.raiseAndSetIfChanged('rfpInfoStatus', value, this.m_propertyValues.rfpInfoStatus, (value) => {
			this.m_propertyValues.rfpInfoStatus = value;
		});
	}

	public get rfpConnectionError(): any {
		return this.m_propertyValues.rfpConnectionError;
	}

	public set rfpConnectionError(value: any) {
		this.raiseAndSetIfChanged('rfpConnectionError', value, this.m_propertyValues.rfpConnectionError, (value) => {
			this.m_propertyValues.rfpConnectionError = value;
		});
	}

	public get selectedTradingAccount(): number {
		return this.m_propertyValues.selectedTradingAccount;
	}

	public set selectedTradingAccount(value: number) {
		this.raiseAndSetIfChanged(
			'selectedTradingAccount',
			value,
			this.m_propertyValues.selectedTradingAccount,
			(value) => {
				this.m_propertyValues.selectedTradingAccount = value;
			}
		);
	}

	public get selectedInstrument(): Optional<MarketItem> {
		return this.m_propertyValues.selectedInstrument;
	}

	public set selectedInstrument(value: Optional<MarketItem>) {
		this.raiseAndSetIfChanged('selectedInstrument', value, this.m_propertyValues.selectedInstrument, (value) => {
			this.m_propertyValues.selectedInstrument = value;
		});
	}

	public get selectedSymbol(): any {
		return this.m_propertyValues.selectedSymbol;
	}

	public set selectedSymbol(value: any) {
		this.raiseAndSetIfChanged('selectedSymbol', value, this.m_propertyValues.selectedSymbol, (value) => {
			this.m_propertyValues.selectedSymbol = value;
		});
	}

	public get selectedPosition(): any {
		return this.m_propertyValues.selectedPosition;
	}

	public set selectedPosition(value: any) {
		this.raiseAndSetIfChanged('selectedPosition', value, this.m_propertyValues.selectedPosition, (value) => {
			this.m_propertyValues.selectedPosition = value;
		});
	}

	public get selectedNewsContent(): number {
		return this.m_propertyValues.selectedNewsContent;
	}

	public set selectedNewsContent(value: number) {
		this.raiseAndSetIfChanged('selectedNewsContent', value, this.m_propertyValues.selectedNewsContent, (value) => {
			this.m_propertyValues.selectedNewsContent = value;
		});
	}

	public get symbolChanged(): string {
		return this.m_propertyValues.symbolChanged;
	}

	public set symbolChanged(value: string) {
		this.raiseAndSetIfChanged('symbolChanged', value, this.m_propertyValues.symbolChanged, (value) => {
			this.m_propertyValues.symbolChanged = value;
		});
	}

	public get toggleOverlay(): number {
		return this.m_propertyValues.toggleOverlay;
	}

	public set toggleOverlay(value: number) {
		this.raiseAndSetIfChanged('toggleOverlay', value, this.m_propertyValues.toggleOverlay, (value) => {
			this.m_propertyValues.toggleOverlay = value;
		});
	}

	public get positionProfitLosses(): number {
		return this.m_propertyValues.positionProfitLosses;
	}

	public set positionProfitLosses(value: number) {
		this.raiseAndSetIfChanged('positionProfitLosses', value, this.m_propertyValues.positionProfitLosses, (value) => {
			this.m_propertyValues.positionProfitLosses = value;
		});
	}

	public get tradingAccount(): TradingAccount[] {
		return this.m_propertyValues.tradingAccount;
	}

	public set tradingAccount(value: TradingAccount[]) {
		this.raiseAndSetIfChanged('tradingAccount', value, this.m_propertyValues.tradingAccount, (value) => {
			this.m_propertyValues.tradingAccount = value;
		});
	}

	public getTradingPositions(): TradingPosition[] {
		return this.m_propertyValues.tradingPositionsArr;
	}

	public get tradingPositionsArr(): TradingPosition[] {
		return this.m_propertyValues.tradingPositionsArr;
	}

	public set tradingPositionsArr(_: TradingPosition[]) {
		throw new Error('tradingPositionsArr can not be set externaly');
	}

	public get tradingPositions(): Record<number, TradingPosition> {
		return this.m_propertyValues.tradingPositions;
	}

	public set tradingPositions(value: Record<number, TradingPosition>) {
		this.raiseAndSetIfChanged('tradingPositions', value, this.m_propertyValues.tradingPositions, (value) => {
			this.tradingPositionsLength = Object.values(value).length;
			this.m_propertyValues.tradingPositions = value;
			this.m_propertyValues.tradingPositionsArr = Object.values(this.m_propertyValues.tradingPositions);
		});
	}

	public get tradingPositionsLength(): number {
		return this.m_propertyValues.tradingPositionsLength;
	}

	public set tradingPositionsLength(value: number) {
		this.raiseAndSetIfChanged(
			'tradingPositionsLength',
			value,
			this.m_propertyValues.tradingPositionsLength,
			(value) => {
				this.m_propertyValues.tradingPositionsLength = value;
			}
		);
	}

	public get twoFactorAuthModalToggle(): boolean {
		return this.m_propertyValues.twoFactorAuthModalToggle;
	}

	public set twoFactorAuthModalToggle(value: boolean) {
		this.raiseAndSetIfChanged(
			'twoFactorAuthModalToggle',
			value,
			this.m_propertyValues.twoFactorAuthModalToggle,
			(value) => {
				this.m_propertyValues.twoFactorAuthModalToggle = value;
			}
		);
	}

	public get startTradingNow(): boolean {
		return this.m_propertyValues.startTradingNow;
	}

	public set startTradingNow(value: boolean) {
		this.raiseAndSetIfChanged('startTradingNow', value, this.m_propertyValues.startTradingNow, (value) => {
			this.m_propertyValues.startTradingNow = value;
		});
	}

	public get watchlist(): TWatchlists[] {
		return this.m_propertyValues.watchlist;
	}

	public set watchlist(value: TWatchlists[]) {
		this.raiseAndSetIfChanged('watchlist', value, this.m_propertyValues.watchlist, (value) => {
			this.m_propertyValues.watchlist = value;
		});
	}

	public get mappedWatchlist(): TMappedWatchlist | null {
		return this.m_propertyValues.mappedWatchlist;
	}

	public set mappedWatchlist(value: TMappedWatchlist | null) {
		this.raiseAndSetIfChanged('mappedWatchlist', value, this.m_propertyValues.mappedWatchlist, (value) => {
			this.m_propertyValues.mappedWatchlist = value;
		});
	}

	public get hsSubs(): any {
		return this.m_propertyValues.hsSubs;
	}

	public set hsSubs(value: any) {
		this.raiseAndSetIfChanged('hsSubs', value, this.m_propertyValues.hsSubs, (value) => {
			this.m_propertyValues.hsSubs = value;
		});
	}

	public get instruments(): any {
		return this.m_propertyValues.instruments;
	}

	public set instruments(value: any) {
		this.raiseAndSetIfChanged('instruments', value, this.m_propertyValues.instruments, (value) => {
			this.m_propertyValues.instruments = value;
		});
	}

	public get watchlistAccount(): any {
		return this.m_propertyValues.watchlistAccount;
	}

	public set watchlistAccount(value: any) {
		this.raiseAndSetIfChanged('watchlistAccount', value, this.m_propertyValues.watchlistAccount, (value) => {
			this.m_propertyValues.watchlistAccount = value;
		});
	}

	public get accountChange(): boolean {
		return this.m_propertyValues.accountChange;
	}

	public set accountChange(value: boolean) {
		this.raiseAndSetIfChanged('accountChange', value, this.m_propertyValues.accountChange, (value) => {
			this.m_propertyValues.accountChange = value;
		});
	}

	public get orderConfirm(): TOrderConfirm {
		return this.m_propertyValues.orderConfirm;
	}

	public set orderConfirm(value: TOrderConfirm) {
		this.raiseAndSetIfChanged('orderConfirm', value, this.m_propertyValues.orderConfirm, (value) => {
			this.m_propertyValues.orderConfirm = value;
		});
	}

	public get oneClickTrading(): boolean {
		return this.m_propertyValues.oneClickTrading;
	}

	public set oneClickTrading(value: boolean) {
		this.raiseAndSetIfChanged('oneClickTrading', value, this.m_propertyValues.oneClickTrading, (value) => {
			this.m_propertyValues.oneClickTrading = value;
		});
	}

	public get keyPress(): any {
		return this.m_propertyValues.keyPress;
	}

	public set keyPress(value: any) {
		this.raiseAndSetIfChanged('keyPress', value, this.m_propertyValues.keyPress, (value) => {
			this.m_propertyValues.keyPress = value;
		});
	}

	public get marketIndex(): any {
		return this.m_propertyValues.marketIndex;
	}

	public set marketIndex(value: any) {
		this.raiseAndSetIfChanged('marketIndex', value, this.m_propertyValues.marketIndex, (value) => {
			this.m_propertyValues.marketIndex = value;
		});
	}

	public get rfpGatewayProvider(): Optional<() => RfpGateway> {
		return this.m_propertyValues.rfpGatewayProvider;
	}

	public set rfpGatewayProvider(value: Optional<() => RfpGateway>) {
		this.raiseAndSetIfChanged('rfpGatewayProvider', value, this.m_propertyValues.rfpGatewayProvider, (value) => {
			this.m_propertyValues.rfpGatewayProvider = value;
		});
	}

	public get lastSymbol(): Optional<TSymbolPreference> {
		return this.m_propertyValues.lastSymbol;
	}

	public set lastSymbol(value: Optional<TSymbolPreference>) {
		this.raiseAndSetIfChanged('lastSymbol', value, this.m_propertyValues.lastSymbol, (value) => {
			this.m_propertyValues.lastSymbol = value;
		});
	}

	public get inactiveTime(): number {
		return this.m_propertyValues.inactiveTime;
	}

	public set inactiveTime(value: number) {
		this.raiseAndSetIfChanged('inactiveTime', value, this.m_propertyValues.inactiveTime, (value) => {
			this.m_propertyValues.inactiveTime = value;
		});
	}

	public get inactiveModal(): boolean {
		return this.m_propertyValues.inactiveModal;
	}

	public set inactiveModal(value: boolean) {
		this.raiseAndSetIfChanged('inactiveModal', value, this.m_propertyValues.inactiveModal, (value) => {
			this.m_propertyValues.inactiveModal = value;
		});
	}

	public get openOrder(): any {
		return this.m_propertyValues.openOrder;
	}

	public set openOrder(value: any) {
		this.raiseAndSetIfChanged('openOrder', value, this.m_propertyValues.openOrder, (value) => {
			this.m_propertyValues.openOrder = value;
		});
	}

	public get openOrderInformation(): boolean {
		return this.m_propertyValues.openOrderInformation;
	}

	public set openOrderInformation(value: boolean) {
		this.raiseAndSetIfChanged('openOrderInformation', value, this.m_propertyValues.openOrderInformation, (value) => {
			this.m_propertyValues.openOrderInformation = value;
		});
	}

	public get toggleAccordionMenu(): string {
		return this.m_propertyValues.toggleAccordionMenu;
	}

	public set toggleAccordionMenu(value: string) {
		this.raiseAndSetIfChanged('toggleAccordionMenu', value, this.m_propertyValues.toggleAccordionMenu, (value) => {
			this.m_propertyValues.toggleAccordionMenu = value;
		});
	}

	public get inputValidation(): TInputValidation {
		return this.m_propertyValues.inputValidation;
	}

	public set inputValidation(value: TInputValidation) {
		this.raiseAndSetIfChanged('inputValidation', value, this.m_propertyValues.inputValidation, (value) => {
			this.m_propertyValues.inputValidation = value;
		});
	}

	public get orderValue(): TOrderValue {
		return this.m_propertyValues.orderValue;
	}

	public set orderValue(value: TOrderValue) {
		this.raiseAndSetIfChanged('orderValue', value, this.m_propertyValues.orderValue, (value) => {
			this.m_propertyValues.orderValue = value;
		});
	}

	public get trade(): any {
		return this.m_propertyValues.trade;
	}

	public set trade(value: any) {
		this.raiseAndSetIfChanged('trade', value, this.m_propertyValues.trade, (value) => {
			this.m_propertyValues.trade = value;
		});
	}

	public get currentPrice(): any {
		return this.m_propertyValues.currentPrice;
	}

	public set currentPrice(value: any) {
		this.raiseAndSetIfChanged('currentPrice', value, this.m_propertyValues.currentPrice, (value) => {
			this.m_propertyValues.currentPrice = value;
		});
	}

	public get isEdit(): boolean {
		return this.m_propertyValues.isEdit;
	}

	public set isEdit(value: boolean) {
		this.raiseAndSetIfChanged('isEdit', value, this.m_propertyValues.isEdit, (value) => {
			this.m_propertyValues.isEdit = value;
		});
	}

	public get userGuide(): boolean {
		return this.m_propertyValues.userGuide;
	}

	public set userGuide(value: boolean) {
		this.raiseAndSetIfChanged('userGuide', value, this.m_propertyValues.userGuide, (value) => {
			this.m_propertyValues.userGuide = value;
		});
	}

	public get dragPosition(): any {
		return this.m_propertyValues.dragPosition;
	}

	public set dragPosition(value: any) {
		this.raiseAndSetIfChanged('dragPosition', value, this.m_propertyValues.dragPosition, (value) => {
			this.m_propertyValues.dragPosition = value;
		});
	}

	public get newAccount(): boolean {
		return this.m_propertyValues.newAccount;
	}

	public set newAccount(value: boolean) {
		this.raiseAndSetIfChanged('newAccount', value, this.m_propertyValues.newAccount, (value) => {
			this.m_propertyValues.newAccount = value;
		});
	}

	public get showProfitLoss(): TShowProfitLoss {
		return this.m_propertyValues.showProfitLoss;
	}

	public set showProfitLoss(value: TShowProfitLoss) {
		this.raiseAndSetIfChanged('showProfitLoss', value, this.m_propertyValues.showProfitLoss, (value) => {
			this.m_propertyValues.showProfitLoss = value;
		});
	}

	public get activeIndex(): number {
		return this.m_propertyValues.activeIndex;
	}

	public set activeIndex(value: number) {
		this.raiseAndSetIfChanged('activeIndex', value, this.m_propertyValues.activeIndex, (value) => {
			this.m_propertyValues.activeIndex = value;
		});
	}

	public get gridClicked(): boolean | number {
		return this.m_propertyValues.gridClicked;
	}

	public set gridClicked(value: boolean | number) {
		this.raiseAndSetIfChanged('gridClicked', value, this.m_propertyValues.gridClicked, (value) => {
			this.m_propertyValues.gridClicked = value;
		});
	}

	public get selectedSymbolUpdated(): boolean {
		return this.m_propertyValues.selectedSymbolUpdated;
	}

	public set selectedSymbolUpdated(value: boolean) {
		this.raiseAndSetIfChanged('selectedSymbolUpdated', value, this.m_propertyValues.selectedSymbolUpdated, (value) => {
			this.m_propertyValues.selectedSymbolUpdated = value;
		});
	}

	public get getOrderInfo(): any {
		return this.m_propertyValues.getOrderInfo;
	}

	public set getOrderInfo(value: any) {
		this.raiseAndSetIfChanged('getOrderInfo', value, this.m_propertyValues.getOrderInfo, (value) => {
			this.m_propertyValues.getOrderInfo = value;
		});
	}

	public get instrumentLastTrade(): any {
		return this.m_propertyValues.instrumentLastTrade;
	}

	public set instrumentLastTrade(value: any) {
		this.raiseAndSetIfChanged('instrumentLastTrade', value, this.m_propertyValues.instrumentLastTrade, (value) => {
			this.m_propertyValues.instrumentLastTrade = value;
		});
	}

	public get settingsOptions(): any {
		return this.m_propertyValues.settingsOptions;
	}

	public set settingsOptions(value: any) {
		this.raiseAndSetIfChanged('settingsOptions', value, this.m_propertyValues.settingsOptions, (value) => {
			this.m_propertyValues.settingsOptions = value;
		});
	}

	public get gridChartsChanged(): boolean {
		return this.m_propertyValues.gridChartsChanged;
	}

	public set gridChartsChanged(value: boolean) {
		this.raiseAndSetIfChanged('gridChartsChanged', value, this.m_propertyValues.gridChartsChanged, (value) => {
			this.m_propertyValues.gridChartsChanged = value;
		});
	}

	public get showOrderTicket(): boolean {
		return this.m_propertyValues.showOrderTicket;
	}

	public set showOrderTicket(value: boolean) {
		this.raiseAndSetIfChanged('showOrderTicket', value, this.m_propertyValues.showOrderTicket, (value) => {
			this.m_propertyValues.showOrderTicket = value;
		});
	}

	public get showOrderInformation(): boolean {
		return this.m_propertyValues.showOrderInformation;
	}

	public set showOrderInformation(value: boolean) {
		this.raiseAndSetIfChanged('showOrderInformation', value, this.m_propertyValues.showOrderInformation, (value) => {
			this.m_propertyValues.showOrderInformation = value;
		});
	}

	public get showCloseTicket(): boolean {
		return this.m_propertyValues.showCloseTicket;
	}

	public set showCloseTicket(value: boolean) {
		this.raiseAndSetIfChanged('showCloseTicket', value, this.m_propertyValues.showCloseTicket, (value) => {
			this.m_propertyValues.showCloseTicket = value;
		});
	}

	public get showClosedPositionTicket(): boolean {
		return this.m_propertyValues.showClosedPositionTicket;
	}

	public set showClosedPositionTicket(value: boolean) {
		this.raiseAndSetIfChanged(
			'showClosedPositionTicket',
			value,
			this.m_propertyValues.showClosedPositionTicket,
			(value) => {
				this.m_propertyValues.showClosedPositionTicket = value;
			}
		);
	}

	public get showConfirmTicket(): boolean {
		return this.m_propertyValues.showConfirmTicket;
	}

	public set showConfirmTicket(value: boolean) {
		this.raiseAndSetIfChanged('showConfirmTicket', value, this.m_propertyValues.showConfirmTicket, (value) => {
			this.m_propertyValues.showConfirmTicket = value;
		});
	}

	public get tradeInformation(): Optional<TradeRequest> {
		return this.m_propertyValues.tradeInformation;
	}

	public set tradeInformation(value: Optional<TradeRequest>) {
		this.raiseAndSetIfChanged('tradeInformation', value, this.m_propertyValues.tradeInformation, (value) => {
			this.m_propertyValues.tradeInformation = value;
		});
	}

	public get showCancelTicket(): boolean {
		return this.m_propertyValues.showCancelTicket;
	}

	public set showCancelTicket(value: boolean) {
		this.raiseAndSetIfChanged('showCancelTicket', value, this.m_propertyValues.showCancelTicket, (value) => {
			this.m_propertyValues.showCancelTicket = value;
		});
	}

	public get gridButton(): any {
		return this.m_propertyValues.gridButton;
	}

	public set gridButton(value: any) {
		this.raiseAndSetIfChanged('gridButton', value, this.m_propertyValues.gridButton, (value) => {
			this.m_propertyValues.gridButton = value;
		});
	}

	public get modifyTicket(): boolean {
		return this.m_propertyValues.modifyTicket;
	}

	public set modifyTicket(value: boolean) {
		this.raiseAndSetIfChanged('modifyTicket', value, this.m_propertyValues.modifyTicket, (value) => {
			this.m_propertyValues.modifyTicket = value;
		});
	}

	public get gridChartInitialLoad(): boolean {
		return this.m_propertyValues.gridChartInitialLoad;
	}

	public set gridChartInitialLoad(value: boolean) {
		this.raiseAndSetIfChanged('gridChartInitialLoad', value, this.m_propertyValues.gridChartInitialLoad, (value) => {
			this.m_propertyValues.gridChartInitialLoad = value;
		});
	}

	public get crossHairState(): any {
		return this.m_propertyValues.crossHairState;
	}

	public set crossHairState(value: any) {
		this.raiseAndSetIfChanged('crossHairState', value, this.m_propertyValues.crossHairState, (value) => {
			this.m_propertyValues.crossHairState = value;
		});
	}

	public get showNewsFeed(): boolean {
		return this.m_propertyValues.showNewsFeed;
	}

	public set showNewsFeed(value: boolean) {
		this.raiseAndSetIfChanged('showNewsFeed', value, this.m_propertyValues.showNewsFeed, (value) => {
			this.m_propertyValues.showNewsFeed = value;
		});
	}

	public get selectedType(): string {
		return this.m_propertyValues.selectedType;
	}

	public set selectedType(value: string) {
		this.raiseAndSetIfChanged('selectedType', value, this.m_propertyValues.selectedType, (value) => {
			this.m_propertyValues.selectedType = value;
		});
	}

	public get toggleSort(): boolean {
		return this.m_propertyValues.toggleSort;
	}

	public set toggleSort(value: boolean) {
		this.raiseAndSetIfChanged('toggleSort', value, this.m_propertyValues.toggleSort, (value) => {
			this.m_propertyValues.toggleSort = value;
		});
	}

	public get watchListStoredAsArray(): any {
		return this.m_propertyValues.watchListStoredAsArray;
	}

	public set watchListStoredAsArray(value: any) {
		this.raiseAndSetIfChanged(
			'watchListStoredAsArray',
			value,
			this.m_propertyValues.watchListStoredAsArray,
			(value) => {
				this.m_propertyValues.watchListStoredAsArray = value;
			}
		);
	}

	public get closeTrade(): any {
		return this.m_propertyValues.closeTrade;
	}

	public set closeTrade(value: any) {
		this.raiseAndSetIfChanged('closeTrade', value, this.m_propertyValues.closeTrade, (value) => {
			this.m_propertyValues.closeTrade = value;
		});
	}

	public get navigation(): any {
		return this.m_propertyValues.navigation;
	}

	public set navigation(value: any) {
		this.raiseAndSetIfChanged('navigation', value, this.m_propertyValues.navigation, (value) => {
			this.m_propertyValues.navigation = value;
		});
	}

	public get tabnumber(): number {
		return this.m_propertyValues.tabnumber;
	}

	public set tabnumber(value: number) {
		this.raiseAndSetIfChanged('tabnumber', value, this.m_propertyValues.tabnumber, (value) => {
			this.m_propertyValues.tabnumber = value;
		});
	}

	public get maximizeGrid(): boolean {
		return this.m_propertyValues.maximizeGrid;
	}

	public set maximizeGrid(value: boolean) {
		this.raiseAndSetIfChanged('maximizeGrid', value, this.m_propertyValues.maximizeGrid, (value) => {
			this.m_propertyValues.maximizeGrid = value;
		});
	}

	public get applicationStatus(): string | null | undefined {
		return this.m_propertyValues.applicationStatus;
	}

	public set applicationStatus(value: string | null | undefined) {
		this.raiseAndSetIfChanged('applicationStatus', value, this.m_propertyValues.applicationStatus, (value) => {
			this.m_propertyValues.applicationStatus = value;
		});
	}

	public get chartTimezone(): any {
		return this.m_propertyValues.chartTimezone;
	}

	public set chartTimezone(value: any) {
		this.raiseAndSetIfChanged('chartTimezone', value, this.m_propertyValues.chartTimezone, (value) => {
			this.m_propertyValues.chartTimezone = value;
		});
	}

	public get initialTimezone(): Optional<string> {
		return this.m_propertyValues.initialTimezone;
	}

	public set initialTimezone(value: Optional<string>) {
		this.raiseAndSetIfChanged('initialTimezone', value, this.m_propertyValues.initialTimezone, (value) => {
			this.m_propertyValues.initialTimezone = value;
		});
	}

	public get headerSelected(): boolean {
		return this.m_propertyValues.headerSelected;
	}

	public set headerSelected(value: boolean) {
		this.raiseAndSetIfChanged('headerSelected', value, this.m_propertyValues.headerSelected, (value) => {
			this.m_propertyValues.headerSelected = value;
		});
	}

	public get accountIndex(): any {
		return this.m_propertyValues.accountIndex;
	}

	public set accountIndex(value: any) {
		this.raiseAndSetIfChanged('accountIndex', value, this.m_propertyValues.accountIndex, (value) => {
			this.m_propertyValues.accountIndex = value;
		});
	}

	public get closeLength(): number {
		return this.m_propertyValues.closeLength;
	}

	public set closeLength(value: number) {
		this.raiseAndSetIfChanged('closeLength', value, this.m_propertyValues.closeLength, (value) => {
			this.m_propertyValues.closeLength = value;
		});
	}

	public get ticketLoad(): boolean {
		return this.m_propertyValues.ticketLoad;
	}

	public set ticketLoad(value: boolean) {
		this.raiseAndSetIfChanged('ticketLoad', value, this.m_propertyValues.ticketLoad, (value) => {
			this.m_propertyValues.ticketLoad = value;
		});
	}

	public get typeOfOrder(): orderTypes {
		return this.m_propertyValues.typeOfOrder;
	}

	public set typeOfOrder(value: orderTypes) {
		this.raiseAndSetIfChanged('typeOfOrder', value, this.m_propertyValues.typeOfOrder, (value) => {
			this.m_propertyValues.typeOfOrder = value;
		});
	}

	public get modalToggle(): { closePosition: boolean; closeOrder: boolean } {
		return this.m_propertyValues.modalToggle;
	}

	public set modalToggle(value: { closePosition: boolean; closeOrder: boolean }) {
		this.raiseAndSetIfChanged('modalToggle', value, this.m_propertyValues.modalToggle, (value) => {
			this.m_propertyValues.modalToggle = value;
		});
	}
	public get accountValues(): {
		balance: number;
		margin: number;
		freeMargin: number;
		marginLevel: number;
		equity: number;
		organizationName: string;
		pl: number;
	} {
		return this.m_propertyValues.accountValues;
	}

	public set accountValues(value: {
		balance: number;
		margin: number;
		freeMargin: number;
		marginLevel: number;
		equity: number;
		organizationName: string;
		pl: number;
	}) {
		this.raiseAndSetIfChanged('accountValues', value, this.m_propertyValues.accountValues, (value) => {
			this.m_propertyValues.accountValues = value;
		});
	}

	public get newOrderModalToggle(): {
		orderTicket: boolean;
		confirmOrder: boolean;
		amount?: number;
		limit?: number;
		stopLoss?: number;
		takeProfit?: number;
		dateText?: string;
		dateValue?: number;
		instrument?: string;
		type?: orderTypes;
	} {
		return this.m_propertyValues.newOrderModalToggle;
	}

	public set newOrderModalToggle(value: {
		orderTicket: boolean;
		confirmOrder: boolean;
		amount?: number;
		limit?: number;
		stopLoss?: number;
		takeProfit?: number;
		dateText?: string;
		dateValue?: number;
		instrument?: string;
		type?: orderTypes;
	}) {
		this.raiseAndSetIfChanged('newOrderModalToggle', value, this.m_propertyValues.newOrderModalToggle, (value) => {
			this.m_propertyValues.newOrderModalToggle = value;
		});
	}

	public get notification(): TNotification {
		return this.m_propertyValues.notification;
	}

	public set notification(value: TNotification) {
		this.raiseAndSetIfChanged('notification', value, this.m_propertyValues.notification, (value) => {
			this.m_propertyValues.notification = value;
		});
	}

	public get reconnection(): { isReconnecting: boolean; count: number } {
		return this.m_propertyValues.reconnection;
	}

	public set reconnection(value: { isReconnecting: boolean; count: number }) {
		this.raiseAndSetIfChanged('reconnection', value, this.m_propertyValues.reconnection, (value) => {
			this.m_propertyValues.reconnection = value;
		});
	}

	public get loadingState(): { marketItems: boolean; tradingAccounts: boolean; tradingPositions: boolean } {
		return this.m_propertyValues.loadingState;
	}

	public set loadingState(value: { marketItems: boolean; tradingAccounts: boolean; tradingPositions: boolean }) {
		this.raiseAndSetIfChanged('loadingState', value, this.m_propertyValues.loadingState, (value) => {
			this.m_propertyValues.loadingState = value;
		});
	}

	public get emptyChartModal(): boolean {
		return this.m_propertyValues.emptyChartModal;
	}

	public set emptyChartModal(value: boolean) {
		this.raiseAndSetIfChanged('emptyChartModal', value, this.m_propertyValues.emptyChartModal, (value) => {
			this.m_propertyValues.emptyChartModal = value;
		});
	}

	public get accountPopupToggle(): boolean {
		return this.m_propertyValues.accountPopupToggle;
	}

	public set accountPopupToggle(value: boolean) {
		this.raiseAndSetIfChanged('accountPopupToggle', value, this.m_propertyValues.accountPopupToggle, (value) => {
			this.m_propertyValues.accountPopupToggle = value;
		});
	}

	public get tfboConnectionError(): boolean {
		return this.m_propertyValues.tfboConnectionError;
	}

	public set tfboConnectionError(value: boolean) {
		this.raiseAndSetIfChanged('tfboConnectionError', value, this.m_propertyValues.tfboConnectionError, (value) => {
			this.m_propertyValues.tfboConnectionError = value;
		});
	}

	public get ssoTradingAccount(): string | null {
		return this.m_propertyValues.ssoTradingAccount;
	}

	public set ssoTradingAccount(value: string | null) {
		this.raiseAndSetIfChanged('ssoTradingAccount', value, this.m_propertyValues.ssoTradingAccount, (value) => {
			this.m_propertyValues.ssoTradingAccount = value;
		});
	}

	public get previousDayClosePrices(): PreviousDayClosePrices {
		return this.m_propertyValues.previousDayClosePrices;
	}

	public addPreviousDayClosePrices(values: PreviousDayClosePrices) {
		this.raiseAndSetIfChanged(
			'previousDayClosePrices',
			values,
			this.m_propertyValues.previousDayClosePrices,
			(values) => {
				this.m_propertyValues.previousDayClosePrices = {
					...this.m_propertyValues.previousDayClosePrices,
					...values,
				};
			}
		);
	}

	public closeAllOtherTabs() {
		this.modifyTicket = false;
		this.showOrderInformation = false;
		this.showNewsFeed = false;
		this.showOrderTicket = false;
		this.showConfirmTicket = false;
		this.startTradingNow = false;
		this.showCloseTicket = false;
		this.showCancelTicket = false;
	}

	private constructor() {
		super();
		this.m_propertyValues = clone(defaultValues.getValue());
	}

	public reset(): void {
		this.m_propertyValues = clone(defaultValues.getValue());
	}
}

export default createContext<DashboardContextProvider>(
	Resolver.isSet && Resolver.isRegistered(DashboardContextProvider)
		? Resolver.resolve(DashboardContextProvider)
		: DashboardContextProvider.instance
);
