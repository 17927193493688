import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import Routes from '../../../../../../setup/routes';

import { AppComponentType, TradingAccountType } from '../../../../../../utils/functions/enums';
import AppContext, { TTradingMode } from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { AccountMarketType, TradingAccount } from '../../../../../../gateways/RfpGateway/rfp.types';

import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';

import accountStatusStore from '../../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../../components/Permissions/config.types';

import { GymTradingAccount } from '../../../../../../pages/TradersGym/Accounts/GymTradingAccount';

import authStore, { TradingMode } from '../../../../../../store/authStore';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';

import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';

import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';

import { TradingAccountType as PlatformAccountType } from '../../../../../../gateways/TfboGateway/TfboGateway.types';

import { LastLoggedAct } from '../../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import { RFP } from '../../../../../../gateways/RfpGateway/rfpConstants';
import { Resolver } from '../../../../../../utils/functions/Ioc';
import RfpGateway from '../../../../../../gateways/RfpGateway/RfpGateway';

import { checkHasSubscriptionOptions } from '../../../../../../utils/functions/subscriptionUtils';
import useSubscriptionInfo from '../../../../../../utils/hooks/useSubscriptionInfo';

import AccountIconAndTier from '../../components/AccountIconAndTier';

import SubscriptionOptions from './SubscriptionOptions';

import styles from './TradingAccounts.module.scss';
import {printAccountName} from "../../../../../../utils/functions/getAccountStats";

interface ITradingAccountsProps {
	activeMenuItem: 'live' | 'demo' | TTradingMode;
	setAccountPopupIsOpen: (isOpen: boolean) => void;
	selectedTradingAccount: TradingAccount | GymTradingAccount | undefined;
}

const TradingAccounts = ({ setAccountPopupIsOpen, activeMenuItem, selectedTradingAccount }: ITradingAccountsProps) => {
	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');
	const history = useHistory();
	const rfpGateway = Resolver.resolve(RfpGateway);
	const [subscriptionInfo] = useSubscriptionInfo();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const permissions = accountStatusStore.use.permissions();
	const setShowModal = accountStatusStore.use.setShowModal();
	const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
	const selectedTradingMode = authStore.use.tradingMode();
	const setTradingMode = authStore.use.setTradingMode();
	const addAccount = permissions.addAccount;
	const selected = tradingAccountStore.use.selected();
	const setSelected = tradingAccountStore.use.setSelected();
	const setAccountMarketType = tradingAccountStore.use.setAccountMarketType();
	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const accountStats = tradingAccountStore.use.accountStats();

	const { mutateAsync: savePreferences } = useSaveUserPreferences();

	const accountsList = useMemo(
		() => accountStats.filter((account) => account.account.type.toLowerCase() === activeMenuItem?.toLowerCase()),
		[activeMenuItem, accountStats]
	);

	const switchAccounts = async (account?: TradingAccount) => {
		dashboardContext.closeAllOtherTabs();
		if (activeMenuItem !== selectedTradingMode.toLowerCase()) {
			setTradingMode(activeMenuItem.toUpperCase() as TradingMode);
		}

		const selectedIndex = dashboardContext.tradingAccount.findIndex((acc) => {
			return acc.accountNumber === account?.accountNumber;
		});

		if (appContext.userPreferences) {
			if (account?.accountNumber) {
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[
					activeMenuItem.toLowerCase() as keyof LastLoggedAct
				] = account.accountNumber.toString();
			}

			fillContextsFromPreferences(appContext.userPreferences, isFundedTrader);
			savePreferences();
		}

		const selectedAccount = dashboardContext.tradingAccount[selected];
		const newAccount = dashboardContext.tradingAccount[selectedIndex];

		if (selectedAccount?.accountMarketType !== newAccount?.accountMarketType) {
			if (dashboardContext.mappedWatchlist !== null) {
				setCurrentWatchList(dashboardContext.mappedWatchlist[0]);
			}
		}

		if (selectedIndex !== -1) {
			setAccountMarketType({
				isJapanSpread: newAccount?.accountMarketType === AccountMarketType.JapanSpread,
				isJapanSubscription: newAccount?.accountMarketType === AccountMarketType.Japan,
				isSpreadBetting: newAccount?.accountMarketType === AccountMarketType.SpreadBetting
			});
			setSelected(selectedIndex);

			if (selectedTradingAccount) rfpGateway.send(RFP.tradingAccountLogout, { acct_id: selectedTradingAccount.id });
		}
		setAccountPopupIsOpen(false);
	};

	const handleAddAccountClick = () => {
		if (hasNoDemoAccount) {
			switchAccounts();
			return;
		}

		if (addAccount === IStatusConfigEnum.addLiveAccount || addAccount === IStatusConfigEnum.addDemoAccount) {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		} else {
			setShowModal(true);
			appContext.statusModal = true;
		}

		setAccountPopupIsOpen(false);
	};

	const isLiveMenu = activeMenuItem.toLowerCase() === 'live';
	const showAddAccount =
		(!appContext.isJapanAccount &&
			permissions?.addAccount &&
			selectedTradingMode.toLowerCase() === activeMenuItem.toLowerCase()) ||
		(isLiveMenu && !hasLiveAccount);
	const showAddJapanAccount = appContext.isJapanAccount && isLiveMenu && accountsList.length < 2;
	const hasNoDemoAccount = activeMenuItem === 'demo' && accountsList.length === 0;

	return (
		<div className={styles.accountsList}>
			{accountsList.map(({ account, platformAccountType }: any) => {
				const isLiveAccount = account.type === TradingAccountType.LIVE;
				const isSpreadBettingAccount = platformAccountType === PlatformAccountType.SpreadBetting;
				const isJapanSpreadAccount = platformAccountType === PlatformAccountType.JapanSpread;
				const isJapanSubscriptionAccount = appContext.isJapanAccount && isLiveAccount && !isJapanSpreadAccount;
				const hasSubscriptionOptions =
					isJapanSubscriptionAccount &&
					checkHasSubscriptionOptions(subscriptionInfo.status);
				const isCurrentAccount = account.accountNumber.toString() === selectedTradingAccount?.providerAccountId;

				return (
					<div
						key={account.accountNumber}
						className={cn(styles.accountsListItem, styles.noPadding, isCurrentAccount && styles.active)}
					>
						<div className={cn(styles.accountInfo)} onClick={() => switchAccounts(account)}>
							<span className={cn(styles.icon, isCurrentAccount && styles[account.type.toLowerCase()])}>
								<AccountIconAndTier isForJapanSubscriptionAccount={isJapanSubscriptionAccount} customCurrency={account.currency.code} />
							</span>

							<div className={styles.statsContainer}>
								<div
									className={cn(
										styles.stats,
										isCurrentAccount && styles.selected
									)}
								>
									<span className={styles[account.type.toLowerCase()]}>
										{t(`en:LOGIN_VIEW_${account.type.toUpperCase()}`)}
									</span>
									&nbsp;
									<span>{account.accountNumber}</span>
								</div>
								<span className={styles.baseCurrency}>
									{printAccountName({
										t,
										isLiveAccount,
										isSpreadBettingAccount,
										isJapanSpreadAccount,
										isJapanSubscriptionAccount,
										defaultName: `${account.currency.code} • ${t('wtr:CFD')}`,
									})}
								</span>
							</div>
						</div>
						{isCurrentAccount && hasSubscriptionOptions && (
							<SubscriptionOptions setAccountPopupIsOpen={setAccountPopupIsOpen} />
						)}
					</div>
				);
			})}

			{(showAddAccount || hasNoDemoAccount || showAddJapanAccount) && (
				<div className={styles.addAccountContainer} onClick={handleAddAccountClick}>
					<FontAwesomeIcon
						icon={['fal', 'plus']}
						className={cn(styles.plusIcon, styles[activeMenuItem.toLowerCase()])}
					/>
					{/*TODO: update translations for accountName for Japanese account*/}
					<span
						className={styles.accountType}
						dangerouslySetInnerHTML={{
							__html: tt(`ADD_${activeMenuItem.toUpperCase()}_ACCOUNT`, {
								accountName: showAddJapanAccount ? (isJapanSubscriptionAccount ? 'FX Spread ' : 'FX Subscription ') : '',
								classNameAccountType: cn(styles.accountType, styles[activeMenuItem.toLowerCase()]),
							}),
						}}
					></span>
				</div>
			)}
		</div>
	);
};

export default React.memo(TradingAccounts);
