import clone from 'clone';

import { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';
import {
	LastLoggedAct,
	TPreferenceResponse,
	TWatchlists,
} from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import authStore, { AuthStore } from '../../../store/authStore';
import convertChartTimeZone from '../../../utils/functions/chartIqTimeZone';

import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
} from '../../functions/WatchlistUtils';

import useSaveUserPreferences from '../../mutations/useSaveUserPreferences';
// import tradingAccountStore from '../../../store/tradingAccountStore';
import { AccountMarketType } from '../../../gateways/RfpGateway/rfp.types';
import { useDynamicWatchLists } from '../useDynamicWatchLists';

/**
 * Fills the contexts used by the model layer from the result recieved by the preferences API
 * @param {TPreferenceResponse} userPreference User preference API call result or default preference object
 * @param {AppContextProvider} appContext The app context state to be filled
 * @param {DashboardContextProvider} dashboardContext The dashboard context state to be filled
 * @since preference version 1.0
 */

export const useFillContextsFromPreferences = () => {
	const tradingMode = authStore((store: AuthStore) => store.tradingMode);
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const dynamicWatchLists = useDynamicWatchLists();
	// const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	// const currentWatchList = tradingAccountStore.use.currentWatchList();

	const { mutate: savePreferences } = useSaveUserPreferences();

	return (userPrefs: TPreferenceResponse, isFundedTrader?: any) => {
		if (!userPrefs) return;
		const userPreferences = clone(userPrefs);
		const { user_prefs: preferences } = userPreferences;
		const accountType = dashboardContext.accountType;

		if (!preferences.platform.lastLoggedAct || typeof preferences.platform.lastLoggedAct !== 'object') {
			appContext.userPreferences!.user_prefs.platform.lastLoggedAct = { demo: '', live: '' };
			savePreferences();
		}

		const isLastLoggedAccountSpreadBetting =
			preferences.platform?.accountTypes &&
			preferences.platform?.accountTypes[
				preferences.platform.lastLoggedAct[tradingMode.toLowerCase() as keyof LastLoggedAct]
			] === AccountMarketType.SpreadBetting;

		const isLastLoggedAccountJapanSpread =
			preferences.platform?.accountTypes &&
			preferences.platform?.accountTypes[
				preferences.platform.lastLoggedAct[tradingMode.toLowerCase() as keyof LastLoggedAct]
			] === AccountMarketType.JapanSpread;


		//Theme Preference
		if (preferences.platform?.theme !== null) {
			appContext.appTheme = preferences.platform.theme._type === 'Light' ? 'light' : 'dark';
			localStorage.setItem('wt-theme', appContext.appTheme);
		}
		//One-Click Trading Preference
		if (preferences.trading?.settings['cfd'] !== null) {
			dashboardContext.oneClickTrading = preferences.trading.settings['cfd']?._mode === '1-Click';
		}
		//Quantity Type Preference
		if (preferences.trading?.settings['cfd']?._quantityType !== null) {
			if (isLastLoggedAccountSpreadBetting) {
				dashboardContext.quantityType = 'Lots';
			} else {
				dashboardContext.quantityType = appContext.isJapanAccount
					? 'Amount'
					: preferences.trading?.settings['cfd']?._quantityType!;
			}
		}

		//Notification sound muted Preference
		if (preferences.trading?.settings['cfd']?._notificationSoundMuted !== undefined) {
			dashboardContext.notificationSoundMuted = preferences.trading?.settings['cfd']?._notificationSoundMuted;
		}
		//Notification sound volume Preference
		if (preferences.trading?.settings['cfd']?._notificationSoundVolume) {
			dashboardContext.notificationSoundVolume = preferences.trading?.settings['cfd']?._notificationSoundVolume;
		}
		//Trade From Chart Preference
		if (preferences.trading?.settings['cfd']?._tradeFromChartEnabled !== null) {
			dashboardContext.tradeFromChartEnabled = preferences.trading?.settings['cfd']?._tradeFromChartEnabled!;
		} else {
			// Persist the new setting for existing users
			if (appContext.userPreferences!.user_prefs.trading.settings[accountType]) {
				appContext.userPreferences!.user_prefs.trading.settings[accountType]!._tradeFromChartEnabled = true;
				savePreferences();
			}
		}
		//Inactive Time Preference
		if (preferences.platform?.inactivity !== null) {
			dashboardContext.inactiveTime = preferences.platform.inactivity;
		}

		if (
			!preferences.trading.settings[accountType]!.amount
		) {
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!.amount = {};
			savePreferences();
		}

		//Watchlist Preference
		if (preferences.watchlists.account.length > 0) {
			let cfdWatchlists: TWatchlists[] = [];

			const filteredByModeWatchlists = preferences.watchlists.account.filter((accountType) => {
				if (isLastLoggedAccountSpreadBetting) {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'SpreadBetting';
				} if (isLastLoggedAccountJapanSpread) {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'JapanSpread';
				} else {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === undefined;
				}
			});

			filteredByModeWatchlists.forEach((account) => {
				account.watchlist.forEach((watchlist, index) => {
					if (isFundedTrader) {
						if (watchlist._feedId === DEFAULT_FEED_ID && watchlist._sortOrder === 'None') {
							cfdWatchlists.push(watchlist);
						}
					} else {
						if (watchlist._feedId === DEFAULT_FEED_ID) {
							cfdWatchlists.push(watchlist);
						}
					}
				});
			});

			// dashboardContext.watchlist = cfdWatchlists;
			//
			// dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);

			// if (appContext.isJapanAccount) {
			// 	setCurrentWatchList(
			// 		currentWatchList || filteredByModeWatchlists[0]?._lastUsedWatchlist || dashboardContext.watchlist[0]?._name
			// 	);
			// } else {
			// 	setCurrentWatchList(currentWatchList || dashboardContext.watchlist[0]?._name);
			// }

			dynamicWatchLists(cfdWatchlists, isFundedTrader);
		}

		// 	if(watchlist.equity != null) {
		// 		dashboardContext.equityWatchlist = watchlist.equity
		// 	}
		// }
		// if(lastTrade != null) {
		// 	dashboardContext.instrumentLastTrade = lastTrade
		// }

		//Timezone Preference - default Local if no time zone specified
		const timezone = convertChartTimeZone(preferences.platform?.timezone ? preferences.platform.timezone._name : '');
		dashboardContext.initialTimezone = timezone;
		dashboardContext.chartTimezone = timezone;

		if (localStorage) {
			localStorage.setItem('charts-timezone', timezone);
		}

		// Language Preference
		if (
			preferences.platform?.lang._code !== null &&
			appContext.languageSettings !== preferences?.platform?.lang._code
		) {
			appContext.languageSettings = preferences.platform.lang._code;
		}
	};
};
