import { useContext } from 'react';
import DashboardContext from '../../contexts/DashboardContext';
import tradingAccountStore from '../../store/tradingAccountStore';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	dynamicWatchlistPreference,
	getFirstMatchingWatchListName
} from '../functions/WatchlistUtils';
import { TWatchlists } from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import AppContext from '../../contexts/AppContext';
import { TradingAccountType } from '../functions/enums';

export const useDynamicWatchLists = () => {
	const dashboardContext = useContext(DashboardContext);
	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const currentWatchList = tradingAccountStore.use.currentWatchList();
	const isSpreadBettingAcc = tradingAccountStore.use.isSpreadBetting();
	const appContext = useContext(AppContext);

	return (cfdWatchlists: TWatchlists[], isFundedTrader: boolean) => {
		let dynamicWatchlists: any = [];
		if (dashboardContext.defaultWatchLists.length > 0 && !isFundedTrader) {
			for (let watchList of dashboardContext.defaultWatchLists) {
				const currentDynamicWatchlists = watchList?.items.map((item) => {
					return dynamicWatchlistPreference(DEFAULT_FEED_ID, item.title, item.codes_csv, item.order);
				});
				dynamicWatchlists = [...dynamicWatchlists, ...currentDynamicWatchlists];
			}
		}

		if (dynamicWatchlists.length > 0 && !isSpreadBettingAcc && !isFundedTrader) {
			dashboardContext.watchlist = [...dynamicWatchlists, ...cfdWatchlists];
		} else {
			dashboardContext.watchlist = [...cfdWatchlists];
		}

		if (isFundedTrader && dynamicWatchlists.length) {
			dashboardContext.watchlist = dashboardContext.watchlist.slice(dynamicWatchlists.length);
			dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
		} else {
			dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
		}

		if (dynamicWatchlists.length > 0 && !appContext.isJapanAccount) {
			setCurrentWatchList(dashboardContext.watchlist[0]?._name);
		} else if (appContext.isJapanAccount) {
			const liveCFDWatchlist = appContext.userPreferences?.user_prefs?.watchlists?.account.find((account) => {
				return account && account._type.toUpperCase() === TradingAccountType.LIVE && !account.accountType;
			});

			const watchListName = getFirstMatchingWatchListName([currentWatchList, liveCFDWatchlist?._lastUsedWatchlist || "", dashboardContext.watchlist[0]?._name], dashboardContext.watchlist);
			setCurrentWatchList(watchListName);
		} else {
			setCurrentWatchList(currentWatchList || dashboardContext.watchlist[0]?._name);
		}
	};
};
