import { useContext, useEffect, useMemo } from 'react';

import statusStore, { StatusStore } from '../../store/accountStatusStore';

import AppContext from '../../contexts/AppContext';
import { TIERS_WITH_GYM_ACCESS } from '../../setup/subscriptionsConfig';
import { SubscriptionTier } from '../functions/subscriptionUtils';

import { GymAccountUtils } from '../../pages/TradersGym/Accounts/GymAccountUtils';
import { GymTradingAccount } from '../../pages/TradersGym/Accounts/GymTradingAccount';

import DashboardContext from '../../contexts/DashboardContext';
import { TradersGymContext, TradersGymContextType } from '../../pages/TradersGym/TradersGymContext';
import authStore from '../../store/authStore';
import { AccountMarketType } from '../../gateways/RfpGateway/rfp.types';
import tradingAccountStore from '../../store/tradingAccountStore';

const useEnableTradersGym = () => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const permissions = statusStore((state: StatusStore) => state.permissions);
	const tradersGym = permissions?.tradersGym;
	const { setGymProps } = gymContext;
	const setIsTradeGymActive = statusStore.use.setIsTraderGymActive();
	const setUnlockGym = statusStore.use.setUnlockGym();
	const userProfile = authStore.use.userProfile();
	const email = appContext.email ?? userProfile.email;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	const { tradersGymContext } = gymContext;

	const accountMarketType = useMemo(() => {
		return isSpreadBettingAccount ? AccountMarketType.SpreadBetting : AccountMarketType.CFD;
	}, [isSpreadBettingAccount]);

	useEffect(() => {
		if (tradersGymContext.isActive) {
			canEnableTradersGym();
		}
	}, [accountMarketType]);

	const canEnableTradersGym = () => {
		if (!email) {
			return;
		}

		const selectedIndex = dashboardContext.tradingAccount.findIndex((acc) => {
			return acc.id === dashboardContext.selectedTradingAccount;
		});

		const account = dashboardContext.tradingAccount[selectedIndex];

		if (account && (isFundedTrader || tradersGym === true)) {
			if (isJapanSubscriptionAccount && !TIERS_WITH_GYM_ACCESS.includes(appContext.subscriptionInfo?.tier as SubscriptionTier)) {
				return false;
			}

			const emailId = GymAccountUtils.convertEmailToId(`${email}_${accountMarketType}`);
			let gymAccount = GymTradingAccount.restoreAccount(emailId, accountMarketType);

			if (!gymAccount) {
				const accountBalance = userProfile?.country?.demoBalance ?? 30000;
				gymAccount = GymAccountUtils.createAccount(
					emailId,
					account.baseCurrency,
					accountBalance,
					account.tradingInstruments,
					accountMarketType
				);

				gymAccount.storeAccount();
			} else {
				// If you click too early on the gym button,
				// the account is not yet created and the app throws the following error - undefined tradingInstruments
				// So we need to check if the account is created and then set the tradingInstruments
				if (account) {
					gymAccount.tradingInstruments = account.tradingInstruments;
				}
			}

			setGymProps({ isActive: true, gymTradingAccount: gymAccount });
			setIsTradeGymActive(true);
			return true;
		} else {
			setUnlockGym(true);
			return false;
		}
	};

	return canEnableTradersGym;
};

export default useEnableTradersGym;
