import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// must be imported before <App />
import 'semantic-ui-css/semantic.min.css';
import './index.scss';

import './setup/i18n';
import './setup/gtm';
import { DependencyContainer, IResolver, Resolver } from './utils/functions/Ioc';
import { WindowContextProvider } from './contexts/WindowContext';
import { default as RfpGateway } from './gateways/RfpGateway/RfpGateway';
import { default as BinaryRfpGateway } from './gateways/RfpGateway/BinaryRfpGateway';
import { InstrumentContextProvider } from './contexts/InstrumentContext';
import { AppContextProvider } from './contexts/AppContext';
import { DashboardContextProvider } from './contexts/DashboardContext';
import { ChartContextProvider } from './contexts/ChartContext';
import { showSkipWaitingPrompt } from './shared/NewUpdateNotification/utils';
import { MarginRulesContextProvider } from './contexts/MarginRulesContext';
//import { isProduction, isPreviewProduction } from './setup/config';
import App from './App';
import * as serviceWorkerContainer from './workers/serviceWorkerContainer';

const dependencyContainer = new DependencyContainer()
	.registerSingle(RfpGateway, (resolver: IResolver) => {
		const instance = new BinaryRfpGateway({
			defaultFeedId: 'VTFeed',
			baseUrl: `${process.env.REACT_APP_RFP_BINARY_WEBPROXY}`,
			webSocket: {
				connectTimeout: 15000,
				feedDelay: 0,
				autoReconnect: false, // (isProduction || isPreviewProduction) ? { interval: 1000 } : false,
				simulateQuotes: false,
			},
		});

		if (
			process.env &&
			process.env.REACT_APP_ENV === 'development--' &&
			console &&
			typeof console.debug === 'function'
		) {
			instance.debugHandler = (...args: any[]) => {
				if (args.length > 0 && (args[0] as string).indexOf('MarketWatch') >= 0) {
					console.log(`[${new Date()}]: RfpGateway:`, args);
				}
			};
		}
		return instance;
	})
	.registerSingle(InstrumentContextProvider, (resolver: IResolver) => {
		return new InstrumentContextProvider();
	})
	.registerSingle(AppContextProvider, (resolver: IResolver) => {
		return AppContextProvider.instance;
	})
	.registerSingle(DashboardContextProvider, (resolver: IResolver) => {
		return DashboardContextProvider.instance;
	})
	.registerSingle(MarginRulesContextProvider, (resolver: IResolver) => {
		return MarginRulesContextProvider.instance;
	})
	.registerSingle(ChartContextProvider, (resolver: IResolver) => {
		return ChartContextProvider.instance;
	})
	.register(WindowContextProvider, new WindowContextProvider(globalThis.window));

Resolver.setResolver(dependencyContainer.resolver);

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById('root')
);

// const onUpdate = () => {
// 	showSkipWaitingPrompt();
// };

window.addEventListener('load', async () => {
	// When the user asks to refresh the UI, we'll need to reload the window
	if (navigator.serviceWorker) {
		const registration = await navigator.serviceWorker.getRegistration();

		// ensure the case when the updatefound event was missed is also handled
		// by re-invoking the prompt when there's a waiting Service Worker
		if (registration?.waiting) {
			showSkipWaitingPrompt();
		}

		if (registration?.active) {
			setInterval(() => {
				// @ts-ignore
				if (registration) {
					console.info('registration -> check for updates');
					registration
						.update()
						.then((r) => {})
						.catch((e) => {});
				}
			}, 600000);
		}

		let refreshing = false;

		// detect controller change and refresh the page
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (!refreshing) {
				window.location.reload();
				refreshing = true;
			}
		});
	}
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerContainer.unregister();
// serviceWorkerContainer.register({ onUpdate });

//close child windows when this window closes
Resolver.resolve(WindowContextProvider).once('beforeUnload', (sender, event) => {
	sender.children.forEach((child) => {
		child.window.close();
	});
});
