import React, { useEffect, useState, useContext, useMemo, Dispatch, SetStateAction } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from '../../../../../../shared/Modal/Modal';
import InfoElement from '../OrderTicketModal/OrderInformation/InfoElement';

import { default as DashboardContext } from '../../../../../../contexts/DashboardContext';
import { default as usePromiseFactory } from '../../../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../../../utils/hooks/useForceRerender';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import { getInstrumentDetails, convertAmountToLot } from '../../../../../../utils/functions/calculations';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../../../../contexts/AppContext';
import StringUtils from '../../../../../../utils/functions/StringUtils';

import { QUANTITY_TYPE } from '../../../../../../utils/functions/enums';
import { getGeneralFormatDate } from '../../../../../../utils/functions/subscriptionUtils';
import { GENERAL_DATETIMES_FORMAT } from '../../../../../../setup/config';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';

import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import MoneyFormatter from '../../../../../components/MoneyFormatter';

import styles from './ClosedTicketModal.module.scss';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';

interface ClosedTicketModalProps {
	data: any;
	showClosedPositionTicket: boolean;
	setShowClosedPositionTicket: Dispatch<SetStateAction<boolean>>;
}
const ClosedTicketModal = ({ data, showClosedPositionTicket, setShowClosedPositionTicket }: ClosedTicketModalProps) => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const isArabic = appContext.isArabic;
	const languageSettings = appContext.languageSettings;

	const selectedTradingAccount = useSelectedTradingAccount();
	const detailedInformation = dashboardContext.detailedInformation;
	const quantityType = dashboardContext.quantityType;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;

	const selectedPosition = data[0]; //tradingPositions.filter((position) => position.posId === data[0].posId)[0];
	useObservable(
		dashboardContext.getPropertyChangeStream(
			'tradingAccount',
			'detailedInformation',
			'tradingPositions',
			'quantityType'
		),
		async (change) => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		}
	);

	const [amount, setAmount] = useState<number>(0);
	const [comment, setComment] = useState<string>();

	const amountOrLots = () => {
		let selectedInstrumentDetails =
			detailedInformation && getInstrumentDetails(detailedInformation, [selectedTradingAccount!], data[0].code);
		const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
		setAmount(quantityType === QUANTITY_TYPE.LOTS ? convertAmountToLot(data[0].qty, instrumentRndLot) : data[0].qty);
	};

	const updateCommentValue = () => {
		let commandValue = data[0]?.comment;
		if (StringUtils.isNullOrEmpty(commandValue)) {
			commandValue = t('wtr:NA');
		}
		setComment(commandValue);
	};

	useEffect(() => {
		amountOrLots();
		updateCommentValue();
	}, []);

	const amountDecPrec = useMemo(() => {
		return amount?.toString().split('.').length > 1 ? amount?.toString().split('.')[1].length : 0;
	}, [amount]);

	const formattedOpenTime = appContext.isJapanAccount
		? getGeneralFormatDate(data[0].oT, true, true)
		: moment(data[0].oT).format(GENERAL_DATETIMES_FORMAT).toLocaleUpperCase();

	const formattedCloseTime = appContext.isJapanAccount
		? getGeneralFormatDate(data[0].cT, true, true)
		: moment(data[0].cT).format(GENERAL_DATETIMES_FORMAT).toLocaleUpperCase();

	const getSimulationName = (id: string) => {
		return gymContext.tradersGymContext.simulationsManager?.getSimulationWithId(id)?.displayName ?? 'N/A';
	};

	return (
		<div>
			<Modal
				style={{ direction: isArabic ? 'rtl' : 'ltr' }}
				dialogClassName={styles.modal}
				centered
				scrollable
				show={showClosedPositionTicket}
				onHide={() => setShowClosedPositionTicket((state) => !state)}
			>
				<Modal.Header className={styles.modalHeader}>
					<Modal.Title className={styles.modalTitle}>{t('wtr:CLOSED_POSITION_DETAILS')}</Modal.Title>
					<FontAwesomeIcon
						icon={['fas', 'times']}
						className={styles.closeIcon}
						onClick={() => setShowClosedPositionTicket((state) => !state)}
					/>
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					{gymContext.tradersGymContext.isActive && (
						<InfoElement title={'Simulation'} value={getSimulationName(data[0].simulationId)} />
					)}
					<InfoElement title={t('wtr:WTR_POSITION_ID')} value={data[0].posId} />
					<InfoElement title={tt('INSTRUMENT')} value={data[0]?.marketItem.displayName} />
					<InfoElement title={tt('SIDE')} value={t(`en:${data[0].side}`)} />
					<InfoElement
						title={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
						value={formatNumberWithCommas(amount, amountDecPrec, languageSettings)}
					/>
					<InfoElement title={tt('OPEN_TIME')} value={formattedOpenTime} valueClassName={styles.date} />
					<InfoElement title={tt('CLOSE_TIME')} value={formattedCloseTime} valueClassName={styles.date} />
					<InfoElement
						title={tt('OPEN_PRICE')}
						value={formatNumberWithCommas(data[0].oP, data[0].dec, languageSettings)}
					/>
					<InfoElement
						title={tt('CLOSE_PRICE')}
						value={formatNumberWithCommas(data[0].cP, data[0].dec, languageSettings)}
					/>
					<InfoElement
						title={tt('TAKE_PROFIT')}
						value={
							isNaN(parseFloat(data[0].tp)) ? 'N/A' : formatNumberWithCommas(data[0].tp, data[0].dec, languageSettings)
						}
					/>
					<InfoElement
						title={tt('STOP_LOSS')}
						value={
							isNaN(parseFloat(data[0].sl)) ? 'N/A' : formatNumberWithCommas(data[0].sl, data[0].dec, languageSettings)
						}
					/>
					<InfoElement
						title={tt('NET_PL')}
						setRed={selectedPosition.netProfit < 0}
						setRedPip={false}
						id={'closedTicketNetPL'}
						value={<MoneyFormatter money={selectedPosition.netProfit} />}
						valueClassName={styles.pL}
					/>
					<InfoElement
						title={tt('GROSS_PL')}
						setRed={selectedPosition.grossProfit < 0}
						setRedPip={false}
						id={'closedTicketGrossPL'}
						value={<MoneyFormatter money={selectedPosition.grossProfit} />}
						valueClassName={styles.pL}
					/>
					{!appContext.isJapanAccount && (
						<InfoElement
							title={t('wtr:WTR_DIVIDEND')}
							value={<MoneyFormatter money={!isNaN(data[0].dividend) ? data[0].dividend : 0} />}
						/>
					)}
					<InfoElement title={tt('SWAP')} value={<MoneyFormatter money={!isNaN(data[0].swap) ? data[0].swap : 0} />} />
					{!appContext.isJapanAccount && (
						<InfoElement
							title={tt('COMMISSION')}
							value={<MoneyFormatter money={!isNaN(data[0].comm) ? data[0].comm : 0} />}
						/>
					)}
					<InfoElement title={tt('POSITION_COMMENT')} value={comment ?? t('wtr:NA')} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ClosedTicketModal;
