import React, { useContext } from 'react';

import AppContext from '../../../../../contexts/AppContext';

import useObservable from '../../../../../utils/hooks/useObservable';
import authStore, { AuthStore } from '../../../../../store/authStore';
import useForceRerender from '../../../../../utils/hooks/useForceRerender';

import { AppComponentType } from '../../../../../utils/functions/enums';

import accountStatusStore, { StatusStore } from '../../../../../store/accountStatusStore';

import Routes from '../../../../../setup/routes';
import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import { Item } from './';

const Management = () => {
	const appContext = useContext(AppContext);
	const { isJapanAccount } = useContext(AppContext);

	const permissions = accountStatusStore((store: StatusStore) => store.permissions);
	const mainMenuAction = permissions?.mainMenuAction;
	const portalPage = permissions?.portalPage;
	const setShowAccountStatus = accountStatusStore((store: StatusStore) => store.setShowAccountStatus);
	const isDemoMode = authStore((store: AuthStore) => store.isDemoMode);
	const hasLiveAccount = accountStatusStore((store: StatusStore) => store.hasLiveAccount);

	let component;
	let route = Routes.trader.funds;
	const forceRerender = useForceRerender();
	useObservable(appContext.getPropertyChangeStream('accountStats'), () => forceRerender());

	// in case of JAPAN where should lead ???
	if (portalPage === IStatusConfigEnum.deposits) {
		component = AppComponentType.Funds;
		route = Routes.trader.funds;
	} else if (portalPage === IStatusConfigEnum.fork) {
		component = AppComponentType.CompleteOnboarding;
		route = Routes.trader.openAccount;
	} else if (portalPage === IStatusConfigEnum.relevantStep && !isJapanAccount) {
		component = AppComponentType.UploadDocuments;
		route = Routes.trader.uploadDocuments;
	} else if (portalPage === IStatusConfigEnum.statusPage) {
		component = AppComponentType.Status;
		route = Routes.trader.status;
	} else if (isJapanAccount) {
		component = AppComponentType.OpenAccount;
		route = Routes.trader.openAccount;
	}

	const handleAccountClick = () => {
		if (portalPage === IStatusConfigEnum.accountStatus) {
			setShowAccountStatus();
			return false;
		}

		return true;
	};

	if (isDemoMode) return null;
	if (isDemoMode && appContext.isJapanAccount && hasLiveAccount) return null;

	return (
		<Item
			preClick={handleAccountClick}
			component={component}
			route={route}
			icon={mainMenuAction === IStatusConfigEnum.fund ? 'money-bill-1' : 'user'}
			tooltip={mainMenuAction === IStatusConfigEnum.fund ? 'wtr:LEFT_NAV_FUNDS' : 'en:ACCOUNT'}
			isLoading={!portalPage || !mainMenuAction}
		/>
	);
};

export default Management;
