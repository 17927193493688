import { Result, Topic, SubType, TradingPositionSide, TradingPositionType } from '../../utils/functions/enums';

export enum RFPDataObjectType {
	All,
	HistoryTickItems,
	HistoryTick,
	HistorySize,
	PriceQuote,
	TfboLoginResponse,
	TfboEndpointResponse,
	Pong,
	FeedInfos,
	MarketItemsRecord,
	MarketItem,
	MarketItemsInfoRecord,
	MarketItemInfo,
	TradingAccount,
	TradingAccountLogin,
	TradingInstruments,
	TradingProvider,
	TradeEvent,
	TradingPosition,
	MarketWatchIntervals,
	MarketWatchCategories,
	MarketWatchItem,
	MarketWatchItems,
	RFPNews,
	ClientCommandResult,
	HistoryTimescaleStartDate,
	TradingAccountsError,
	TradingAccountLogout,
	TTSessionTimes,
}

export interface RFPDataObject {
	dataObjectType: RFPDataObjectType;
}

export interface ClientCommandResult extends RFPDataObject {
	reqId?: string;
	feedId?: string;
	code?: string;
	msg?: string;

	objId: string;

	topic: Topic;
	result: Result;
	subType: SubType;
}

export interface RFPLoginPayload {
	username: string;
	password: string;
	tradingMode: TradingMode;
}

export interface RFPConnectPayload {
	username: string;
	password?: string;
	authToken?: string;
	sso_token?: string;
	tradingMode: TradingMode;
	enableSSO?: boolean;
	lang?: string;
	skip_tfbo_login: boolean;
	captcha_response?: string;
}

export enum RFPConnectionErrorType {
	AuthenticationFailed = 'Error: Authentication failed.',
	AuthenticationTimeout = 'Error: Authentication timeout.',
	RequestFeedIdsTimeout = 'Error: Timeout requesting feedIDs.',
}

export interface RFPConnectResult {
	success: boolean;
	status: number;
	email?: string | null | undefined;
	tfboSessionId?: string | null | undefined;
	tfboToken?: string | null | undefined;
	error?: string | null | undefined;
	prop?: { [k: string]: string } | null | undefined;
	login_mode?: TradingMode | null | undefined;
	fundedTrader?: boolean | null | undefined;
}

export type TradingMode = 'LIVE' | 'DEMO';

interface RFPWebProxyHeaders {
	'heart-beat': string;
	'user-name': string;
	version: string;
	subscription?: any;
}

export interface Frame {
	body: string;
	command: string;
	headers: RFPWebProxyHeaders;
	ack?: (headers: unknown) => void;
	nack?: (headers: unknown) => void;
}

export interface FeedInfo {
	feedID: string;
	initialGroup: null;
	name: string;
	variant: null;
}

export interface FeedInfos extends RFPDataObject {
	feedIds: FeedInfo[];
}

export interface TradingProvider extends RFPDataObject {
	description: string;
	displayName: string;
	feedID: string;
	id: number;
	uniqueName: string;
}

// These fields are finally working as expected for field accountMarketType on account.
export enum AccountMarketType {
	CFD = 0,
	Australia = 1,
	Africa = 2,
	Japan = 3,
	SpreadBetting = 4,
	JapanSpread = 5,
}

export interface RFPTradingAccountsError {
	dataObjectType: RFPDataObjectType;
	providerId: number;
	errorMessage: string;
}

export interface TradingAccount extends RFPDataObject {
	accountType: 'DEMO' | 'LIVE' | 'GYM';
	balance: number;
	baseCurrency: string;
	credit: number;
	equity: number;
	freeMargin: number;
	grossProfit: number;
	id: number;
	leverage: number;
	marginLevel: number;
	preferredFeed: string;
	providerAccountId: string;
	accountNumber?: number;
	providerId: number;
	usedMargin: number;
	marginCalculationType?: number;
	val?: MarketItem;
	tradingInstruments?: TradingInstruments;
	activePositions: Record<number, TradingPosition>;
	closedPositions: Record<number, TradingPosition>;
	netProfit: number;
	isJapanAccount: boolean;
	enabled: boolean;
	accountMarketType: AccountMarketType;
	isLoggedIn: boolean;
}

export interface QuantityTypeUpdate {
	quantityType: number;
	accId: number;
}

export interface DemoAccountParams {
	defaultCode: string;
	instruments: { code: string }[];
	msgType: number;
	tpId: number;
}

export interface NewsSubscriptionRequest {
	action: SubscriptionAction;
	msgCount: number;
}

export interface MarketWinNewsSubscriptionRequest {
	subscribe: boolean;
}

export interface NewsApiResponse {
	limit: number;
	offset: number;
	request: [];
	sorOrder: string;
	sortBy: string;
	sortOrder: string;
}

export interface RFPNews extends RFPDataObject {
	body: string;
	country?: string;
	currencies?: string[];
	headline: string;
	id: number;
	newsCategoryId?: string;
	newsTypeId?: string;
	revision?: number;
	time: Long;
	externalLinkUrl?: string;
}

export interface MarketItemsRecord extends RFPDataObject {
	marketItems: MarketItem[];
}

export interface MarketItem extends RFPDataObject {
	bCcy: string;
	ccy: string;
	code: string;
	decPrec: number;
	displayName: string;
	fullName: string;
	feedId: string;
	flags: number;
	grp: string;
	isin: string;
	marginType: number;
	pipSizeH: string;
	qCcy: string;
	quoteCurrency?: string; //TODO remove this field, as it does not appear to be sent by RFP
	tickSize: number;
	tradable?: boolean | null | undefined;
	exchangeCountryCode?: string | null | undefined;
	sector?: string | null | undefined;
	exchangeTicker?: string | null | undefined;
	volatility?: string | number;
	percentChange?: string | number;
	marketItemInfo?: MarketItemInfo | null;
	minTier?: number | null;
	category?: string;
	watchlistRank?: number | null;
	subscriptionCounter: number;
	accountMarketType: AccountMarketType;
	accountType: AccountMarketType;
	tradingSessions?: TradingSessions;
}

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export interface TradingSession {
	isOpen: boolean;
	day: DayOfWeek;
	openDate: Date;
	closeDate: Date;
	schedule: { start: string; end: string };
}

export interface TradingSessions {
	//Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday
	sessions: TradingSession[][];
	timezone: string;
	utcTimeOffset: number; // in hours
}

export interface MarketItemsInfoRecord extends RFPDataObject {
	marketItemsInfo: MarketItemInfo[];
}

export interface MarketItemInfo extends RFPDataObject {
	code: string;
	decimalPrecision: string;
	feedId: string;
	formatLength: string;
	formatPosition: string;
	pipSize: string;
	tickSize: string;
}

export interface SelectedSymbol {
	[chartId: string]: MarketItem;
}

export interface TradingAccountLoginRequest {
	action: 'Login';
	tradingAccountId: number;
}

export interface TradingAccountLogoutRequest {
	acct_id: number;
}
export interface RequestTTWatchList {
	isoCode: string;
}

export interface TTWatchListItem {
	title: string;
	order: number;
	codes_csv: any;
}

export interface TTWatchList {
	isoCode: string;
	items: TTWatchListItem[];
}

export interface TradingAccountLogin extends RFPDataObject {
	accountId: number;
	error: null | string;
	loggedOn: boolean;
}

export interface AccountTierRequest {
	accountId: string;
}

export interface AccountTierResponse {
	accountId: string;
	error: string;
	enabled: boolean;
	tier: number;
	usedUnits: number;
	availableUnits: number;
	maxUnits: number;
}

export interface TradingInstruments extends RFPDataObject {
	account: number;
	instruments: {
		[key: string]: Instrument;
	};
}

export interface Instrument {
	amtDPrec: number;
	ccy: string;
	code: string;
	grp: string;
	lotDPrec: number;
	maxL: number;
	minL: number;
	pipPos: number;
	rndLot: number;
	rshL: number;
	rshS: number;
	sDig: number;
	stpL: number;
	swpL: number;
	swpS: number;
	trPeriod: string;
	tickValue?: number;
	tickSize?: number;
	marginReq?: number;

	maxAmount: number;
	minAmount: number;
	stepAmount: number;

	threeDaySwap: string;

	mtReqMx: number;
	mtValOne: number;
	mtReqOne: number;
	mtValTwo: number;
	mtReqTwo: number;
	mtValThree: number;
	mtReqThree: number;
	mtValFour: number;
	mtReqFour: number;
	mtValFive: number;
	mtReqFive: number;
	mtValSix: number;
	mtReqSix: number;
}

export interface RequestTTMarginRules {
	accountId: number;
}

export interface TTMarginRuleItem {
	id: number;
	name: string;
	instruments: string;
	tierValues: string;
	tierRequirements: string;
	scheduleEnabled: number;
	dayIndexesSundayFirst: string;
	dayValues: string;
}

export interface TTMarginRules {
	accountId: number;
	items: TTMarginRuleItem[];
}

export enum PositionSide {
	PSD_UNKNOWN	=0,
	BUY = 1,
	SELL = 2,
}

/*REQ_FUTURE_MARGIN*/
export interface RequestFutureMargin {
	acctId: number;
	amount: number;
	symbol: string;
	reqId: string;
	side: PositionSide;
}

/*FUTURE_MARGIN*/
export interface FutureMargin {
	margin: number;
	reqId: string;
}

export interface HistoryRequest {
	reqId: string;
	feedId: string;
	code: string;
	priceType: PriceType;
	timescale: TimeScale;
	tickCount?: number;
	startTime?: number;
	endTime?: number;
}

export interface HistorySubscription extends HistoryRequest {
	action: SubscriptionAction;
}

export interface HistoryRequestStartDate {
	feedId: string;
	code: string;
	timeInterval: number; //interval_min
}

export interface HistoryTimescaleStartDate extends RFPDataObject {
	feedId: string;
	code: string;
	timeInterval: number; //interval_min
	time: number; //time in seconds
}

export type PriceQuote = RFPDataObject & {
	/** ask */
	a: number;
	/** bid */
	b: number;
	/** symbol */
	c: string;
	/** feed ID */
	f: string;
	/** high */
	h: number;
	/** low */
	l: number;
	/** timestamp */
	t: number;
};

export type PriceType = 'Bid' | 'Ask' | 'Average';

export type TimeScale =
	| 'TS_1MIN'
	| 'TS_2MIN'
	| 'TS_3MIN'
	| 'TS_5MIN'
	| 'TS_10MIN'
	| 'TS_15MIN'
	| 'TS_30MIN'
	| 'TS_1HOUR'
	| 'TS_2HOUR'
	| 'TS_3HOUR'
	| 'TS_4HOUR'
	| 'TS_6HOUR'
	| 'TS_8HOUR'
	| 'TS_12HOUR'
	| 'TS_1DAY'
	| 'TS_1WEEK'
	| 'TS_1MONTH';

export interface HistoryTick extends RFPDataObject {
	reqId: string;
	feedId: string;
	code: string;

	continuousFlag: number;
	timescale: TimeScale;

	open: number;
	high: number;
	low: number;
	close: number;

	closeTime: number;
	volume: number;

	openTime: number;
}

export interface HistoryTickItems extends RFPDataObject {
	reqId: string;
	feedId: string;
	code: string;
	timeScale: TimeScale;

	historyTicks: HistoryTick[];
}

export interface HistorySize extends RFPDataObject {
	reqId: string;
	feedId: string;
	code: string;
	rfpTimingInterval: number;
	duration: number;
	size: number;
}

export interface FeedSubscription {
	feedId: string;
}

export interface RFPRequestTTSessionTimes {
	code: string;
}

export interface RFPSessionTimes extends RFPDataObject {
	serverTimeZone: string;
	symbol: string;
	trading: string;
}

export interface SocketHeaders {
	Authorization: string;
}

export interface QuoteSubscription {
	action: SubscriptionAction;
	feedId: string;
	marketItems: MarketItem[];
}

export enum SubscriptionAction {
	Subscribe,
	Unsubscribe,
	Snapshot,
}

export interface RFPInfo {
	clReqId: string;
	code: string | null;
	feedId: string | null;
	msg: string;
	objId: null;
	result: string;
	subType: string;
	topic: string;
}

export interface IChartParameters {
	func: string;
	symbol: string;
	period: number;
	timeUnit: string;
	interval: number;
	start: string;
	end: string;
}

export interface RFPLoginError {
	error: string;
	message: string;
	path: string;
	status: number;
	timestamp: string;
}

export interface RFPLoginSuccess {
	accessToken: string;
	email: string;
	id: number;
	roles: string[];
	tfboSessionId: string;
	tfboToken: string;
	tokenType: 'Bearer';
	username: string;
}

export enum TradingPositionState {
	open = 'OPEN',
	pending = 'PENDING',
	closed = 'CLOSED',
	canceled = 'CANCELED',
	deleted = 'DELETED',
}

// export enum LocalizationLang {
//     'none' = ProtobufSchema.types.LocalizationLang.,
//     'en' = 'en',
//     'zh-Hans' = 'cn',
//     'zh-Hant' = 'ct',
//     'de' = 'de',
//     'ms-MY' = 'ms_MY',
//     'pt-BR' = 'pt_BR',
//     'th' = 'th',
//     'vi' = 'vi',
//     'ar-SA' = 'ar_SA',
//     'ja' = 'ja'
// }

export interface TradingPosition extends RFPDataObject {
	aId: number;
	state: TradingPositionState;
	side: TradingPositionSide;
	code: string;
	f: string;
	type?: TradingPositionType;
	oP?: number;
	cP?: number;
	prc?: number;
	comm?: number;
	swap?: number;
	qty?: number;
	oT?: number;
	cT?: number;
	eT?: number;
	sl?: number;
	tp?: number;
	posId?: number;
	qCcy?: string;
	dec?: number;
	grossProfit?: number;
	dividend?: number;
	marketItem?: MarketItem;
	currentPrice?: number;
	pips?: number;
	additionalSubscriptionPair: Set<string>;
	netProfit?: number;
	comment?: string;
	cancelRequestSent?: boolean;
	trailingStop?: number;
}

export type orderTypes = 'market' | 'limit' | 'stop' | '';

export interface NewOrderRequest {
	reqId: number;
	acctId: number;
	feedId: string;
	code: string;
	tradeSize: number;
	tradeSide: 'BUY' | 'SELL' | 'NEW';
	tradeType: 'STOP' | 'LIMIT';
	expiration?: OrderExpiration;
	limitLevel?: number;
	stopLevel?: number;
	priceLevel?: number;
	trailingStop?: number;
}

export interface EditOrderRequest {
	reqId: number;
	acctId: number;
	posId: number;
	priceLevel?: number;
	expiration?: OrderExpiration;
	limitLevel: string | number | null;
	stopLevel: string | number | null;
	trailingStop?: string | number | null;
}

export interface NewPositionRequest {
	reqId: number;
	acctId: number;
	feedId: string;
	code: string;
	tradeSize: number;
	tradeSide: 'BUY' | 'SELL' | 'NEW';
	limitLevel?: number;
	stopLevel?: number;
	trailingStop?: number;
}

export interface EditPositionRequest {
	reqId: number;
	acctId: number;
	posId: number;
	limitLevel: string | null;
	stopLevel: string | null;
	trailingStop?: string | null;
}

export interface CloseTradeRequest {
	posId: number;
	trAccountId: number;
}

export interface PartialCloseRequest {
	posId: number;
	trAccountId: number;
	amount: number;
}

export interface MarketWatchSnapshotsRequest {
	categoryName: string;
	intervalLength: number;
}

export interface MarketWatchIntervals extends RFPDataObject {
	feedId: string;
	intervalLength: number[];
}

export interface MarketWatchCategory {
	name?: string | null | undefined;
	intervalLength?: number | null | undefined;
	itemsCount?: number | null | undefined;
	dynamic?: boolean | null | undefined;
}

export interface MarketWatchCategories extends RFPDataObject {
	feedId: string;
	categories: MarketWatchCategory[];
}

export interface MarketWatchItem extends RFPDataObject {
	feedId?: string | null | undefined;
	code?: string | null | undefined;
	intervalLength?: number | null | undefined;
	volatility?: number | null | undefined;
	percentChange?: number | null | undefined;
	high?: number | null | undefined;
	low?: number | null | undefined;
	bid?: number | null | undefined;
	ask?: number | null | undefined;
}

export interface MarketWatchItems extends RFPDataObject {
	feedId: string;
	dynamic_category: string | null | undefined;
	category: string;
	totNumMsgs: number;
	msgNum: number;
	items: MarketWatchItem[];
}

export interface MarketWatchSubscriptionRequest {
	code: string[];
	intervalLength: number;
	subscribe: boolean;
}

export interface MarketWatchDynamicSubscriptionRequest {
	category: string;
	intervalLength: number;
	subscribe: boolean;
}

export interface Ping {}

export interface Pong extends RFPDataObject {}

export interface TfboLoginResponse extends RFPDataObject {
	success: boolean;
	error: string;
	tfbo_session_id: string;
	tfbo_token: string;
}

export interface TfboEndpointResponse extends RFPDataObject {
	restApiURL: string;
	uploadDocsURL: string;
	exchangeRateURL: string;
}

export interface TradeRequest {
	quantity: number;
	quantityType: string;
	sl: string;
	tp: string;
	typeOfOrder: orderTypes;
	acctId?: number;
	tradeType?: string;
	expiration?: OrderExpiration;
	posId?: number;
	code?: string;
	lotAmountQty?: number;
	tradeSize?: number;
	limitLevel?: number;
	stopLevel?: number;
	priceLevel?: number;
	tradeSide?: string;
}

export interface TradeEvent extends RFPDataObject {
	message?: string | null;
	messageType?: string | null;
	requestId?: string | number | null;
	events?: Array<{ message?: string | null; error?: string | null; messageType?: string | null }>;
}

export interface InstrumentLastTrade {
	[instrument: string]: TradeRequest;
}

export type PositionState = 'OPEN' | 'PENDING' | 'CLOSED' | null;

export type OrderExpiration = 'EXP_DAY' | 'EXP_WEEK' | 'EXP_MONTH' | 'EXP_GTC' | 'CUSTOM' | number | null;

export interface PreviousDayClosePriceRequest {
	code: string[];
}

export interface PreviousDayClosePriceItem {
	code?: string | null;
	prevClose?: number | null;
}
export interface PreviousDayClosePrice {
	feedId?: string;
	item?: PreviousDayClosePriceItem[];
}

export interface PreviousDayClosePrices {
	[instrument: string]: number;
}

export interface TierUpdateInfo {
	accountId: number;
	tierNum: number;
}
