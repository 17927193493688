import { immer } from 'zustand/middleware/immer';

import { Account, TAccountStats } from '../gateways/TfboGateway/TfboGateway.types';

import { create } from './create';

import createSelectors from './createSelectors';

export interface TradingAccountStoreActions {
	setSelected: (selected: number) => void;

	setAccounts: (accounts: any[]) => void;

	setAccountStats: (accountStats: any[]) => void;

	setCurrentWatchList: (selectedAccountStats: any) => void;
	setSelectedAccountStats: (selectedAccountStats: any) => void;
	setIsFundedTrader: (isFundedTrader: boolean) => void;
	setAccountMarketType: ({isJapanSpread, isJapanSubscription, isSpreadBetting}: {isJapanSpread: boolean, isJapanSubscription: boolean, isSpreadBetting: boolean}) => void;
	reset: () => void;
}

export interface TradingAccountStoreValues {
	selected: number;
	accounts: Account[];
	accountStats: TAccountStats[];
	selectedAccountStats: any;
	currentWatchList: string;

	// TODO: when refactoring and have time, group these status in a clever way, and update all over the application
	isSpreadBetting: boolean;
	isJapanSpread: boolean;
	isJapanSubscription: boolean;
	isFundedTrader: boolean;
}

export const initialStateTradingAccountStore: TradingAccountStoreValues = {
	selected: 0,
	selectedAccountStats: {},
	accounts: [],
	accountStats: [],
	currentWatchList: '',
	isSpreadBetting: false,
	isJapanSpread: false,
	isJapanSubscription: false,
	isFundedTrader: false,
};
export type TradingAccountStore = TradingAccountStoreValues & TradingAccountStoreActions;

const tradingAccountStore = create<TradingAccountStore>()(
	immer((set: any) => ({
		...initialStateTradingAccountStore,

		setSelected: (selected: number) => {
			set((state: TradingAccountStore) => {
				state.selected = selected;
			});
		},

		setAccountMarketType: ({isJapanSpread, isJapanSubscription, isSpreadBetting}: {isJapanSpread: boolean, isJapanSubscription: boolean, isSpreadBetting: boolean}) => set({ isJapanSpread, isJapanSubscription, isSpreadBetting }),

		setSelectedAccountStats: (selectedAccountStats: {}) =>
			set((state: TradingAccountStore) => {
				state.selectedAccountStats = { ...selectedAccountStats };
			}),

		setAccounts: (accounts: any) => set({ accounts }),
		setCurrentWatchList: (currentWatchList: string) => set({ currentWatchList }),
		setAccountStats: (accountStats: any[]) => set({ accountStats }),
		setIsFundedTrader: (isFundedTrader: boolean) => set({ isFundedTrader }),
		reset: () => set({ ...initialStateTradingAccountStore }),
	}))
);

export default createSelectors(tradingAccountStore);
