import React, { FC, useContext, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import cn from 'classnames';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

import FeedbackModal from '../.././views/components/FeedbackModal/FeedbackModal';
import Header from '../../views/features/Dashboard/Header/Header';
import Navigation from '../../views/features/Dashboard/Navigation/Navigation';
import SubscriptionChecker from '../../pages/Subscriptions/SubscriptionChecker/SubscriptionChecker';

import AppContext from '../../contexts/AppContext';
import NotificationsContextProvider from '../../contexts/NotificationsContext';
import DashboardContext from '../../contexts/DashboardContext';
import useShortTranslation from '../../utils/hooks/useShortTranslation';

import NotificationModal, { NotificationModalConfig } from '../../views/components/NotificationModal/NotificationModal';
import useLogout from '../../utils/hooks/useLogout';
import UserGuide from '../../views/components/userGuideModal/UserGuide';

import ContactModal from './../../views/components/ContactModal/ContactModal';
import ReconnectModal from './../../views/components/ReconnectModal/ReconnectModal';

import tradingAccountStore from "../../store/tradingAccountStore";
import InstrumentContext from "../../contexts/InstrumentContext";

import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
	hasHeader?: boolean;
	hasMainNav?: boolean;
	hasHeaderSearch?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, hasHeaderSearch = true }) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanSpreadAccount = tradingAccountStore.use.isJapanSpread();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const instrumentContext = useContext(InstrumentContext);

	useEffect(() => {
		document.body.classList.add('auth');
		return () => document.body.classList.remove('auth');
	});

	useIdleTimer({
		timeout: 1000 * dashboardContext.inactiveTime,
		onIdle: useLogout(),
		debounce: 500,
	});

	const notificationConfig: NotificationModalConfig = {
		backdrop: 'static',
		message: tt('LOG_OUT_CONFIRM'),
		onConfirm: useLogout(),
		id: 'logout',
		showActions: true,
		title: t('en:MORE_LOGOUT'),
	};

	useEffect(() => {
		instrumentContext.updateGroups(isJapanSubscriptionAccount ?  'minTier' : 'grp' );
	}, [isJapanSpreadAccount, isJapanSubscriptionAccount, isSpreadBettingAccount]);

	return (
		<NotificationsContextProvider>
			<ReactNotifications />
			<FeedbackModal />
			<ContactModal />
			<ReconnectModal />
			<NotificationModal config={notificationConfig} />
			<UserGuide />
			{appContext.isJapanAccount ? <SubscriptionChecker /> : null}

			<div className={cn(styles.container)}>
				<div className={styles.header}>
					<Header showSearch={hasHeaderSearch} />
				</div>
				<div className={styles.navigation}>
					<Navigation />
				</div>
				<div className={styles.content}>{children}</div>
			</div>
		</NotificationsContextProvider>
	);
};

export default AuthLayout;
