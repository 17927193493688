import { createContext } from 'react';
import { EventEmitter } from 'events';
import { INewWindowProps } from 'react-new-window';
import { Resolver } from '../utils/functions/Ioc';
import { default as Nullable, Optional } from '../utils/functions/Nullable';
import { default as Lazy } from '../utils/functions/Lazy';
import { default as PromiseFactory } from '../utils/functions/PromiseFactory';
import { default as TypeUtils } from '../utils/functions/TypeUtils';
import { AppContextProvider } from './AppContext';
import { ChartContextProvider } from './ChartContext';
import { DashboardContextProvider } from './DashboardContext';

export interface IWindowContextEventMap {
	unload: (sender: WindowContextProvider, event: Event) => any;
	load: (sender: WindowContextProvider, event: Event) => any;
	beforeUnload: (sender: WindowContextProvider, event: BeforeUnloadEvent) => any;
}

interface IWindowEventListenerMap extends Map<keyof WindowEventMap, any> {
	set<K extends keyof WindowEventMap>(key: K, value: (ev: WindowEventMap[K]) => any): this;
	get<K extends keyof WindowEventMap>(key: K): Optional<(ev: WindowEventMap[K]) => any>;
}

type TContextProviders = {
	app: AppContextProvider;
	chart: ChartContextProvider;
	dashboard: DashboardContextProvider;
};

interface IAppStateManager {
	contexts(): TContextProviders;
}

type TStateRequestEvent = {
	data: {
		callback: (appStateManager: IAppStateManager) => any;
	};
};

interface IChildWindowProps {
	url: string;
	windowName?: string;
	windowFeatures?: string;
}

interface IChildWindowTypeMap {
	watchlist: IChildWindowProps;
	orders: IChildWindowProps;
	news: IChildWindowProps;
	account: IChildWindowProps;
}

const childWindowTypeMap: IChildWindowTypeMap = {
	watchlist: {
		url: `${globalThis.window.location.origin}{Routes.newWindow}/watchlist`,
	},
	orders: {
		url: `${window.location.origin}{Routes.trader.watchlist}/window-orders`,
	},
	news: {
		url: `${window.location.origin}{Routes.trader.watchlist}/window-news`,
	},
	account: {
		url: `${window.location.origin}{Routes.trader.watchlist}/window-account`,
	},
};

export class WindowContextProvider {
	private m_childWindows = new Set<Window | null>();
	private readonly m_window!: Window;
	private readonly m_children = new Set<WindowContextProvider>();
	private readonly m_eventEmitter = new Lazy(() => new EventEmitter());
	private readonly m_windowEventHandlers = new Lazy(() => {
		return (new Map<keyof WindowEventMap, any>() as IWindowEventListenerMap)
			.set('load', (ev) => this.emit('load', this, ev))
			.set('unload', (ev) => this.emit('unload', this, ev))
			.set('beforeunload', (ev) => this.emit('beforeUnload', this, ev));
	});
	private m_type: 'main' | keyof IChildWindowTypeMap = 'main';
	private readonly childWindowSubscriptions: Set<string> = new Set<string>();
	private static readonly _default = new Lazy(() => new WindowContextProvider(globalThis.window));
	private static readonly _defaultWindowProps = new Lazy<Partial<INewWindowProps>>(() => {
		return {
			features: {
				width: 400,
				height: 150,
				left: 0,
				top: 0,
				menubar: false,
				toolbar: false,
				location: false,
				status: false,
				resizable: true,
			},
		};
	});

	private static get defaultWindowProperties(): Partial<INewWindowProps> {
		return this._defaultWindowProps.getValue();
	}

	public static get default(): WindowContextProvider {
		return this._default.getValue();
	}

	public get window(): Window {
		return this.m_window;
	}

	public get opener(): Optional<Window> {
		return this.window.opener;
	}

	public get parent(): Optional<Window> {
		return this.window.parent === this.window ? null : this.window.parent;
	}

	public get children(): ReadonlySet<WindowContextProvider> {
		return this.m_children;
	}
	public get childWindows(): Set<Window | null> {
		return this.m_childWindows;
	}

	public get childWindowSubscriptionsSet(): Set<string> {
		return this.childWindowSubscriptions;
	}

	public get isChildWindow(): boolean {
		//TODO: change this logic when references to opener are removed
		return !!this.opener;
	}

	private get eventEmitter(): EventEmitter {
		return this.m_eventEmitter.getValue();
	}

	private get windowEventHandlers(): IWindowEventListenerMap {
		return this.m_windowEventHandlers.getValue();
	}

	public constructor();
	public constructor(window: Window);
	public constructor(window?: Window) {
		//ensure window is not null
		this.m_window = Nullable.of(window)
			.orElseGetNullable(() => globalThis.window)
			.getOrThrow(() => new Error('window cannot be null or undefined'));

		if (this.m_window === globalThis.window) {
			//ensure window name is not null
			this.m_window.name = this.m_window.name && this.m_window.name.length > 0
				? this.m_window.name
				: (() => {
					const name = `${Math.random()}`;
					let parentName = name;

					try {
						// To try to access to this.opener if it's safe, Same-Origin Policy
						if (this.opener && this.opener.location.origin === this.m_window.location.origin) {
							if (this.opener.hasOwnProperty('name') && this.opener.name) {
								parentName = `${this.opener.name}__${name}`;
							}
						}
					} catch (error) {
						console.error("Error accessing opener:", error);
					}

					return parentName;
				})();

			//attach event handlers to window
			Array.from(this.windowEventHandlers.keys()).forEach((eventName) => {
				Nullable.of(this.windowEventHandlers.get(eventName)).run((handler) => {
					this.window.removeEventListener(eventName, handler);
					this.window.addEventListener(eventName, handler);
				});
			});

			for (const key in childWindowTypeMap) {
				const childWindowType = childWindowTypeMap[key as keyof typeof childWindowTypeMap];
				if (TypeUtils.is<{ url: string }>(childWindowType, (subject) => typeof subject.url !== 'undefined')) {
					if (this.m_window.location.href === childWindowType.url) {
						this.m_type = key as keyof typeof childWindowTypeMap;
						break;
					}
				}
			}

			this.window.addEventListener('stateRequest', (event: any) => {
				if (
					TypeUtils.is<TStateRequestEvent>(
						event,
						(subject) => subject.data && typeof subject.data.callback === 'function'
					)
				) {
					event.data.callback(this.createAppStateManager());
				}
			});
		}
	}

	public requestState(callback: TStateRequestEvent['data']['callback']): void {
		const event: any = this.window.document.createEvent('Event');
		event.initEvent('stateRequest', true, true);
		event.data = { callback: callback };
		this.window.dispatchEvent(event);
	}

	public openChildWindow<K extends keyof IChildWindowTypeMap>(
		type: K,
		windowProps: IChildWindowProps
	): Promise<WindowContextProvider> {
		windowProps.windowName = windowProps.windowName || `${Math.random()}`;
		return PromiseFactory.default.promise((resolve, reject) => {
			try {
				const childWindow = this.window.open(windowProps.url, windowProps.windowName, windowProps.windowFeatures);
				this.m_childWindows.add(childWindow?.window!);
				Nullable.of(childWindow)
					.map((window) => new WindowContextProvider(window))
					.run((childContext) => {
						childContext.once('unload', (sender, event) => this.m_children.delete(sender));
						this.m_children.add(childContext);
						let isResolved = false;
						childContext.once('load', (sender, event) => {
							if (!isResolved) {
								isResolved = true;
								resolve(sender);
							}
						});
						setTimeout(() => {
							if (!isResolved) {
								isResolved = true;
								resolve(childContext);
							}
						}, 2000);
					})
					.orElseRun(() => {
						reject('Unable to create child window');
					});
			} catch (err) {
				reject(err);
			}
		});
	}

	private createAppStateManager(): IAppStateManager {
		return {
			contexts: () => {
				return {
					app:
						Resolver.isSet && Resolver.isRegistered(AppContextProvider)
							? Resolver.resolve(AppContextProvider)
							: AppContextProvider.instance,
					chart:
						Resolver.isSet && Resolver.isRegistered(ChartContextProvider)
							? Resolver.resolve(ChartContextProvider)
							: ChartContextProvider.instance,
					dashboard:
						Resolver.isSet && Resolver.isRegistered(DashboardContextProvider)
							? Resolver.resolve(DashboardContextProvider)
							: DashboardContextProvider.instance,
				};
			},
		};
	}

	public on<K extends keyof IWindowContextEventMap>(event: K, listener: IWindowContextEventMap[K]): this {
		this.eventEmitter.on(event, listener);
		return this;
	}

	public once<K extends keyof IWindowContextEventMap>(event: K, listener: IWindowContextEventMap[K]): this {
		this.eventEmitter.once(event, listener);
		return this;
	}

	public off<K extends keyof IWindowContextEventMap>(event: K, listener: IWindowContextEventMap[K]): this {
		this.eventEmitter.off(event, listener);
		return this;
	}

	private emit<K extends keyof IWindowContextEventMap>(event: K, ...data: Parameters<IWindowContextEventMap[K]>): this {
		try {
			this.eventEmitter.emit(event, ...data);
		} catch (err) {}
		return this;
	}
}

export default createContext<WindowContextProvider>(
	Resolver.isSet && Resolver.isRegistered(WindowContextProvider)
		? Resolver.resolve(WindowContextProvider)
		: WindowContextProvider.default
);
