import React, { useContext } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import { useTranslation } from 'react-i18next';
import {
	GridType,
	stopLossTypes,
	TicketLayout,
	TradingPositionLimitType,
} from '../../../../../../utils/functions/enums';
import { TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import pipStore from '../../../../../../store/PipsStore/pipStore';
import orderStore from '../../../../../../store/OrderStore/orderStore';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import { hidePopup } from '../helpers';
import tradingViewStore from '../../../../../../store/tradingViewStore';

interface LimitProfitLossButtonProps {
	info: any;
	type: string;
	gridType: GridType;
}

const LimitProfitLossButton = ({ info, type, gridType }: LimitProfitLossButtonProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const languageSettings = appContext.languageSettings;
	const isArabic = appContext.isArabic;
	const ticketLayout = tradingViewStore.use.ticketLayout();

	const setTradeProps = orderStore.use.setTradeProps();
	const stopLossPips = pipStore.use.stopLoss();
	const takeProfitPips = pipStore.use.takeProfit();
	const fillStoreFromPosition = orderStore.use.fillStoreFromPosition();
	const tradingPositions = dashboardContext.getTradingPositions();
	const activeTradingAccount = useSelectedTradingAccount();

	const openTicket = (e: any) => {
		hidePopup(isArabic);
		dashboardContext.closeAllOtherTabs();

		const name = e.column.id;
		const record = e.row.original;

		let tradingPositionState = TradingPositionState.open;

		if (gridType === GridType.Order) {
			tradingPositionState = TradingPositionState.pending;
		}

		let limitsType: TradingPositionLimitType[] = [];
		if (name === TradingPositionLimitType.StopLoss) {
			limitsType.push(TradingPositionLimitType.StopLoss);
		} else if (name === TradingPositionLimitType.TakeProfit) {
			limitsType.push(TradingPositionLimitType.TakeProfit);
		} else if (name !== 'NaN' && record.TakeProfit !== 'NaN') {
			limitsType.push(TradingPositionLimitType.TakeProfit, TradingPositionLimitType.StopLoss);
		}

		if (gridType === GridType.Position) {
			fillStoreFromPosition(record.position, limitsType, true, { takeProfitPips, stopLossPips });
			setTradeProps({
				isPreservedValue: true,
			});
		} else if (gridType === GridType.Order) {
			fillStoreFromPosition(record.position, limitsType, true);
		}

		setTradeProps({
			stopLossType:
				!!record?.position?.trailingStop && record?.position?.trailingStop > 0
					? stopLossTypes.Trailing
					: stopLossTypes.Normal,
		});

		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showOrderTicket = true;

			if (name === TradingPositionLimitType.StopLoss || name === TradingPositionLimitType.TakeProfit) {
				dashboardContext.gridButton =
					name === TradingPositionLimitType.StopLoss
						? TradingPositionLimitType.StopLoss
						: TradingPositionLimitType.TakeProfit;
			} else if (name) {
				dashboardContext.gridButton = name;
			} else {
				dashboardContext.gridButton = 'Menu Item';
			}

			if (record) {
				const positions: any = tradingPositions.filter(
					(t) =>
						t.code === record.code &&
						t.state === tradingPositionState &&
						t.aId === activeTradingAccount?.id &&
						(record.isGroupRow || record.posId === t.posId)
				);

				dashboardContext.selectedPosition = positions;
				dashboardContext.selectedInstrument = record.position.marketItem;
			}
		} else {
			dashboardContext.newOrderModalToggle = {
				orderTicket: true,
				confirmOrder: false,
			};

			if (record) {
				const positions = tradingPositions.filter(
					(t: TradingPosition) =>
						t.code === record.code &&
						t.state === tradingPositionState &&
						t.aId === activeTradingAccount?.id &&
						(record.isGroupRow || record.posId === t.posId)
				);

				dashboardContext.selectedPosition = positions;
				dashboardContext.selectedInstrument = record.position.marketItem;
			}

			if (name === TradingPositionLimitType.StopLoss || name === TradingPositionLimitType.TakeProfit) {
				dashboardContext.gridButton =
					name === TradingPositionLimitType.StopLoss
						? TradingPositionLimitType.StopLoss
						: TradingPositionLimitType.TakeProfit;
			} else if (name) {
				dashboardContext.gridButton = name;
			} else {
				dashboardContext.gridButton = 'Menu Item';
			}
		}
	};

	const record = info.row.original;

	const value = type === 'stopLoss' ? record.StopLoss : record.TakeProfit;

	const isEmptyTrailingLoss = !!record?.position?.trailingStop && record?.position?.trailingStop > 0;

	if ((record && record.StopLoss !== 'Head') || record.TakeProfit !== 'Head') {
		return (
			<OverlayTrigger
				delay={{ show: 750, hide: 0 }}
				key={type === 'stopLoss' ? 'stopLossButton' : 'takeProfitButton'}
				placement="top-end"
				overlay={
					<Tooltip className="my-tooltip" id={type === 'stopLoss' ? 'stopLossButton' : 'takeProfitButton'}>
						{type === 'stopLoss'
							? isEmptyTrailingLoss
								? t('en:WTR_SET_TRAILING_STOP_LOSS')
								: record?.StopLoss > 0
								? t('wtr:SET_STOP_LOSS_TOOLTIP')
								: t('wtr:WTR_SET_STOP_LOSS')
							: t('wtr:WTR_SET_TAKE_PROFIT')}{' '}
						{!isNaN(record?.StopLoss) && type === 'stopLoss' && record?.StopLoss}
					</Tooltip>
				}
			>
				<div className={styles.plusContainer}>
					<Button className={styles.plusButton} onClick={() => openTicket(info)}>
						<div className={styles.iconContainer}>
							{isNaN(
								(type === 'stopLoss'
									? record.StopLoss
									: record.TakeProfit || Number.NaN || record?.position?.trailingStop) as number
							) || value == 0 ? (
								<FontAwesomeIcon icon={['fas', 'plus']} size="1x" className={styles.plusTheme} />
							) : (
								<div className={styles.profitValues}>
									{!!record?.position?.trailingStop && record?.position?.trailingStop > 0 && `(${t('wtr:T')})`}{' '}
									{formatNumberWithCommas(
										type === 'stopLoss' ? record.StopLoss : record.TakeProfit,
										record.decPrec ?? 0,
										languageSettings
									)}
								</div>
							)}
						</div>
					</Button>
				</div>
			</OverlayTrigger>
		);
	} else return <></>;
};

export default LimitProfitLossButton;
