import { TradingPosition } from '../../gateways/RfpGateway/rfp.types';
import { create } from 'zustand';
import createSelectors from '../createSelectors';

export type positionsState = {
	positions: Record<string, TradingPosition>;
	panelSize: number;
	setPosition: (position: TradingPosition) => void;
	setWatchListPanelSize: (size: number) => void;
};

const positionsStore = create<positionsState>((set) => ({
	positions: {},
	panelSize: 0,
	setPosition: (position: TradingPosition) => {
		set((state) => ({
			positions: {
				...state.positions,
				[position.posId as number]: position,
			},
		}));
	},
	setWatchListPanelSize: (size: number) => {
		set((state) => ({
			panelSize: size,
		}));
	},
}));

export default createSelectors(positionsStore);
