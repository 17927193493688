import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';

import cn from 'classnames';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Trans } from 'react-i18next';

import ERROR from '../../../gateways/errorMessages';
import { default as RfpGatewayContext } from '../../../contexts/RfpGatewayContext';
import { default as Nullable } from '../../../utils/functions/Nullable';

import forex from '../../../images/forex.svg';
import metals from '../../../images/metals.svg';
import commodities from '../../../images/commodities.svg';
import crypto from '../../../images/crypto.svg';
import energy from '../../../images/energy.svg';
import efts from '../../../images/et-fs.svg';
import stocks from '../../../images/stocks.svg';
import indices from '../../../images/indices.svg';
import risers from '../../../images/risers.svg';
import fallers from '../../../images/fallers.svg';
import mostVolatile from '../../../images/most-volatiles.svg';
import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import { MarketItem, MarketWatchItems } from '../../../gateways/RfpGateway/rfp.types';
import InstrumentContext from '../../../contexts/InstrumentContext';
import useForceRerender from '../../../utils/hooks/useForceRerender';
import usePromiseFactory from '../../../utils/hooks/usePromiseFactory';
import useObservable from '../../../utils/hooks/useObservable';
import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';
import { MarketsGroupName, MarketsGroupPeriod, MarketsGroupTooltips } from '../../../utils/functions/enums';
import selectedSearchCategory from '../../../images/Search-Arrow-thick.svg';
import { tierIcons, tiersKeysMap } from '../../../setup/subscriptionsConfig';
import { getTierDisplayName, getTierNameByNumber } from '../../../utils/functions/subscriptionUtils';
import tradingAccountStore from '../../../store/tradingAccountStore';

import { default as MarketsGrid, IMarketGroup } from './MarketsGrid';
import styles from './Markets.module.scss';
import NewsWidget from './NewsWidget/NewsWidget';

import MarketSignals from './MarketSignals';
import NewsPopup from './NewsWidget/NewsPopup';
import MarketsTable from './MarketsGridNew/MarketsTable';
import authStore from '../../../store/authStore';
import WtrResizable from '../../components/Resizable/WtrResizable';

const Markets = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const marketGroupsRef = useRef<IMarketGroup[]>([]);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const [selectedMarketsGroupName, setSelectedMarketsGroupName] = useState(
		localStorage.getItem('selectedMarketsGroupName')
			? (localStorage.getItem('selectedMarketsGroupName') as MarketsGroupName)
			: isSpreadBettingAccount
			? MarketsGroupName.RisersSB
			: MarketsGroupName.Risers
	);

	const [_, setSidePanelWidth] = useState<number>(0);
	const [newsPanelHeight, setNewsPanelHeight] = useState<number>(0);

	const tt = useShortTranslation('wtr:');

	const rfpConnectionError = dashboardContext.rfpConnectionError;
	const rfpGatewayProvider = dashboardContext.rfpGatewayProvider;

	const selectedNewsContent = dashboardContext.selectedNewsContent;

	useObservable(appContext.getPropertyChangeStream('appTheme', 'isChildWindow'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'inactiveTime',
			'selectedNewsContent',
			'rfpConnectionError',
			'rfpGatewayProvider',
			'tradingAccount'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const instrumentContextProvider = useContext(InstrumentContext);

	useEffect(() => {
		localStorage.setItem('selectedMarketsGroupName', selectedMarketsGroupName);
	}, [selectedMarketsGroupName]);

	// Set default selected market group name (maybe adjust it a little in the future)
	useEffect(() => {
		if (selectedMarketsGroupName.includes('SB') && !isSpreadBettingAccount) {
			setSelectedMarketsGroupName(MarketsGroupName.Risers);
		}

		if (!selectedMarketsGroupName.includes('SB') && isSpreadBettingAccount) {
			setSelectedMarketsGroupName(MarketsGroupName.RisersSB);
		}
	}, [isSpreadBettingAccount]);

	useEffect(() => {
		promiseFactory.throttle('instrumentContextChanged', 100).then(() => {
			if (!rfpGatewayContext) {
				return;
			}

			//get map of instruments, indexed by group
			const instrumentsByGroup = Array.from(instrumentContextProvider.instruments.values()).reduce(
				(map, marketItem) => {
					// TODO rework this, please
					if (isJapanSubscriptionAccount) {
						for (let i = marketItem.minTier!; i <= 3; i++) {
							const grp = `Tier${i}`;
							if (!map.has(grp)) {
								map.set(grp, []);
							}
							map.get(grp)!.push(marketItem);
						}
					} else {
						if (!map.has(marketItem.grp)) {
							map.set(marketItem.grp, []);
						}
						map.get(marketItem.grp)!.push(marketItem);
					}
					return map;
				},
				new Map<string, MarketItem[]>()
			);

			let risersArray = [];
			let risersSBArray = [];
			let fallersArray = [];
			let fallersSBArray = [];
			let mostVolatileArray = [];
			let mostVolatileSBArray = [];

			const marketFilters = sessionStorage.getItem('marketFilters');
			const riserPeriod = marketFilters && JSON.parse(marketFilters)?.Risers?.periodFilter;
			const riserCategory = (
				dashboardContext.marketWatchRisers[
					riserPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (riserCategory) {
				for (let i = 0; i < riserCategory.items?.length; i++) {
					let code = riserCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						risersArray.push({
							...marketItem,
							...riserCategory.items[i],
						});
					}
				}
			}

			const riserSBCategory = (
				dashboardContext.marketWatchRisersSB[
					riserPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (riserSBCategory) {
				for (let i = 0; i < riserSBCategory.items?.length; i++) {
					let code = riserSBCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						risersSBArray.push({
							...marketItem,
							...riserSBCategory.items[i],
						});
					}
				}
			}

			const fallerPeriod = marketFilters && JSON.parse(marketFilters)?.Fallers?.periodFilter;
			const fallerCategory = (
				dashboardContext.marketWatchFallers[
					fallerPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (fallerCategory) {
				for (let i = 0; i < fallerCategory.items?.length; i++) {
					let code = fallerCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						fallersArray.push({
							...marketItem,
							...fallerCategory.items[i],
						});
					}
				}
			}

			const fallerSBCategory = (
				dashboardContext.marketWatchFallersSB[
					fallerPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (fallerSBCategory) {
				for (let i = 0; i < fallerSBCategory.items?.length; i++) {
					let code = fallerSBCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						fallersSBArray.push({
							...marketItem,
							...fallerSBCategory.items[i],
						});
					}
				}
			}

			const mvPeriod = marketFilters && JSON.parse(marketFilters)?.[MarketsGroupName.MostVolatile]?.periodFilter;
			const mvCategory = (
				dashboardContext.marketWatchMostVolatile[
					mvPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (mvCategory) {
				for (let i = 0; i < mvCategory.items?.length; i++) {
					let code = mvCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						mostVolatileArray.push({
							...marketItem,
							...mvCategory.items[i],
						});
					}
				}
			}

			const mvSBCategory = (
				dashboardContext.marketWatchMostVolatileSB[
					mvPeriod === MarketsGroupPeriod.Weekly ? 'weekly' : 'daily'
				] as MarketWatchItems[]
			).find((marketWatch) => marketWatch.category === 'All');
			if (mvSBCategory) {
				for (let i = 0; i < mvSBCategory.items?.length; i++) {
					let code = mvSBCategory.items[i].code;
					if (code) {
						let marketItem: any = rfpGatewayContext.getMarketItem(code);
						mostVolatileSBArray.push({
							...marketItem,
							...mvSBCategory.items[i],
						});
					}
				}
			}

			//create market groups. dynamic groups are above the sorted constant groups
			const dynamicGroups: IMarketGroup[] = [
				{ name: MarketsGroupName.Risers, label: tt('RISERS'), icon: risers, instruments: [...risersArray] },
				{ name: MarketsGroupName.Fallers, label: tt('FALLERS'), icon: fallers, instruments: [...fallersArray] },
				{
					name: MarketsGroupName.MostVolatile,
					label: tt('MOST_VOLATILE'),
					icon: mostVolatile,
					instruments: [...mostVolatileArray],
				},
			];

			const dynamicSBGroups: IMarketGroup[] = [
				{ name: MarketsGroupName.RisersSB, label: tt('RISERS'), icon: risers, instruments: [...risersSBArray] },
				{ name: MarketsGroupName.FallersSB, label: tt('FALLERS'), icon: fallers, instruments: [...fallersSBArray] },
				{
					name: MarketsGroupName.MostVolatileSB,
					label: tt('MOST_VOLATILE'),
					icon: mostVolatile,
					instruments: [...mostVolatileSBArray],
				},
			];

			const constantGroups: IMarketGroup[] = isJapanSubscriptionAccount
				? [
						{ name: 'Tier1', label: getTierDisplayName('Tier1'), icon: tierIcons['tier1'], instruments: [] },
						{ name: 'Tier2', label: getTierDisplayName('Tier2'), icon: tierIcons['tier2'], instruments: [] },
						{ name: 'Tier3', label: getTierDisplayName('Tier3'), icon: tierIcons['tier3'], instruments: [] },
				  ]
				: [
						{ name: 'Commodities', label: tt('WTR_COMMODITIES'), icon: commodities, instruments: [] },
						{ name: 'Crypto', label: tt('CRYPTO'), icon: crypto, instruments: [] },
						{ name: 'Energy', label: tt('ENERGY'), icon: energy, instruments: [] },
						{ name: 'ETF', label: tt('ETFS'), icon: efts, instruments: [] },
						{ name: 'Forex', label: tt('WTR_FOREX'), icon: forex, instruments: [] },
						{ name: 'Indices', label: tt('WTR_INDICES'), icon: indices, instruments: [] },
						{ name: 'Metals', label: tt('METALS'), icon: metals, instruments: [] },
						{ name: 'Equities', label: tt('SHARE_CFDS'), icon: stocks, instruments: [] },
				  ].sort((groupA, groupB) => groupA.label.localeCompare(groupB.label));

			const spreadBettingGroups = [
				{ name: 'CryptoSB', label: tt('CRYPTO'), icon: crypto, instruments: [] },
				{ name: 'EnergySB', label: tt('ENERGY'), icon: energy, instruments: [] },
				{ name: 'ForexSB', label: tt('WTR_FOREX'), icon: forex, instruments: [] },
				{ name: 'IndicesSB', label: tt('WTR_INDICES'), icon: indices, instruments: [] },
				{ name: 'MetalsSB', label: tt('METALS'), icon: metals, instruments: [] },
			];
			const marketGroups: IMarketGroup[] = isSpreadBettingAccount
				? [...dynamicSBGroups, ...spreadBettingGroups]
				: [...dynamicGroups, ...constantGroups];

			//add instruments to market groups
			marketGroups.forEach((marketGroup) => {
				Nullable.of(instrumentsByGroup.get(marketGroup.name)).run((instruments) => {
					instruments = instruments.sort((val1, val2) => val1.code.localeCompare(val2.code));
					Array.prototype.push.apply(marketGroup.instruments, instruments);
				});
			});
			//filter group if it doesn`t have instruments
			const marketGroupsFiltered = marketGroups.filter((group: IMarketGroup) => {
				if (group.instruments.length > 0) {
					return group;
				}
			});

			//set market groups ref
			marketGroupsRef.current = marketGroupsFiltered;
			forceRerender();
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dashboardContext.marketItems,
		instrumentContextProvider.instruments,
		instrumentContextProvider.watchlists,
		isSpreadBettingAccount,
		isJapanSubscriptionAccount,
	]);

	useEffect(() => {
		if (rfpConnectionError && rfpConnectionError.headers) {
			dashboardContext.notification = {
				isActive: true,
				message: ERROR.RFP_TRADING_ACCOUNT,
				type: 'error',
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rfpConnectionError]);

	function isValidEnumValue(value: string): value is keyof typeof MarketsGroupTooltips {
		return value in MarketsGroupTooltips;
	}

	const marketTabs = useMemo(
		() =>
			marketGroupsRef.current.map((marketGroup) => {
				const isSelected = selectedMarketsGroupName === marketGroup.name;
				const tierNumber = Object.keys(tiersKeysMap).includes(marketGroup.name)
					? parseInt(marketGroup.name.charAt(marketGroup.name.length - 1))
					: null;

				let translationKey;

				if (isValidEnumValue(marketGroup.name)) {
					translationKey = MarketsGroupTooltips[marketGroup.name];
				}

				return (
					<div className={styles.categoryGroupContainer} key={marketGroup.name}>
						<OverlayTrigger
							key="timeframes"
							delay={{ show: 750, hide: 0 }}
							placement="top"
							overlay={
								<Tooltip id="marketCategoriesTierInfo" className="my-tooltip">
									{!tierNumber ? <Trans i18nKey={`wtr:${translationKey}`} /> : getTierNameByNumber(tierNumber)}
								</Tooltip>
							}
						>
							<label
								key={marketGroup.name}
								className={cn(isSelected ? styles.positionType : styles.positionTypeOption)}
								onClick={() => setSelectedMarketsGroupName(marketGroup.name as MarketsGroupName)}
							>
								{tierNumber ? (
									<img
										className={cn(styles.icon, styles[`icon${marketGroup.name}`], isSelected ? styles.active : '')}
										src={marketGroup.icon}
										alt={marketGroup.label}
									/>
								) : (
									<img
										className={cn(styles.icon, styles[`icon${marketGroup.name}`], isSelected ? styles.active : '')}
										src={marketGroup.icon}
										alt={marketGroup.label}
									/>
								)}

								<span className={styles.labelText}>
									{marketGroup.label}&nbsp;(
									{/*{['Risers', 'Fallers', 'Most Volatile'].includes(marketGroup.name)*/}
									{/*  ? '30'*/}
									{/*  : marketGroup.instruments.length}*/}
									{marketGroup.instruments.length})
								</span>
							</label>
						</OverlayTrigger>

						<img
							alt=""
							className={isSelected ? styles.selectedSearchCategory : styles.hideSelectedSearchCategory}
							src={selectedSearchCategory}
						/>
					</div>
				);
			}),
		[marketGroupsRef.current[3]?.instruments, selectedMarketsGroupName, dashboardContext.marketItems]
	);

	return (
		<RfpGatewayContext.Provider value={rfpGatewayProvider ? rfpGatewayProvider() : null}>
			<div className={styles.container}>
				<div className={styles.marketsWrapper}>
					<div className={styles.inline}>
						<WtrResizable
							panelName="marketsGroups"
							defaultSize={{ width: 190, height: '100%' }}
							setSidePanelWidth={setSidePanelWidth}
						>
							<div className={styles.marketTabs}>{marketTabs}</div>
						</WtrResizable>

						<MarketsTable
							selectedCategory={selectedMarketsGroupName}
							translatedMarketGroups={marketGroupsRef.current}
							newsPanelHeight={newsPanelHeight}
						/>
					</div>
				</div>
				<WtrResizable panelName="marketsNews" setNewsPanelHeight={setNewsPanelHeight}>
					{appContext.isJapanAccount ? (
						<div className={cn(styles.jpNews)}>
							<NewsWidget withDetails />
						</div>
					) : (
						<div className={cn(styles.newsAndSignals)}>
							<div className={styles.newsOrSignalsColumn}>
								<NewsWidget />
							</div>
							<div className={styles.newsOrSignalsColumn}>
								<MarketSignals />
							</div>
						</div>
					)}
				</WtrResizable>
			</div>
			{selectedNewsContent !== -1 && !appContext.isJapanAccount && <NewsPopup />}
		</RfpGatewayContext.Provider>
	);
};

export default Markets;
