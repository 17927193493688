import React, { useContext, useEffect, useState } from 'react';
import { MarketItem } from '../../../gateways/RfpGateway/rfp.types';
import GroupBadge from './GroupBadge';
import tradingAccountStore from '../../../store/tradingAccountStore';
import { IGridRecord } from '../../features/Markets/MarketsGrid';

interface InstrumentIcon {
	marketItem: IGridRecord | MarketItem;
}
const InstrumentIcon = ({ marketItem }: InstrumentIcon) => {
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	const [iconSrc, setIconSrc] = useState('');
	const [isRendered, setIsRendered] = useState(false);
	let group = (isJapanSubscriptionAccount ? marketItem.minTier : marketItem.grp) || marketItem.category;
	let name = marketItem.displayName || marketItem.code;

	if (name.endsWith('_SB')) {
		name = name.replace('_SB', '');
	}
	if (group && typeof group === 'string' && group.endsWith('SB')) {
		group = group.replace('SB', '');
	}

	useEffect(() => {
		const loadIcon = async () => {
			try {
				const iconModule = await import(`../../../images/${group}/${name}.svg`);
				setIconSrc(iconModule.default);
				setIsRendered(true);
			} catch (error) {
				setIconSrc('');
				setIsRendered(true);
			}
		};

		loadIcon();
	}, [name, group]);

	return <>{isRendered && (iconSrc ? <img src={iconSrc} alt="badge" /> : <GroupBadge groupName={group} />)}</>;
};

export default InstrumentIcon;
