import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../../../contexts/DashboardContext';

import useShortTranslation from '../../../../../../../../utils/hooks/useShortTranslation';

import useObservable from '../../../../../../../../utils/hooks/useObservable';
import useForceRerender from '../../../../../../../../utils/hooks/useForceRerender';

import WtrTooltip from '../../../../../../../../shared/WtrTooltip/WtrTooltip';

import MarginRequirementsContext from '../../../../../../../../contexts/MarginRequirementsContext';

import RequirementLine from './RequirementLine';

import styles from './MarginRequirements.module.scss';

interface IMarginRequirementsProps {
	children: React.ReactNode;
}

const MarginRequirements = ({children}: IMarginRequirementsProps) => {
	const tt = useShortTranslation('en:');
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const marginRequirementsContext = useContext(MarginRequirementsContext);
	const languageSettings = appContext.languageSettings;

	const forceRerender = useForceRerender();

	// Need to use it because the changes on DashboardContext doesn't trigger a render
	useObservable(dashboardContext.getPropertyChangeStream('quantityType'), () => {
		forceRerender();
	});

	if (!marginRequirementsContext?.doesRulesApply && !marginRequirementsContext?.marginRequirementsToApply) {
		return <>{children}</>;
	}

	const lots = marginRequirementsContext?.marginRequirementsToApply?.tierValues?.split(',') || [];
	const lastLotsIndex = lots?.length - 1;
	const margins = marginRequirementsContext?.marginRequirementsToApply?.tierRequirements?.split(',') || [];

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{tt('MARGIN_REQUIREMENTS')}</div>
			<div className={styles.header}>
				<span>{dashboardContext.quantityType === 'Lots' ? tt('QT_LOTS') : tt('QT_AMOUNTS')}</span>
				<span>
					<WtrTooltip content={tt('MARGIN_REQUIREMENTS_MARKET_VOLATILITY')} position="left center">
						<FontAwesomeIcon className={styles.infoTooltip} icon={['fas', 'circle-info']} />
					</WtrTooltip>
					{tt('MARGIN')}
				</span>
			</div>
			{margins.map((margin: string, i: number) => {
				const marginValue = parseFloat(margin);
				const key = `__${margin}_${i}`;
				const rangeMin = parseFloat(i === 0 ? '0' : lots[i - 1]);
				const rangeMax = parseFloat(lots[i]);

				return (
					<RequirementLine
						key={key}
						rangeMin={rangeMin}
						rangeMax={rangeMax}
						isLastRange={i === lastLotsIndex}
						value={marginValue}
						languageSettings={languageSettings}
					/>
				);
			})}
		</div>
	);
};

export default MarginRequirements;
