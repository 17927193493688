import React, { FC, CSSProperties } from 'react';
import cn from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, flexRender, Table } from '@tanstack/react-table';

import {
	PositionTableItem,
	TableMarketItem,
} from '../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';

import styles from '../MarketsTable.module.scss';

interface DraggableTableHeaderProps {
	header: Header<any, unknown>;
	table: Table<any>;
}

const DraggableTableHeader: FC<DraggableTableHeaderProps> = ({ header, table }) => {
    const { attributes, isDragging, listeners, transform } = useSortable({
      id: header.column.id
    });

    const style: CSSProperties = {
      opacity: isDragging ? 0.8 : 1,
      position: 'relative',
      transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
      transition: 'width transform 0.2s ease-in-out',
      whiteSpace: 'nowrap',
      width: header.column.getSize(),
      zIndex: isDragging ? 1 : 0
    };

	if (header.column.id === 'settings' || header.column.id === 'close') {
		return null;
	}

    const columnSizingHandler = (thElem: HTMLTableCellElement | null, table: any, column: any) => {
      if (!thElem) {
        return;
      }

      // If you don't do that, there will be an infinite loop. We update the value in state only if the value has actually changed.
      if (Math.round(table.getState().columnSizing[column.id]) === Math.round(thElem.getBoundingClientRect().width)) {
        return;
      }

      table.setColumnSizing((prevSizes: any) => ({
        ...prevSizes,
        // 100% accurate float-point width, even if table content is loaded async
        [column.id]: thElem.getBoundingClientRect().width
      }));
    };

    return (
      <th
        {...{
          key: header.id,
          ref: (thElem) => columnSizingHandler(thElem, table, header.column),
          colSpan: header.colSpan,
          className: cn(styles.tableHeader),
          style: {
            ...style
          }
        }}
      >
        <div className={cn(styles.headerWrapper)}>
          {header.isPlaceholder ? null : (
            <div style={{ userSelect: 'none' }} onClick={header.column.getToggleSortingHandler()}>
              {flexRender(header.column.columnDef.header, header.getContext())}
              &nbsp;
              {{
                asc: ' ↑',
                desc: ' ↓'
              }[header.column.getIsSorted() as string] ?? null}
            </div>
          )}

          <span className={cn(styles.dragColumnButton)} {...attributes} {...listeners}>
					<FontAwesomeIcon icon={['fas', 'grip-dots-vertical']} style={{ width: '18px', height: '18px' }}/>
				</span>
        </div>

        <div
          {...{
            onDoubleClick: () => header.column.resetSize(),
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `${styles.resizer} ${table.options.columnResizeDirection === 'ltr' ? styles.ltr : styles.rtl} ${
              header.column.getIsResizing() && styles.isResizing
            }`
          }}
        />
      </th>
    );
  }
;

export default DraggableTableHeader;
