import moment from 'moment';

import {
	TfboApiMethodMeta,
	TradingAccountType as PlatformAccountType,
} from '../../gateways/TfboGateway/TfboGateway.types';
import i18n from '../../setup/i18n';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import { AppContextProvider } from '../../contexts/AppContext';
import {
	GENERAL_DATETIMES_FORMAT,
	GENERAL_DATE_FORMAT,
	JAPAN_DATETIMES_FORMAT,
	JAPAN_DATE_FORMAT,
} from '../../setup/config';
import { GLOBAL_MAX_ORDER_SIZE, tiers, tiersKeysMap } from '../../setup/subscriptionsConfig';
import { formatNumberWithCommas } from '../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import { Optional } from './Nullable';
import { Resolver } from './Ioc';

// Japan subscription statuses
export enum SubscriptionStatus {
	'Active' = 'ACTIVE',
	'NoRecord' = 'NORECORD',
	'Existing' = 'EXISTING',
	'Stopped' = 'STOPPED',
	'Terminated' = 'TERMINATED',
	'Unpaid' = 'UNPAID',
	'Cancelled' = 'CANCELED',
	'Suspended' = 'SUSPENDED',
	'Incomplete' = 'INCOMPLETE',
}

// Japan subscription tiers
export type SubscriptionTier = 'Tier0' | 'Tier1' | 'Tier2' | 'Tier3';

// export enum changeTierResponse {
// 	'Changed' = 'CHANGED',
// 	'NotApplicable' = 'NOTAPPLICABLE',
// 	'NotAllowed' = 'NOTALLOWED',
// 	'NoRecord' = 'NORECORD',
// }

export enum SubscriptionModalReason {
	Instrument = 'InstrumentNotAllowed',
	Volume = 'VolumeExceeded',
	OrderSizeUpgradable = 'OrderSizeInvalidUntilUpgrade',
	OrderSizeGlobal = 'OrderSizeGlobalExceeded',
	SubscriptionForNewUser = 'SubscriptionForNewUserNeeded',
	SubscriptionForExistingUser = 'SubscriptionForExistingUserNeeded',
	SubscriptionForUnpaidUser = 'SubscriptionForUnpaidUserNeeded',
	Renewal = 'RenewalNeeded',
	Cancelled = 'CancelledOrSuspended',
	Terminated = 'SubscriptionTerminated',
	SubscribeResponse = 'SubscribeResponseRecevied',
	ManageSubscription = 'ManageSubscriptionRequest',
	UpgradeSubscription = 'UpgradeSubscriptionRequest',
	// ManageSubscriptionConfirm = 'ManageSubscriptionConfirmation',
	// UpgradeSubscriptionConfirm = 'UpgradeSubscriptionConfirmation',
	ConfirmStop = 'ConfirmationForSubscriptionStopNeeded',
	ConfirmRestart = 'ConfirmationForSubscriptionResumNeeded',
	ConfirmCancel = 'ConfirmationForSubscriptionCancelNeeded',
	ChangeTierResponse = 'ChangeTierResponseReceived',
	StopSubscriptionResponse = 'StopSubscriptionResponseReceived',
	CancelSubscriptionResponse = 'CancelSubscriptionResponseReceived',
	RestartSubscriptionResponse = 'RestartSubscriptionResponse',
	SubscriptionDetails = 'SubscriptionDetailsDisplayed',
	NoSuperiorTiers = 'NoSuperiorTiersAvaiableForThisUser',
	SubscribeFromAlert = 'SubscribeFromAlert',
	SystemError = 'BackendOperationSysError',
	OrderSizeHighestTier = 'OrderSizeExceededForHighestTier',
	NotAuthorized = 'BackendReturnedResponseNotAuthorized',
	IncorrectParamsForBJP = 'BackendSentIncorrectParamsForRequestToBJP',
	GymNotAccessibleForTier = 'TierNotAllowedToAccessTradersGym',
	UpgradeToAccessGym = 'UpgradeNeededToAccessTradersGym',
	JapanAccountEmptyApplicationStatus = 'JapanAccountEmptyApplicationStatus',
}

export enum UserOperationType {
	'CLOSE' = 'CanClosePositions',
	'CANCEL' = 'CanCancelOrders',
}

export type SubscriptionModalState = {
	isOpen: boolean;
	reason: SubscriptionModalReason | null;
	text?: string | null;
	instrument?: MarketItem | null;
};

export type SubscriptionInfo = {
	status: SubscriptionStatus | null;
	tier: SubscriptionTier | null;
	tierNum: number | null;
	nextTier: SubscriptionTier | null;
	enabled: boolean | null;
	TTTier: number | null;
	expiryDate: string | null;
	startDate: string | null;
	isDemoUnlimited: boolean | null;
	maxVolume: number | null;
	usedVolume: number | null;
	remainingVolume: number | null;
};

// export type SubscriptionInfoProp = {
// 	key: keyof SubscriptionInfo;
// 	value: any;
// };
//
// export type SubscribeStatusObj = {
// 	status: string;
// 	providerStatus?: string;
// };

export type SubscriptionCheckStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';

export interface Tier {
	id: SubscriptionTier;
	key: string;
	pricePerMonth: number;
	currencyPairs: string;
	minPerOrder: number;
	maxPerOrder: number;
	monthlyAmount: string;
	maxPosition: number;
	instruments: string[];
	openInterestLimit?: any;
	maxOpenInstrumentPosition?: {
		[key: string]: number;
	};
}

export const defaultSubscriptionModalState = {
	isOpen: false,
	reason: null,
	text: null,
	instrument: null,
};

export const defaultSubscriptionInfo: SubscriptionInfo = {
	status: null,
	tier: null,
	tierNum: null,
	nextTier: null,
	enabled: null,
	TTTier: null,
	expiryDate: null,
	startDate: null,
	isDemoUnlimited: null,
	maxVolume: null,
	usedVolume: null,
	remainingVolume: null,
};

//get app context provider

const sendRequest = (
	requestObj: { method: keyof typeof TfboApiMethodMeta; payloadParameters: any },
	onSuccess?: ((response: any) => void) | null,
	onError?: ((error: any) => void) | null
) => {};

export const useSubscribe = ({
	accountId,
	provider,
	tier,
	source,
}: {
	accountId: number;
	provider: string;
	tier: SubscriptionTier;
	source: string;
}) => {
	const request = {
		method: 'subscriptions.subscribe' as keyof typeof TfboApiMethodMeta,
		payloadParameters: {
			provider: provider,
			accountNumber: accountId,
			tier: tier,
			source: source,
		},
	};

	const onSuccess = (response: any) => {};

	const onError = (error: any) => {
		const message = error instanceof Error ? error.message : String(error);
		console.debug('Error requesting initial subscription from TFBO gateway.  Exception: ', message);
	};

	sendRequest(request, onSuccess, onError);
};

// export const getInstrumentTiers = (instrCode: string) => {};

export const getInstrumentMinTier = (instrumentCode: string | undefined, instrumentList: MarketItem[]) => {
	if (!instrumentCode) {
		return null;
	}

	return instrumentList.find((instrument) => instrument.code === instrumentCode)?.minTier;
};

export const getTierNumFromName = (tier: SubscriptionTier) => {
	return parseInt(tier?.charAt(tier.length - 1) || '', 10);
};

export const getFormattedDateHelper = (datetime: Date | number | null | undefined, formatString: string) => {
	const globalLocale = moment.locale();
	moment.locale('en');
	const modifiedMoment = datetime ? () => moment(datetime) : moment;
	const formattedDate = modifiedMoment().format(formatString);
	moment.locale(globalLocale);
	return formattedDate;
};

export const getGeneralFormatDate = (
	date: Date | number | null | undefined,
	includeHM: boolean = false,
	isJapanFormat: boolean = false
) => {
	let formatString = null;
	if (isJapanFormat) {
		formatString = includeHM ? JAPAN_DATETIMES_FORMAT : JAPAN_DATE_FORMAT;
	} else {
		formatString = includeHM ? GENERAL_DATETIMES_FORMAT : GENERAL_DATE_FORMAT;
	}

	return getFormattedDateHelper(date, formatString);
};

const getSubscriptionExpiryDate = (includeHM: boolean = false, isJapanFormat: boolean = false) => {
	const endOfMonth = moment().endOf('month');
	const datetime = endOfMonth.unix() * 1000;

	return getGeneralFormatDate(datetime, includeHM, isJapanFormat);
};

const getSubscriptionStartDate = (includeHM: boolean = false, isJapanFormat: boolean = false) => {
	const startOfMonth = moment().startOf('month');
	const datetime = startOfMonth.unix() * 1000;

	return getGeneralFormatDate(datetime, includeHM, isJapanFormat);
};

// export const checkUserHasUnlimitedDemo = () => true;

export const checkIsInstrumentAllowed = (
	instrumentCode: string,
	currentTierNum: number | null | undefined,
	instrumentList: MarketItem[]
) => {
	const instrumentMinTier = getInstrumentMinTier(instrumentCode, instrumentList);
	if (!currentTierNum || !instrumentMinTier) {
		return true;
	}

	return currentTierNum >= instrumentMinTier;
};

export const checkIsVolumeUnlimited = (subscriptionInfo: SubscriptionInfo | null) => {
	if (!subscriptionInfo) {
		return false;
	}

	return subscriptionInfo.maxVolume === -1;
};

export const checkIsVolumeExceeded = (subscriptionInfo: SubscriptionInfo | null) => {
	if (!subscriptionInfo) {
		return false;
	}

	if (subscriptionInfo?.tierNum ?? 0 > 1) {
		return false;
	}

	if (subscriptionInfo?.remainingVolume === null) {
		return false;
	}

	return subscriptionInfo.remainingVolume === 0;
};

export const checkIsTierOrderSizeExceeded = (orderSize: number, subscriptionInfo: SubscriptionInfo | null) => {
	if (!subscriptionInfo) {
		return false;
	}

	const currentTier = subscriptionInfo.tier;
	const orderMaxLimit = tiers.find((tier) => tier.id === currentTier)?.maxPerOrder;
	if (orderMaxLimit) {
		return orderSize > orderMaxLimit;
	}

	return false;
};

export const checkIsGlobalOrderSizeExceeded = (orderSize: number) => {
	if (!orderSize || isNaN(orderSize)) {
		return false;
	}

	return GLOBAL_MAX_ORDER_SIZE && !isNaN(GLOBAL_MAX_ORDER_SIZE) ? orderSize > GLOBAL_MAX_ORDER_SIZE : false;
};

export const checkHasSubscriptionOptions = (subscriptionStatus: SubscriptionStatus | null) => {
	if (!subscriptionStatus) return false;

	return [SubscriptionStatus.Active, SubscriptionStatus.Unpaid, SubscriptionStatus.Stopped].includes(
		subscriptionStatus.toUpperCase() as SubscriptionStatus
	);
};

export const checkIsOperationGranted = (
	subscriptionStatus: SubscriptionStatus | null | undefined,
	operation: UserOperationType
) => {
	if (!subscriptionStatus || !operation) return false;

	switch (operation) {
		case UserOperationType.CLOSE:
			if ((SubscriptionStatus.Existing, SubscriptionStatus.Unpaid).includes(subscriptionStatus)) {
				return true;
			}
			break;
		case UserOperationType.CANCEL:
			if ((SubscriptionStatus.Existing, SubscriptionStatus.Unpaid).includes(subscriptionStatus)) {
				return true;
			}
			break;
		default:
	}

	return false;
};

export const getSubscriptionConstraints = (
	subscriptionInfo: SubscriptionInfo | null,
	instrumentCode: string | null,
	marketItems: MarketItem[],
	operation?: UserOperationType
) => {
	const statusBasedReason = getInitModalPropsByStatus(subscriptionInfo?.status || null)?.reason;
	if (statusBasedReason) {
		const isOperationGranted = operation ? checkIsOperationGranted(subscriptionInfo?.status, operation) : false;

		if (isOperationGranted) {
			return null;
		}

		return statusBasedReason;
	}

	if (instrumentCode) {
		if (
			operation !== UserOperationType.CLOSE &&
			operation !== UserOperationType.CANCEL &&
			!checkIsInstrumentAllowed(instrumentCode, subscriptionInfo?.tierNum, marketItems)
		) {
			return SubscriptionModalReason.Instrument;
		}
	}

	return null;
};

export const canSeeSubscriptionDetails = (subscriptionStatus: SubscriptionStatus | null) => {
	if (!subscriptionStatus) return false;
	return [SubscriptionStatus.Active, SubscriptionStatus.Unpaid, SubscriptionStatus.Existing].includes(
		subscriptionStatus
	);
};

// export const checkUserHasAccess = (subscriptionStatus: SubscriptionStatus) =>
// 	[
// 		SubscriptionStatus.Active,
// 		// SubscriptionStatus.NoRecord,
// 		SubscriptionStatus.Incomplete,
// 		SubscriptionStatus.Existing,
// 		// SubscriptionStatus.Stopped,
// 		SubscriptionStatus.Unpaid,
// 	].includes(subscriptionStatus.toUpperCase() as SubscriptionStatus);

// export const checkUserNeedsInitialSubscription = (subscriptionStatus: SubscriptionStatus) =>
// 	subscriptionStatus !== SubscriptionStatus.Active;
// [SubscriptionStatus.NoRecord, SubscriptionStatus.Existing, SubscriptionStatus.Incomplete].includes(
// 	subscriptionStatus.toUpperCase() as SubscriptionStatus
// );

export const checkMustShowTierSelector = (
	subscriptionStatus: SubscriptionStatus | null,
	modalReason: SubscriptionModalReason | null
) => {
	if (!subscriptionStatus || !modalReason) {
		return false;
	}

	return (
		// checkUserHasAccess(subscriptionStatus) &&
		[
			SubscriptionModalReason.SubscriptionForExistingUser,
			SubscriptionModalReason.ManageSubscription,
			SubscriptionModalReason.UpgradeSubscription,
			SubscriptionModalReason.Instrument,
			SubscriptionModalReason.Volume,
			SubscriptionModalReason.OrderSizeUpgradable,
			SubscriptionModalReason.SubscribeFromAlert,
			SubscriptionModalReason.UpgradeToAccessGym,
		].includes(modalReason)
	);
};

export const checkMustShowAlert = (
	subscriptionStatus: SubscriptionStatus | null,
	modalReason: SubscriptionModalReason | null
) => {
	if (!subscriptionStatus || !modalReason) {
		return false;
	}

	return (
		// !checkUserHasAccess(subscriptionStatus) ||
		[
			SubscriptionModalReason.SubscriptionForNewUser,
			SubscriptionModalReason.SubscriptionForUnpaidUser,
			SubscriptionModalReason.Renewal,
			SubscriptionModalReason.Cancelled,
			SubscriptionModalReason.Terminated,
			SubscriptionModalReason.ChangeTierResponse,
			SubscriptionModalReason.SubscribeResponse,
			SubscriptionModalReason.ConfirmStop,
			SubscriptionModalReason.ConfirmCancel,
			SubscriptionModalReason.ConfirmRestart,
			SubscriptionModalReason.SubscriptionDetails,
			SubscriptionModalReason.CancelSubscriptionResponse,
			SubscriptionModalReason.StopSubscriptionResponse,
			SubscriptionModalReason.RestartSubscriptionResponse,
			SubscriptionModalReason.NoSuperiorTiers,
			SubscriptionModalReason.SystemError,
			SubscriptionModalReason.OrderSizeGlobal,
			SubscriptionModalReason.NotAuthorized,
			SubscriptionModalReason.IncorrectParamsForBJP,
			SubscriptionModalReason.GymNotAccessibleForTier,
			SubscriptionModalReason.JapanAccountEmptyApplicationStatus,
		].includes(modalReason)
	);
};

export const getInitModalPropsByStatus = (
	subscriptionStatus: SubscriptionStatus | null
): Partial<SubscriptionModalState> | null => {
	// switch (subscriptionStatus?.toUpperCase()) {
	const statusToReasonMap: { [key: string]: SubscriptionModalReason } = {
		[SubscriptionStatus.Terminated]: SubscriptionModalReason.Terminated,
		[SubscriptionStatus.Suspended]: SubscriptionModalReason.Cancelled,
		[SubscriptionStatus.Cancelled]: SubscriptionModalReason.Cancelled,
		[SubscriptionStatus.NoRecord]: SubscriptionModalReason.SubscriptionForNewUser,
		[SubscriptionStatus.Incomplete]: SubscriptionModalReason.SubscriptionForNewUser,
		[SubscriptionStatus.Stopped]: SubscriptionModalReason.Renewal,
		[SubscriptionStatus.Unpaid]: SubscriptionModalReason.SubscriptionForUnpaidUser,
		[SubscriptionStatus.Existing]: SubscriptionModalReason.SubscriptionForExistingUser,
	};

	const reason = subscriptionStatus ? statusToReasonMap[subscriptionStatus.toUpperCase()] : null;
	if (reason) {
		return {
			reason,
			isOpen: true,
			text: null,
		};
	}

	return null;
};

export const displayVolumeInfo = (amount: Optional<string | number>) => {
	const appContextProvider = Resolver.resolve(AppContextProvider);
	if (typeof amount === 'number') {
		if (amount === -1) {
			return i18n.t('wtr:UNLIMITED');
		}

		return formatNumberWithCommas(amount, 0, appContextProvider.languageSettings);
	}

	return '-';
};

export const getUpdatedSubscrInfoObject = (
	updateProps: Partial<SubscriptionInfo>,
	infoObject: SubscriptionInfo | null
) => {
	const currentInfoObj = infoObject || defaultSubscriptionInfo;
	return { ...currentInfoObj, ...updateProps };
};

export const getStatusRelatedSubscrInfoUpdate = (
	subscriptionStatus: SubscriptionStatus,
	infoObject: SubscriptionInfo | null
): Partial<SubscriptionInfo> => {
	const status = subscriptionStatus ? (subscriptionStatus.toUpperCase() as SubscriptionStatus) : null;

	const updateProps: Partial<SubscriptionInfo> = {
		status,
		expiryDate: subscriptionStatus === SubscriptionStatus.Active ? getSubscriptionExpiryDate(false, false) : null,
		startDate: subscriptionStatus === SubscriptionStatus.Active ? getSubscriptionStartDate(false, false) : null,
	};

	return {
		...updateProps,
	};
};

export const getActiveTier = (infoObject: SubscriptionInfo | null) => {
	if (infoObject?.status === SubscriptionStatus.Active && infoObject?.tier?.toLowerCase() !== 'tier0') {
		return infoObject.tier;
	}

	return null;
};

export const getNextMonthTier = (infoObject: SubscriptionInfo | null) => {
	if (infoObject?.status === SubscriptionStatus.Active) {
		return infoObject.nextTier;
	}

	return null;
};

export const getTierDisplayName = (tierId: string | null) => {
	if (!tierId) {
		return undefined;
	}

	const tierKey = tiersKeysMap[tierId];
	if (!tierKey) {
		return undefined;
	}

	return i18n.t(`wtr:${tierKey}_NAME`) + ' • ' + i18n.t(`wtr:${tierKey}`);
};

export const getTierNameByNumber = (tierNumber: number) => {
	const tierKey = tiersKeysMap[`Tier${tierNumber}`];
	if (!tierKey) {
		return '';
	}

	return i18n.t(`wtr:${tierKey}_NAME`);
};

export const getMinReqTierDisplayText = (tierNumber: number) => {
	return i18n.t(`wtr:MINIMUM_REQUIRED_TIER`) + ': ' + getTierNameByNumber(tierNumber);
};

export const getAnyLiveUserAccount = (appContext: AppContextProvider) => {
	const liveAccountForDemo = appContext.accountStats?.find(({ account }) => account.type === 'LIVE');
	return liveAccountForDemo?.account.accountNumber.toString();
};

export const checkHasUserDemoAccount = (appContext: AppContextProvider) =>
	!!appContext.accountStats?.find(({ account }) => account.type === 'DEMO');

export const getJPAcctIdForTierInfoRequest = (appContext: AppContextProvider) => {
	if (appContext.isJapanAccount && appContext.accountStats?.length) {
		const liveJapanSubscriptionAccount = appContext.accountStats?.find(
			({ account, platformAccountType }) =>
				account.type === 'LIVE' && platformAccountType === PlatformAccountType.ThinkTrader
		);
		return liveJapanSubscriptionAccount?.account.accountNumber.toString();
	}
	return null;
};

// export const getCurrentAccountId = (accountContext: DashboardContextProvider) => {
// 	if (accountContext.activeTradingAccount.length > 0) {
// 		return accountContext.activeTradingAccount[0].providerAccountId;
// 	} else if (accountContext.tradingAccount.length > 0) {
// 		return accountContext.tradingAccount[0].providerAccountId;
// 	}

// 	return null;
// };
// !!!!! TFBO returns params for a request to BJP ONLY when status is `NoRecord` or `Incomplete`
//---- Dynamic form for request to BJP: used for initial new subscription
//subscriptionUtils/ submitDynamicForm(result.action.type, result.action.url, result.action.fields, acceptCharset)
